import { getUA } from "react-device-detect";

const isAndWebView = !!window.binder;
const isIOSWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(getUA);

//브릿지로 전송해서 firebase에도 데이터 수집

/**
 * @description GA 태깅 - 페이지뷰
 * @param pagename 페이지 명
 * @param pathname location.pathname
 */
export const gaPageView = (pagename, pathname) => {
  window.gtag("config", process.env.REACT_APP_GA_TRACKING_ID, {
    page_title: ``,
    page_location: ``,
    page_path: ``,
  });
  setTimeout(() => {
    window.gtag("event", "screen_view", {
      page_title: `[스마트전단] ${pagename}`,
      page_path: pathname,
    });
  }, 150);

  if (process.env.REACT_APP_TYPE === "PRODUCTION") {
    try {
      if (isIOSWebView) {
        window.webkit.messageHandlers.sendFaView.postMessage({
          vId: "",
          vName: pagename,
        });
      } else if (isAndWebView) {
        window.binder?.sendFaView("", pagename);
      }
    } catch (e) {
      console.log(e);
    }
  }
};

/**
 * @description GA 태깅 - 이벤트
 * @param eCate 페이지 명
 * @param eName 이벤트 명
 */
export const gaEvent = (eCate, eName) => {
  window.gtag("event", `[스마트전단] ${eName} 클릭`, {
    event_category: eCate,
    event_label: eName,
  });

  if (process.env.REACT_APP_TYPE === "PRODUCTION") {
    try {
      if (isIOSWebView) {
        window.webkit.messageHandlers.sendFaEvent.postMessage({
          eId: "",
          eName: eName,
          eCate: eCate,
          eType: "",
        });
      } else if (isAndWebView) {
        window.binder?.sendFaEvent("", eName, eCate, "");
      }
    } catch (e) {
      console.log(e);
    }
  }
};

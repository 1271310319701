import { LEAFLET_URL } from "../constants";

const allStoreData = [
  `
  <h1>LOTTE Mart</h1>
  <div>
    ·전단적용기간 : 2024. 11. 7(목) ~ 2024. 11. 13(수) < 비신선식품 적용기간 : 2024. 10. 31(목) ~ 11. 13(수) >

    롯데마트가 준비한 고객 감사 대축제
    땡큐절
    매일 10시 아침 종이 땡 미친 할인 큐

    땡 잡았다! 
    1 하루ㆍ주말 특가
    2 50%ㆍ1 + 1
    3 득템 찬스
    4 땡 전딜

    초이스 척아이롤/부채살
    L.POINT 50%
    ※기간 : 11/7(목) ~ 11/10(일)
    ※조기 품절될 수 있습니다

    L.POINT 50%
    초이스 척아이롤 (100g/냉장/미국산) 1,590원

    L.POINT 50%
    초이스 부채살 (100g/냉장/미국산) 1,890원

    L.POINT 회원 2,000원 할인!
    L.POINT 2,000원 할인!
    부사사과 (4~6입/봉/국산) L.POINT 2,000원 
    6,990원
    ※조기 품절될 수 있습니다

    봉지굴
    L.POINT 30% -> 롯데/BC/KB국민/신한/NH농협/삼성카드 20%
    L.POINT 30% -> 롯데/BC/KB국민/신한/NH농협/삼성카드 20%
    봉지굴(260g/국산) 4,978원
    ※안전을 위해 가급적 익혀드시기를 권장합니다
    ※페이지 하단 카드할인 세부내용 참고

    4일간, 해당카드 결제시 반값!
    롯데/BC/KB국민/신한/NH농협/삼성카드 50%
    한통 가아아득 치킨(팩/국내산 계육) 6,495원
    ※기간 : 11/7(목) ~ 11/10(일)
    ※ 11/11(월) ~ 11/13(수) 구매시 9,990원
    ※제주점 제외
    ※ 한정물량으로 점별 수량 상이

    대파(봉/국산) 1봉1,580원 1,580원 
    ※L.POINT회원 해당카드 결제시에 한함 
    농협할인 20% -> 롯데/BC/KB국민/신한/NH농협/삼성카드 500원 추가할인
    ※1일 1인 2봉 구매 한정 ※L.POINT 회원 기간 중 최대 2만원 할인
    ※산지 시황에 따라 조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고

    2kg 9,990원 ※L.POINT회원 해당카드 결제시에 한함
    영동 비파괴 당도선별 샤인머스캣 (2kg/박스/국산) 9,990원
    ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고
    L.POINT 4,000원 -> 롯데/BC/KB국민/신한/NH농협/삼성카드 1,000원 추가할인 \
    영동군과 함께 농가를 응원합니다!

    봉지라면 1+1
    봉지라면 8종(상품별 규격 상이) 각3,880~8,700원
    ※동일 브랜드 내 동일 가격 간 교차구매 가능
    ※오뚜기 굴진짬뽕 4개입 外 7종

    매일 오전 10시 ~ 오후 1시
    타임쿠폰팩 다운받고 즉시 사용하세요!
    ※기간 : 10/31(목) ~ 11/13(수) ※쿠폰 관련 세부내용은 롯데마트몰에서 확인하실 수 있습니다
    5만원 이상 구매시 3,000원 할인 
    10만원 이상 구매시 10,000원 할인
    ※정상가란? 2024년 10월 17일(목) ~ 11월 6일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 10월 10일(목) ~ 10월 30일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    땡큐 하루 특가
    11/7 목
    산더미 대파 소불고기(800g/냉장/원산지 별도표기) 12,900원
    ※기간 외 : 11/8(금)~11/13(수) L.POINT 40% 15,480원
    L.POINT 50%

    파프리카(개/국내산) 롯데/BC/신한/NH농협/삼성카드 200원 할인 890원
    ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고

    11/8 금
    국산 생물 흰다리새우 (100g/냉장/국산) 2,392원
    L.POINT 20%

    껍질벗긴새우 (750g/냉동/원산지 별도표기) 11,940원
    L.POINT 40%

    씨제이 햇반 유기농 쌀밥 9,980원
    (210g×12입) ※1인당 2개 구매 한정
    L.POINT 4,000원 할인

    11/9 토
    양파(1.5kg/국내산) 2,960원
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인
    ※산지 시황에 따라 조기 품절될 수 있습니다
    농협할인 20%

    퐁퐁 전품목(상품별 상이) 해당카드로 2개 이상 구매시 1개당 각1,920~3,840원
    ※해당상품에 한함
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각4,800~9,600원
    롯데/BC/신한/NH농협/삼성카드 2개 이상 60%

    11/10 일
    애호박(개/국내산) 990원
    ※조기품절될 수 있습니다

    땡큐 주말특가
    기간 : 11/7(목) ~ 11/10(일)
    캐나다 삼겹살/목심(각 100g/냉장/캐나다산) 각990원
    롯데/BC/신한/NH농협/삼성카드 50%
    ※기간 : 11/9(토)~11/10(일) ※1인 2팩 구매 한정
    ※조기품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고

    롯데/BC/신한/NH농협/삼성카드 2+1
    삼립 호빵 3입/4입 5종(상품별 용량 상이) 각4,980~5,880원
    ※3개 구매시 34% 할인 ※교차구매 가능
    ※페이지 하단 카드할인 세부내용 참고

    페레로로쉐 T16 벨타입(200g) 18,800원
    ※페이지 하단 카드할인 세부내용 참고
    롯데/BC/신한/NH농협/삼성카드 1+1

    1+1
    파스퇴르 쾌변 요구르트 3종(각 150ml×4입) 6,680원
    ※사과/키위/ABC ※교차구매 가능

    국민맥주 라거편(500ml/캔) 990원
    ※기간 : 11/7(목)~11/9(토)
    ※페이지 하단 카드할인 세부내용 참고
    롯데마트 단독
    L.POINT 400원 할인 -> 롯데/BC/신한/NH농협/삼성카드 810원 할인

    땡 잡았다!
    최대 50% 할인
    요리하다 양념육 전품목(상품별 중량 상이/원산지 별도표기) 최대 50% 할인
    ※단, 제주 흑돼지 간장/고추장 불고기 제주점 제외

    동원 양반김 전품목(상품별 상이) 최대 50% 할인

    2개 이상 50%
    청년다방 깻잎고추튀김/오짱떡볶이/하코야 매운닭다리살/통닭다리살 꼬치 2개 이상 구매시 1개당 각4,990~7,490원
    (상품별 상이) ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각9,980~14,980원

    해피콜 뉴 골든 구르메 프라이팬 5종(상품별 상이) 50% 각17,450~26,950원
    
    글라스락 김치맛 지킴이 핸디퓨어 3종(상품별 상이) 50% 각12,450~15,950원

    2개 이상 50%
    쏘피/쏘피에일/라이프리/마미포코 최대 48종 2개 이상 구매시 1개당 각4,250~17,950원
    (상품별 상이) ※각 브랜드별 교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각8,500~35,900원
    행사상품 3만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " G "를 확인하세요 ※결제시 계산대에서 수령

    2개 이상 50%
    퍼실/퍼울 액체세제 전품목(상품별 상이) 2개 이상 구매시 1개당 각8,750~14,950원
    ※교차구매 가능 ※퍼실 디스크 캡슐세제 제외
    ※1개 구매시 각17,500~29,900원

    다우니 유연제 실내건조/생화향기 8종 해당카드로 2개 이상 구매시 1개당 각9,800/11,650원
    (각 2L/2.6L) ※페이지 하단 카드할인 세부내용 참고
    ※1개 구매시 각 19,600/23,300원 ※교차구매 가능
    롯데/BC/신한/NH농협/삼성카드 2개 이상 50%

    2개 이상 50%
    룸바이홈 스마트 S 옷걸이 外 10종 (상품별 상이) 2개 이상 구매시 1개당 각1,950~2,950원
    ※점별 재고 상이 ※교차구매 가능
    ※1개 구매시 각3,900~5,900원

    2개 이상 50%
    오늘좋은 피치 극세사 이불 外 11종(상품별상이) ※교차구매 가능 ※행사상품에 한함
    2개 이상 구매시 1개당 각9,450~29,950원
    ※1개 구매시 각18,900~59,900원

    오늘좋은 커브 메모리폼 베개 2종 2개 이상 50%
    (상품별상이) ※교차구매 가능 ※행사상품에 한함
    2개 이상 구매시 1개당 각11,950/12,950원
    ※1개 구매시 각23,900/25,900원 

    ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
    ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
    땡 잡았다! 하나 사면 하나 더 1+1

    1+1
    사각/국탕용 어묵 5종 (상품별 상이) 3,280~12,980원
    ※동일 상품에 한함

    롯데/BC/신한/NH농협/삼성카드 1+1
    동원 퀴진 크리스피 돈까스 2종 각9,980원
    (통등심 480g/치즈 450g) ※교차구매 가능

    1+1
    오뚜기 떡볶이 3종(상품별 상이) 각4,980원
    ※국물/쌀/라볶이 ※교차구매 가능

    1+1
    동원 딤섬 3종(상품별 상이) 10,980~11,980원
    ※새우하가우/부추창펀/샤오롱바오 ※동일 가격 교차구매 가능

    1+1
    녹차원 절임차(1kg)/자임 하루하나 유기농 레몬즙(20g×14입) 9,900~15,900원
    ※절임차 1kg 교차구매 가능 ※레몬즙 동일 품목 적용

    1+1 롯데마트 단독
    로티로리 유아간식 14종 (상품별 규격 상이) 2,200~4,500원
    ※동일매가 교차구매 가능

    1+1
    청정원 씨간장숙성 양조간장 골드(1.7L) 18,480원

    1+1
    바릴라 파스타면 4종(상품별 규격 상이) 각4,680~7,680원
    ※스파게티/링귀니/푸실리 ※동일 상품에 한함

    1+1
    동원 리챔 오리지널 (200g×3입) 14,480원

    1+1
    사조 살코기/마일드/고추참치 (각 85g×4입) 각8,980원
    ※교차구매 가능

    1+1
    삼양 불닭소스 4종(상품별 규격 상이) 각4,200원
    ※불닭/불닭마요/핵불닭/까르보불닭 ※교차구매 가능

    1+1
    코인 조미료 12종(상품별 규격 상이) ※점별 운영상품 상이 각7,800~9,900원
    ※동일 브랜드&동일 가격 상품간 교차구매 가능

    1+1
    테일러 유기농 하루 레몬즙(20ml×10입) 11,900원

    1+1
    칠성사이다/펩시/펩시제로/밀키스 (각 190ml×6입) 각5,480원
    ※교차구매 가능

    1+1
    남양 아라비카 골드라벨 누보 50입 (10.7g×50입) 11,200원

    1+1
    동원 참치액 3종 (각 500g, 순/진/해물) 각7,980원
    ※교차구매 가능
    동원에프앤비 전품목 4만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉢"를 확인하세요 ※결제시 계산대에서 수령

    1+1
    해찬들 100% 국산고추장/원조 태양초 고추장 각19,800원
    (상품별 규격 상이)
    ※교차구매 가능
    씨제이 제일제당 전품목 4만 5천원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "ⓒ"를 확인하세요 ※결제시 계산대에서 수령

    1+1
    아임리얼 당근/케일 (각 700ml) ※교차구매 가능 각10,980원

    1+1
    슬라이스 치즈/스낵치즈/자연치즈25종 (상품별 상이) 각3,980~10,580원
    ※서울우유/남양/빙그레/동원 外
    ※동일 브랜드 내 동일 가격 간 교차구매 가능

    1+1
    매일 상하 스트링 치즈(18g×10입) 12,980원

    1+1
    떠먹는 요구르트 30종(각 80g×4입/85g×4입) 각3,780~6,480원
    ※빙그레/풀무원/남양/매일/일동/동원
    ※동일 브랜드 내 동일 가격 간 교차구매 가능

    1+1
    가공우유 23종(상품별 상이) 각1,900~9,600원
    ※푸르밀/빙그레/동원/매일 外 ※기간 : 11/1(금)~11/13(수)
    ※동일 브랜드 내 동일 가격 간 교차구매 가능

    1+1
    상온/냉장커피 단품 전품목(상품별 상이) 각1,780~3,980원
    ※동일 시리즈간 교차구매 가능 ※일부품목 제외

    1+1
    마즈 스니커즈 펀사이즈/트윅스 미니스(각 500g) 각12,980원
    ※교차구매 가능

    1+1
    허쉬 초콜릿칩 쿠키/초콜릿칩 베리 쿠키(각 144g) 각4,790원
    ※교차구매 가능

    1+1
    켈로그 프링글스 10종 (상품별 용량 상이) 각3,330원
    ※교차구매 가능

    1+1
    동서 미떼 핫초코 오리지날/마일드 (각 30g×10입) 각6,500원
    ※교차구매 가능

    1+1
    미쟝센 헤어앤 스칼프 부스터 샴푸(800g)/트리트먼트(300g) 각9,800원
    ※너리싱/딥클린/하이드로 ※교차구매 가능

    1+1
    해피바스 비타민 바디워시(각 750g) 각7,900원 
    ※생귤/유채꽃 ※교차구매 가능

    아모레퍼시픽 행사상품 3만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉵ "를 확인하세요 ※결제시 계산대에서 수령
    
    1+1
    수퍼테크 용기 (각 3L, 일반/드럼) 각12,900원
    ※교차구매 가능

    1+1
    룸바이홈 층간소음 줄이는 거실화 (270mm/290mm) 각11,900원
    ※핑크 外 ※교차구매 가능
    ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

    1+1
    락앤락 클리어 비스프리(상품별 상이) 4,000~15,200원
    ※동일가격 상품에 한함

    ※정상가란? 2024년 10월 17일(목) ~ 11월 6일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 10월 10일(목) ~ 10월 30일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

    ※전단적용기간 : 2024. 11. 7(목) ~ 11. 13(수)

  </div>
  `,
  `
  <div>
    땡 잡았다 득템찬스

    L.POINT 각50%
    참프레/정다운 훈제오리 슬라이스 (각 400g/냉장/원산지 별도표기) 각6,990원
    ※전점 70,000개 한정수량

    물가안정 오징어 (마리/해동/원양산) 2,200원

    물가안정 참치회 (350g/냉장/원산지 별도표기) ※입점점에 한함 19,900원

    L.POINT 10,000원
    파타고니아 생연어 필렛 (500g/냉장/칠레산) 19,900원

    2개 이상 50%
    코주부 징기스칸 육포 순한맛/매운맛 (각 130g/원산지 별도표기) 2개 이상 구매시 1개당 각7,450원
    ※교차구매 가능
    ※1개 구매시 각14,900원

    2개 이상 50%
    동원 리얼크랩스/랍스터 (각 216g) 2개 이상 구매시 1개당 각2,990원
    ※교차구매 가능
    ※1개 구매시 각 5,980원
    
    2개 이상 각3,980원 할인
    풀무원 노엣지피자/핫도그 6종 (상품별 상이) 2개 이상 구매시 1개당 각5,000원
    ※1개 구매시 각 8,980원
    ※점별 운영상품 상이 ※교차구매 가능

    2개 이상 50%
    BBQ 더바삭 닭강정 2종 (각 500g, 극한왕갈비맛/매콤양념) 2개 이상 구매시 1개당 각4,990원
    ※교차구매 가능
    ※1개 구매시 각 9,980원

    2개 이상 최대 2,480원 할인
    풀무원 전 5종(상품별 규격 상이) 2개 이상 구매시 1개당 각5,000원
    ※철판 감자채전/오징어부추전, 떡갈비, 동그랑땡, 고기완자
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각 5,980~7,480원

    1+1
    CJ 더건강한 베이컨(90g×2)/육공육 슬라이스 씬(250g) 8,990/9,480원
    ※동일상품에 한함

    L.POINT 7,000원
    남양 드빈치 자연방목 피자치즈(1kg) 10,980원

    서울 멸균 흰우유 (200ml×24입) 10,980원
    5,920원 할인

    빙그레 바나나맛 우유 기획 3종 (각 240ml×6입, 빙그레 바나나맛+딸기맛 外 2종) 각7,280원
    800원 할인

    L.POINT 1,000원
    남양 마시는 불가리스 기획 5종(각 150ml×8입) 7,980원
    ※남양 마시는 불가리스 사과 外 4종

    2+1
    스타벅스 미디엄로스트/다크로스트 아메리카노(각1.1g×30입) 각11,900원
    ※교차구매 가능

    2+1
    담터 호두 아몬드 율무차 (18g×30입) 9,900원

    동서식품 전상품 5만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 '#'를 확인하세요 ※결제시 계산대에서 수령
    동서 맥심 커피믹스 모카골드 마일드(12g×220입+30입)/화이트골드 (11.7g×220입+30입) 각28,430원

    1+1
    칠성사이다 外 4종(각 1.25L) 각3,280원
    ※칠성사이다제로/펩시/펩시제로/펩시블랙
    ※교차구매 가능

    2+1
    롯데 빈츠/롯데 빈츠 롯데타워 (각 204g) 각4,780원
    ※교차구매 가능

    햇 고춧가루
    햇님마을 영양농산 태양초 고춧가루 (250g/1kg) 12,900/39,800원
    최대 5,920원 할인

    L.POINT 8,000원
    CJ 스팸 클래식 라이트 기획(200g×3+200g) 11,980원

    L.POINT 6,000원
    종근당건강 락토핏 생유산균 골드(2g×50포) 9,900원

    크리넥스 수프림소프트(27m×30롤) 해당카드로 2개 이상 구매시 1개당 각17,700원
    ※페이지 하단 카드할인 세부내용 참고
    ※1개 구매시 39,400원
    2개 이상 50% -> 롯데/BC/신한/NH농협/삼성카드 2,000원 추가할인

    2+1
    롯데 더쌀로 사르르 핫쉬림프맛 (100g) 2,720원

    테팔 행사상품 5만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 '㉫'를 확인하세요 ※결제시 계산대에서 수령
    테팔 스타트이지 프라이팬/뉴 쉐프터치 프라이팬/인텐시움 통3중 냄비/티타늄 블라썸 냄비 40% 할인
    (상품별 상이)

    포트메리온 보타닉가든(상품별 상이) 최대 40% -> 롯데/BC/신한/NH농협/삼성카드 추가 10%
    ※페이지 하단 카드할인 세부내용 참고

    2개 이상 40%
    크린랩 크린장갑(200매) 2개 이상 구매시 1개당 각5,454원
    ※1개 구매시 9,090원

    해태 맛동산 20% 중량업(288g)/해태 오예스 24입 기획(720g) 3,480/7,880원 
    4입 중량
    20% 중량업

    3M 항균 삼중양면 고운/다목적 수세미(각 2입) 각3,490원
    1+1 기획팩

    각2,000원 할인
    산리오 윈터 슈즈 모음전 각15,800원
    ※점별 입점 상품 상이 ※행사 대상 상품에 한함

    50%
    패스트 뉴블랙트럭캐리어 (3세 이상/62.5×12×20.5cm) 19,950원

    세계맥주 골라담기 9,950원 
    캔 당 1,990원
    ※1캔 구매시 각3,000원

    ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
    ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
    990원 땡 전딜!
    하림/참프레 닭다리살(정육) (각 100g/냉장/국내산) 990원
    ※제주점 제외

    요리하다 사골곰탕(500g) 990원

    오늘좋은 백미밥(210g) 990원

    파워에이드 外 4종(상품별 용량 상이) 각990원
    ※파워에이드/토레타/토레타 제로/환타 오렌지/환타 파인제로

    오늘좋은 웨이퍼롤 3종 (각 100g) 각990원
    ※바닐라/초코/카푸치노

    코코토토 매일매일 깨끗한 물티슈(100매) 990원

    최대 510원 할인
    벨버드 황태오리버거/더주오 통살 닭가슴살(상품별 용량 상이) 각990원
    ※입점점에 한함
    
    최대1,510원 할인
    룸바이홈 다용도/손잡이 바스켓, 신발정리대 12종 각990원
    ※점별 재고 상이
    ※한정수량으로 조기 품절될 수 있습니다

    4,990원 땡 전딜!
    강원 감자(2kg/국산) 4,990원

    2개 이상 각4,000원 할인
    고구마말랭이 外 3종 2개 이상 구매시 1개당 각4,990원
    (상품별 상이/원산지 별도표기)
    ※1개 구매시 각8,990원 ※교차구매 가능

    890원 할인
    남양 맛있는 우유 GT 기획(900ml×2) 4,990원

    L.POINT 7,910원
    칼로로시 콩코드(750ml) 4,990원

    샤워메이트 바디워시 5종 각4,990원
    (상품별 용량 상이) ※점별 운영상품 상이
    
    데일리콤마 로더 디퓨저 4종(각 200ml) 각4,990원
    4,910원 할인

    아머올 에탄올 워셔액(1.8L×2개입) 4,990원

    1,910원 할인
    오늘좋은 데일리 테이프클리너 리필(대형/6P) 4,990원

    1,800원 할인
    맥스 폭발방지 CRV 부탄가스(4P) 4,990원

    1+1 기획팩
    유니랩 위생장갑 기획팩 4,990원

    9,990원 땡전딜!
    바베큐 오징어 기획 (300g/원산지 별도표기) 9,990원

    L.POINT 50%
    직소싱 번들 새우살(각 250g×2/냉동/원산지 별도표기) 각9,990원

    3+1 골라담기
    반찬 3+1팩 골라담기 (4팩/원산지 별도표기) 9,990원
    ※ 비움/미찬/도시곳간/무명반찬/푸드앤찬/초이스쿡 입점점에 한함
    ※정상가 4,000원 이하 상품 한정

    CJ 쿡킷/비비고 밀키트 3종/청정원 호밍스 밀키트 3종 (상품별 상이) 각9,990원
    ※ 곱창전골, 곱창순대전골, 불쭈꾸미볶음/김치어묵우동전골, 우삽겹 스키야키, 춘천식 닭갈비

    고려은단 비타민C 2종 각9,990원
    (상품별 상이) ※C1000/이지비타민

    3캔 구매시
    하이볼 골라담기(상품별 용량 상이) 9,990원
    ※퐁당레몬/라임, 생레몬/라임/청귤 外 5종
    ※1캔 구매시 각 4,000원

    에너자이저 맥스 10입 (5입 보너스팩) 각9,990원
    ※AA/AAA

    엘라스틴 프로폴리테라 7종(상품별 용량 상이) 각9,990원
    ※점별 운영상품 상이

    나무야나무야 깨끗한 무향 3겹 화장지 (27m×30롤) 9,990원

    5,810원 할인
    오늘좋은 퍼 방한 슬리퍼 9,990원
    ※입점점에 한함 ※점별 재고 상이

    
    ※정상가란? 2024년 10월 17일(목) ~ 11월 6일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 10월 10일(목) ~ 10월 30일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

    ※전단적용기간 : 2024. 11. 7(목) ~ 11. 13(수)

  </div>
  `,
  `
  <div>
    축산
    마블나인 한우 전품목 (1++(9)등급/각 100g/냉장/국내산 한우고기) L.POINT 최대 50%
    ※조기 품절될 수 있습니다

    "김장시즌 김치와의 환상궁합" 브랜드 돼지고기 삼겹살/앞다리 수육용 각3,690/1,990원
    (각 100g/냉장/국내산 돼지고기)
    ※제주점 제외 ※입점점포에 한함 ※MAP 규격팩 제외

    미국산 갈비살/아롱사태(도매용) (각 100g/냉장/미국산) 2,490/1,890원
    L.POINT 최대 각 40%

    미국산/호주산 LA갈비 (각 1kg, 냉동, 미국산/호주산) 각39,900원
    ※조기 품절될 수 있습니다
    L.POINT 각 10,000원
    
    수산
    김장용 햇추젓 (1kg/냉장/국산) 13,900원
    L.POINT 3,000원

    대천 전통 재래 도시락김 (4g×30봉/원산지 별도표기) 8,990원
    L.POINT 3,000원

    L.POINT 30%
    프리미엄 시그니처 장어초밥 (8입/냉장/원산지 별도표기) 8,960원
    ※입점점에 한함    

    L.POINT 25%
    완도 활 전복 (대/마리/냉장/국산) 1,987원

    L.POINT 35%
    서해안 꽃게(100g/냉장/국산) 1,293원
    ※산지상황에 따라 품절 될 수 있습니다

    채소
    농협할인 20%
    배추(통/국산) 3,488원
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인
    ※산지 시황에 따라 조기 품절될 수 있습니다

    농협할인 20%
    안동 햇 생강(400g/봉/국산) 3,560원
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인
    ※산지 시황에 따라 조기 품절될 수 있습니다

    GAP친환경느타리버섯 (500g/팩/국산) 1,990원

    피그인더가든 데일리믹스 샐러드 (315g/팩/원산지 별도표기) 3,990원

    과일
    L.POINT 5,000원
    상생배(3kg/박스/국산) 10,990원
    ※조기 품절될 수 있습니다

    레드키위 (9~13입/팩/국산) 9,990원

    L.POINT 3,000원
    스위텔토마토 (800g/팩/국산) 9,990원

    10개 구매시
    단감(개/국산) 9,900원
    ※1개 구매시 1,290원

    잡곡/계란
    경기미(20kg/국산) 49,900원

    2개 이상 50%
    파로 혼합곡 (각 1.5kg/원산지 별도표기) 2개 이상 구매시 1개당 각4,995원
    ※1개 구매시 9,990원

    동물복지인증 유정란 (대란/25구/국산) 9,990원

    2봉 이상 50%
    풀무원/CJ 유기농 콩나물 (각 270g/원산지 별도표기) 2봉 이상 구매시 1봉당 각1,045원
    ※동일 브랜드에 한함
    ※1봉 구매시 각2,090원

    간편식 요리

    L.POINT 40%
    ALL NEW 새우초밥(20입/1팩/원산지 별도표기) 11,994원

    김밥&롤 닭강정/유부초밥 세트 (1팩/원산지 별도표기) 각7,990원

    L.POINT 40%
    5무 훈제오리 (1팩/원산지 별도표기) 9,594원 

    추억의 깨찰빵(8입/1팩/원산지 별도표기) 5,500원 할인 7,500원 
    ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함

    ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
    ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
    냉장/냉동 식품

    2개 이상 각1,000원 할인
    CJ 고메 소바바 치킨 5종 (상품별 상이) 2개 이상 구매시 1개당 각7,980원
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각8,980원

    CJ 비비고 갈비왕교자 (420g×2) 9,480원
    ※운영점에 한함

    동원 통그릴비엔나 (1kg) 10,980원

    목우촌 주부9단 프랑크소세지(500g) 5,480원

    유제품/음료 

    1+1
    과채주스 대용량 단품 전품목(1.2L~1.8L) 각3,280~7,980원
    ※동일시리즈간 교차구매 가능 ※점별 운영상품 상이

    1+1
    두유 16입 전품목(상품별 상이) 각14,450~20,000원
    ※동일시리즈간 교차구매 가능

    롯데마트 단독
    풀무원 다논 액티비아 플레인 (80g×16입) 6,980원

    롯데마트 단독
    매일유업 상하 마일드 체다 슬라이스 치즈(17g×50입) 9,980원

    주류
    L.POINT 12,000원 
    안나벨라 러시안 리버밸리 피노누아/안나벨라 나파 밸리 까베르네 소비뇽(각 750ml) 29,900원   

    L.POINT 2,900원 
    존바 파이니스트 (750ml) 9,900원

    크러시 꼬깔콘 기획팩 (470ml×8) 각12,450원

    2캔 구매시
    카발란 Dry 하이볼(320ml) 8,980원
    ※기간 : 11/1(금)~11/13(수)
    ※1캔 구매시 각4,900원

    ONLY 롯데마트
    2,000원 할인
    요리하다 단팥/슈크림 붕어빵(각 400g) 4,990원

    3,000원 할인
    요리하다 쫄깃한 찹쌀 탕수육(550g) 4,990원

    500g ×2개입 기획
    요리하다 찰통순대 기획(500g×2) 5,990원

    3,000원 할인 
    요리하다 새콤바삭 유린기(480g) 4,990원

    롯데웰푸드
    수능 행사상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 ' A 를' 확인하세요 ※결제시 계산대에서 수령
    
    1+1
    롯데 키세스 초콜릿 4종(각 146g) 각5,280원
    ※밀크/아몬드/다크/쿠키앤크림 ※교차구매 가능

    1+1
    롯데 허쉬 초콜릿 스낵사이즈 3종 (각 165g) 각5,520원
    ※밀크/아몬드/쿠키앤크림 ※교차구매 가능

    1,220원 할인
    롯데 길리안 시쉘 초콜릿 2종 (오리지날 250g/다크 225g) 17,980원

    롯데 명가 찰떡파이 (350g) 300원 할인 5,280원

    롯데웰푸드
    빼빼로데이 행사상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정   
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 ' P 를' 확인하세요 ※결제시 계산대에서 수령

    롯데 빼빼로 4종(상품별 규격 상이) 각4,780원
    ※초코/아몬드/크런키/화이트쿠키

    롯데빼빼로 8갑 기획 4종(상품별 규격 상이) 각11,520원
    ※초코/아몬드/크런키/화이트쿠키 

    롯데 빼빼로 케이크박스 기획 14,400원
    (380g) ※점별 운영상품 상이

    롯데 빼빼로 슬라이딩 기획 (150g) 5,760원
    ※점별 운영상품 상이


    ※정상가란? 2024년 10월 17일(목) ~ 11월 6일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 10월 10일(목) ~ 10월 30일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
    ※전단적용기간 : 2024. 11. 7(목) ~ 11. 13(수)

  </div>
  `,
  `
  <div>
    브레프 행사상품 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ♬ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    브레프 전품목(상품별 상이) 2개 이상 구매시 1개당 각2,250~4,450원
    ※1개 구매시 각4,500~8,900원 ※교차구매 가능

    피죤 행사상품 2만 5천원 이상 구매시
    5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ▣ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    고농축 피죤 시그니처 리필 3종 2개 이상 구매시 1개당 각7,450원
    (각 2.3L) ※미스틱레인/플라워페스티벌/라피에스타
    ※1개 구매시 각14,900원 ※교차구매 가능

    크리넥스 물티슈 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉪ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    크리넥스 물티슈 8종(상품별 상이) 2개 이상 구매시 1개당 각2,145~7,950원
    ※마이비데 클린케어 휴대용/엑스라지 外
    ※1개 구매시 각4,290~15,900원 ※교차구매 가능

    피지오겔/AHC 해당상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수) ※브랜드별 합산불가
    ※각 브랜드별 3만원 이상 구매시 해당
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 : 피지오겔 " ㉲ "/ AHC " ㉳ "를 확인하세요
    ※결제시 계산대에서 수령

    피지오겔 DMT크림/AHC 콜라겐 크림(상품별 상이) 26,910/25,900원

    아모레퍼시픽 행사상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉵ "를 확인하세요
    ※결제시 계산대에서 수령

    1+1
    메디안 잇몸과학 치약(각 120g×3입) 각12,980원
    ※스트롱민트/클린민트 ※교차구매 가능 ※점별 운영상품 상이

    1+1
    다우니 세탁세제 리필 실내건조/헹굼제로(각 3.2L) 각36,900원
    ※교차구매 가능

    2개 이상 50%
    2개 이상 구매시 1개당 6,050원
    크리넥스 홈냅킨 퓨어화이트(130매×6입)
    ※1개 구매시 12,100원
    크리넥스 키친타월 2만 5천원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉸"를 확인하세요 ※결제시 계산대에서 수령

    롯데/BC/신한 NH농협/삼성카드 2개 이상 55%
    리엔 보윤 샴푸/컨디셔너(각 1,000ml) 해당카드로 2개 이상 구매시 1개당 각7,155원
    ※1개 구매시 각15,900원
    ※교차구매 가능 ※점별 운영상품 상이
    ※페이지 하단 카드할인 세부내용 참고

    롯데/BC/신한/NH농협/삼성카드 2개 이상 60%
    히말라야 핑크솔트 바디워시 3종 (각 900g) 해당카드로 2개 이상 구매시 1개당 각5,960원
    ※로즈/리프레싱민트/포레스트민트
    ※1개 구매시 각14,900원 ※교차구매 가능
    ※페이지 하단 카드할인 세부내용 참고

    2개 이상 20%
    핸드&풋 크림 모음전(상품별 상이) 2개 이상 구매시 1개당 각1,520~12,720원
    ※1개 구매시 각1,900~15,900원 ※교차구매 가능
    ※점별 운영상품 상이

    L.POINT 5,000원
    아임비타 이뮨플러스(7입) 11,900원

    쿡셀 전품목 5만원 이상 구매시 10,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/27(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ◆ "를 확인하세요
    ※결제시 계산대에서 수령
    쿡셀 전품목(상품별 상이) 20% 31,120/50,240원

    3M 청소/Bath 전품목 3만원 이상 구매시 5,000원 롯데상품권 증정
    불스원 전품목 3만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "M"를 확인하세요
    ※결제시 계산대에서 수령
    3M 베이킹소다 톡톡 크린스틱 시트 타입(소)/대형 테이프 클리너 팩(상품별 상이) 20% 10,800/15,390원
    ※입점점에 한함 ※해당 상품에 한함 ※점별 재고 상이

    대림바스 전품목 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "D"를 확인하세요
    ※결제시 계산대에서 수령
    대림바스 스마트 초슬림 무소음 하드 변기커버 D형(39.3×48cm) 15% 22,010원
    ※입점점에 한함 ※해당 상품에 한함 ※점별 재고 상이

    불스원 전품목 3만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉥ "를 확인하세요
    ※결제시 계산대에서 수령
    불스원 레인OK 발수 와이퍼 (350~650mm) 1+1 밴딩 8,100~12,800원

    티피링크 전품목 5만원 이상 구매시 10,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉠ "를 확인하세요
    ※결제시 계산대에서 수령
    티피링트 tapo 전품목(상품별 상이) 12,900~79,000원

    보디가드 남성/여성 기모내의 세트(상품별 상이) 각9,900원

    비비안 웜업 터틀넥 (상품별 상이) 각9,900원

    데일리 남성/여성 단목, 중목양말(각 8매) 각9,900원

    개이득 냥이득!
    ※ 기간 : 10/31(목) ~11/13(수)
    colioli 득템위크
    반려동물식품&용품 480여종 할인!
    반려동물식품/용품 3만원 이상 구매시 5천원 할인쿠폰 증정!
    ※해당카드 : 롯데/BC/신한/NH농협/삼성카드
    ※영수증 內 할인쿠폰으로 발급 ※12월 한달 간 사용가능
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " @ "를 확인하세요 ※입점점에 한함
    ※L.POINT회원에 한함 ※페이지 하단 카드할인 세부내용 참고

    롯데/BC/신한/NH농협/삼성카드 30%
    콜리올리 웰밸런스 사료 4종 (상품별 상이) 9,730~14,630원
    ※점별 재고 상이
    ※퍼피/성견/중형견/대형견

    콜리올리 순살가득 시리즈 4종 (각 300g) ※점별 재고 상이 각8,330원
    롯데/BC/신한/NH농협/삼성카드 30%

    콜리올리 깔끔하게 사용하는 1회용 패드 3종(M 100매/M 200매/L 40매) 8,330~16,730원
    ※입점점에 한함
    롯데/BC/신한/NH농협/삼성카드 30%

    L.POINT 10% -> 롯데/BC/신한/NH농협/삼성카드 10% 추가할인
    이나바 챠오츄르 버라이어티 (20P/40P) 12,069/21,465원
    ※입점점에 한함

    롯데/BC/신한/NH농협/삼성카드 30%
    월간스틱/월간통살 고양이 간식(30P) 각7,630원
    ※입점점에 한함

    롯데/BC/신한/NH농협/삼성카드 30%
    콜리올리 냄새잡는 벤토나이트 모래(무향 6.5kg) 9,730원
    ※입점점에 한함

    toysrus
    제프리 생일파티에 초대합니다 
    인기 완구류 해당상품 최대 28,500원 할인!

    슈팅스타! 티니핑 피규어 출시!
    스타 하츄핑 피규어 外 10종 (3세 이상/상품별 상이) 각12,900~14,900원
    ※입점점에 한함 ※점별 재고 상이

    레고 깊고 어두운 전장 (21246) 28,500원 할인 66,400원
    더 많은 상품을 토이저러스몰에서 만나보세요! ▶

    ※정상가란? 2024년 10월 17일(목) ~ 11월 6일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 10월 10일(목) ~ 10월 30일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const zettaData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
    ·전단적용기간 : 2024. 11. 7(목) ~ 2024. 11. 13(수) < 비신선식품 적용기간 : 2024. 10. 31(목) ~ 11. 13(수) >
    ·제타플렉스 잠실점

    LotteMart ZETTAPLEX가 준비한 고객 감사 대축제
    땡큐절
    매일 10시 아침 종이 땡 미친 할인 큐

    땡 잡았다! 
    1 하루ㆍ주말 특가
    2 50%ㆍ1 + 1
    3 득템 찬스
    4 땡 전딜

    초이스 척아이롤/부채살
    L.POINT 50%
    ※기간 : 11/7(목) ~ 11/10(일)
    ※조기 품절될 수 있습니다

    L.POINT 50%
    초이스 척아이롤 (100g/냉장/미국산) 1,590원

    L.POINT 50%
    초이스 부채살 (100g/냉장/미국산) 1,890원

    L.POINT 회원 2,000원 할인!
    L.POINT 2,000원 할인!
    부사사과 (4~6입/봉/국산) L.POINT 2,000원 
    6,990원
    ※조기 품절될 수 있습니다

    봉지굴
    L.POINT 30% -> 롯데/BC/KB국민/신한/NH농협/삼성카드 20%
    L.POINT 30% -> 롯데/BC/KB국민/신한/NH농협/삼성카드 20%
    봉지굴(260g/국산) 4,978원
    ※안전을 위해 가급적 익혀드시기를 권장합니다
    ※페이지 하단 카드할인 세부내용 참고

    4일간, 해당카드 결제시 반값!
    롯데/BC/KB국민/신한/NH농협/삼성카드 50%
    한통 가아아득 치킨(팩/국내산 계육) 6,495원
    ※기간 : 11/7(목) ~ 11/10(일)
    ※ 11/11(월) ~ 11/13(수) 구매시 9,990원
    ※제주점 제외
    ※ 한정물량으로 점별 수량 상이

    대파(봉/국산) 1봉1,580원 1,580원 
    ※L.POINT회원 해당카드 결제시에 한함 
    농협할인 20% -> 롯데/BC/KB국민/신한/NH농협/삼성카드 500원 추가할인
    ※1일 1인 2봉 구매 한정 ※L.POINT 회원 기간 중 최대 2만원 할인
    ※산지 시황에 따라 조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고

    2kg 9,990원 ※L.POINT회원 해당카드 결제시에 한함
    영동 비파괴 당도선별 샤인머스캣 (2kg/박스/국산) 9,990원
    ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고
    L.POINT 4,000원 -> 롯데/BC/KB국민/신한/NH농협/삼성카드 1,000원 추가할인 \
    영동군과 함께 농가를 응원합니다!

    봉지라면 1+1
    봉지라면 8종(상품별 규격 상이) 각3,880~8,700원
    ※동일 브랜드 내 동일 가격 간 교차구매 가능
    ※오뚜기 굴진짬뽕 4개입 外 7종

    매일 오전 10시 ~ 오후 1시
    타임쿠폰팩 다운받고 즉시 사용하세요!
    ※기간 : 10/31(목) ~ 11/13(수) ※쿠폰 관련 세부내용은 롯데마트몰에서 확인하실 수 있습니다
    5만원 이상 구매시 3,000원 할인 
    10만원 이상 구매시 10,000원 할인

    ※정상가란? 2024년 10월 17일(목) ~ 11월 6일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 10월 10일(목) ~ 10월 30일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    땡큐 하루 특가
    11/7 목
    산더미 대파 소불고기(800g/냉장/원산지 별도표기) 12,900원
    ※기간 외 : 11/8(금)~11/13(수) L.POINT 40% 15,480원
    L.POINT 50%

    파프리카(개/국내산) 롯데/BC/신한/NH농협/삼성카드 200원 할인 890원
    ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고

    11/8 금
    국산 생물 흰다리새우 (100g/냉장/국산) 2,392원
    L.POINT 20%

    껍질벗긴새우 (750g/냉동/원산지 별도표기) 11,940원
    L.POINT 40%

    씨제이 햇반 유기농 쌀밥 9,980원
    (210g×12입) ※1인당 2개 구매 한정
    L.POINT 4,000원 할인

    11/9 토
    양파(1.5kg/국내산) 2,960원
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인
    ※산지 시황에 따라 조기 품절될 수 있습니다
    농협할인 20%

    퐁퐁 전품목(상품별 상이) 해당카드로 2개 이상 구매시 1개당 각1,920~3,840원
    ※해당상품에 한함
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각4,800~9,600원
    롯데/BC/신한/NH농협/삼성카드 2개 이상 60%

    11/10 일
    애호박(개/국내산) 990원
    ※조기품절될 수 있습니다

    땡큐 주말특가
    기간 : 11/7(목) ~ 11/10(일)
    캐나다 삼겹살/목심(각 100g/냉장/캐나다산) 각990원
    롯데/BC/신한/NH농협/삼성카드 50%
    ※기간 : 11/9(토)~11/10(일) ※1인 2팩 구매 한정
    ※조기품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고

    롯데/BC/신한/NH농협/삼성카드 2+1
    삼립 호빵 3입/4입 5종(상품별 용량 상이) 각4,980~5,880원
    ※3개 구매시 34% 할인 ※교차구매 가능
    ※페이지 하단 카드할인 세부내용 참고

    페레로로쉐 T16 벨타입(200g) 18,800원
    ※페이지 하단 카드할인 세부내용 참고
    롯데/BC/신한/NH농협/삼성카드 1+1

    1+1
    파스퇴르 쾌변 요구르트 3종(각 150ml×4입) 6,680원
    ※사과/키위/ABC ※교차구매 가능

    국민맥주 라거편(500ml/캔) 990원
    ※기간 : 11/7(목)~11/9(토)
    ※페이지 하단 카드할인 세부내용 참고
    롯데마트 단독
    L.POINT 400원 할인 -> 롯데/BC/신한/NH농협/삼성카드 810원 할인

    땡 잡았다!
    최대 50% 할인
    요리하다 양념육 전품목(상품별 중량 상이/원산지 별도표기) 최대 50% 할인
    ※단, 제주 흑돼지 간장/고추장 불고기 제주점 제외

    동원 양반김 전품목(상품별 상이) 최대 50% 할인

    2개 이상 50%
    청년다방 깻잎고추튀김/오짱떡볶이/하코야 매운닭다리살/통닭다리살 꼬치 2개 이상 구매시 1개당 각4,990~7,490원
    (상품별 상이) ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각9,980~14,980원

    해피콜 뉴 골든 구르메 프라이팬 5종(상품별 상이) 50% 각17,450~26,950원
    
    글라스락 김치맛 지킴이 핸디퓨어 3종(상품별 상이) 50% 각12,450~15,950원

    2개 이상 50%
    쏘피/쏘피에일/라이프리/마미포코 최대 48종 2개 이상 구매시 1개당 각4,250~17,950원
    (상품별 상이) ※각 브랜드별 교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각8,500~35,900원
    행사상품 3만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " G "를 확인하세요 ※결제시 계산대에서 수령

    2개 이상 50%
    퍼실/퍼울 액체세제 전품목(상품별 상이) 2개 이상 구매시 1개당 각8,750~14,950원
    ※교차구매 가능 ※퍼실 디스크 캡슐세제 제외
    ※1개 구매시 각17,500~29,900원

    다우니 유연제 실내건조/생화향기 8종 해당카드로 2개 이상 구매시 1개당 각9,800/11,650원
    (각 2L/2.6L) ※페이지 하단 카드할인 세부내용 참고
    ※1개 구매시 각 19,600/23,300원 ※교차구매 가능
    롯데/BC/신한/NH농협/삼성카드 2개 이상 50%

    2개 이상 50%
    룸바이홈 스마트 S 옷걸이 外 10종 (상품별 상이) 2개 이상 구매시 1개당 각1,950~2,950원
    ※점별 재고 상이 ※교차구매 가능
    ※1개 구매시 각3,900~5,900원

    2개 이상 50%
    오늘좋은 피치 극세사 이불 外 11종(상품별상이) ※교차구매 가능 ※행사상품에 한함
    2개 이상 구매시 1개당 각9,450~29,950원
    ※1개 구매시 각18,900~59,900원

    오늘좋은 커브 메모리폼 베개 2종 2개 이상 50%
    (상품별상이) ※교차구매 가능 ※행사상품에 한함
    2개 이상 구매시 1개당 각11,950/12,950원
    ※1개 구매시 각23,900/25,900원 

    ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
    ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
    땡 잡았다! 하나 사면 하나 더 1+1

    1+1
    사각/국탕용 어묵 5종 (상품별 상이) 3,280~12,980원
    ※동일 상품에 한함

    롯데/BC/신한/NH농협/삼성카드 1+1
    동원 퀴진 크리스피 돈까스 2종 각9,980원
    (통등심 480g/치즈 450g) ※교차구매 가능

    1+1
    오뚜기 떡볶이 3종(상품별 상이) 각4,980원
    ※국물/쌀/라볶이 ※교차구매 가능

    1+1
    동원 딤섬 3종(상품별 상이) 10,980~11,980원
    ※새우하가우/부추창펀/샤오롱바오 ※동일 가격 교차구매 가능

    1+1
    녹차원 절임차(1kg)/자임 하루하나 유기농 레몬즙(20g×14입) 9,900~15,900원
    ※절임차 1kg 교차구매 가능 ※레몬즙 동일 품목 적용

    1+1 롯데마트 단독
    로티로리 유아간식 14종 (상품별 규격 상이) 2,200~4,500원
    ※동일매가 교차구매 가능

    1+1
    청정원 씨간장숙성 양조간장 골드(1.7L) 18,480원

    1+1
    바릴라 파스타면 4종(상품별 규격 상이) 각4,680~7,680원
    ※스파게티/링귀니/푸실리 ※동일 상품에 한함

    1+1
    동원 리챔 오리지널 (200g×3입) 14,480원

    1+1
    사조 살코기/마일드/고추참치 (각 85g×4입) 각8,980원
    ※교차구매 가능

    1+1
    삼양 불닭소스 4종(상품별 규격 상이) 각4,200원
    ※불닭/불닭마요/핵불닭/까르보불닭 ※교차구매 가능

    1+1
    코인 조미료 12종(상품별 규격 상이) ※점별 운영상품 상이 각7,800~9,900원
    ※동일 브랜드&동일 가격 상품간 교차구매 가능

    1+1
    테일러 유기농 하루 레몬즙(20ml×10입) 11,900원

    1+1
    칠성사이다/펩시/펩시제로/밀키스 (각 190ml×6입) 각5,480원
    ※교차구매 가능

    1+1
    남양 아라비카 골드라벨 누보 50입 (10.7g×50입) 11,200원

    1+1
    동원 참치액 3종 (각 500g, 순/진/해물) 각7,980원
    ※교차구매 가능
    동원에프앤비 전품목 4만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉢"를 확인하세요 ※결제시 계산대에서 수령

    1+1
    해찬들 100% 국산고추장/원조 태양초 고추장 각19,800원
    (상품별 규격 상이)
    ※교차구매 가능
    씨제이 제일제당 전품목 4만 5천원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "ⓒ"를 확인하세요 ※결제시 계산대에서 수령

    1+1
    아임리얼 당근/케일 (각 700ml) ※교차구매 가능 각10,980원

    1+1
    슬라이스 치즈/스낵치즈/자연치즈25종 (상품별 상이) 각3,980~10,580원
    ※서울우유/남양/빙그레/동원 外
    ※동일 브랜드 내 동일 가격 간 교차구매 가능

    1+1
    매일 상하 스트링 치즈(18g×10입) 12,980원

    1+1
    떠먹는 요구르트 30종(각 80g×4입/85g×4입) 각3,780~6,480원
    ※빙그레/풀무원/남양/매일/일동/동원
    ※동일 브랜드 내 동일 가격 간 교차구매 가능

    1+1
    가공우유 23종(상품별 상이) 각1,900~9,600원
    ※푸르밀/빙그레/동원/매일 外 ※기간 : 11/1(금)~11/13(수)
    ※동일 브랜드 내 동일 가격 간 교차구매 가능

    1+1
    상온/냉장커피 단품 전품목(상품별 상이) 각1,780~3,980원
    ※동일 시리즈간 교차구매 가능 ※일부품목 제외

    1+1
    마즈 스니커즈 펀사이즈/트윅스 미니스(각 500g) 각12,980원
    ※교차구매 가능

    1+1
    허쉬 초콜릿칩 쿠키/초콜릿칩 베리 쿠키(각 144g) 각4,790원
    ※교차구매 가능

    1+1
    켈로그 프링글스 10종 (상품별 용량 상이) 각3,330원
    ※교차구매 가능

    1+1
    동서 미떼 핫초코 오리지날/마일드 (각 30g×10입) 각6,500원
    ※교차구매 가능

    1+1
    미쟝센 헤어앤 스칼프 부스터 샴푸(800g)/트리트먼트(300g) 각9,800원
    ※너리싱/딥클린/하이드로 ※교차구매 가능

    1+1
    해피바스 비타민 바디워시(각 750g) 각7,900원 
    ※생귤/유채꽃 ※교차구매 가능

    아모레퍼시픽 행사상품 3만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉵ "를 확인하세요 ※결제시 계산대에서 수령
    
    1+1
    수퍼테크 용기 (각 3L, 일반/드럼) 각12,900원
    ※교차구매 가능

    1+1
    룸바이홈 층간소음 줄이는 거실화 (270mm/290mm) 각11,900원
    ※핑크 外 ※교차구매 가능
    ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

    1+1
    락앤락 클리어 비스프리(상품별 상이) 4,000~15,200원
    ※동일가격 상품에 한함

    ※정상가란? 2024년 10월 17일(목) ~ 11월 6일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 10월 10일(목) ~ 10월 30일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

    ※전단적용기간 : 2024. 11. 7(목) ~ 11. 13(수)

  </div>
  `,
  `
  <div>
    땡 잡았다 득템찬스

    L.POINT 각 50%
    참프레/정다운 훈제오리 슬라이스 (각 400g/냉장/원산지 별도표기) 각6,990원
    ※전점 70,000개 한정수량

    물가안정 오징어 (마리/해동/원양산) 2,200원

    물가안정 참치회 (350g/냉장/원산지 별도표기) 19,900원
    ※입점점에 한함

    L.POINT 10,000원
    파타고니아 생연어 필렛 (500g/냉장/칠레산) 19,900원

    2개 이상 50%
    코주부 징기스칸 육포 순한맛/매운맛 2개 이상 구매시 1개당 각7,450원
    (각 130g/원산지 별도표기) 
    ※교차구매 가능
    ※1개 구매시 각14,900원

    2개 이상 50%
    동원 리얼크랩스/랍스터 (각 216g) 2개 이상 구매시 1개당 각2,990원
    ※교차구매 가능
    ※1개 구매시 각 5,980원
    
    2개 이상 각 3,980원 할인
    풀무원 노엣지피자/핫도그 6종(상품별 상이) 2개 이상 구매시 1개당 각5,000원
    ※점별 운영상품 상이 ※교차구매 가능
    ※1개 구매시 각 8,980원

    2개 이상 50%
    BBQ 더바삭 닭강정 2종(각 500g, 극한왕갈비맛/매콤양념) 2개 이상 구매시 1개당 각4,990원
    ※교차구매 가능
    ※1개 구매시 각 9,980원

    2개 이상 최대2,480원 할인
    풀무원 전 5종(상품별 규격 상이) 2개 이상 구매시 1개당 각5,000원 
    ※철판 감자채전/오징어부추전, 떡갈비, 동그랑땡, 고기완자
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각 5,980~7,480원

    1+1
    CJ 더건강한 베이컨(90g×2)/육공육 슬라이스 씬(250g) 8,990/9,480원
    ※동일상품에 한함    

    L.POINT 7,000원
    남양 드빈치 자연방목 피자치즈(1kg) 10,980원

    5,920원 할인
    서울 멸균 흰우유 (200ml×24입) 10,980원

    빙그레 바나나맛 우유 기획 3종 800원 할인 각7,280원 
    (각 240ml×6입, 빙그레 바나나맛+딸기맛 外 2종)

    L.POINT 1,000원
    남양 마시는 불가리스 기획 5종(각 150ml×8입) 7,980원
    ※남양 마시는 불가리스 사과 外 4종

    2+1
    스타벅스 미디엄로스트/다크로스트 아메리카노 각11,900원
    (각1.1g×30입) ※교차구매 가능

    2+1
    담터 호두 아몬드 율무차 (18g×30입) 9,900원

    동서식품 전상품 5만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 '#'를 확인하세요 ※결제시 계산대에서 수령    
    동서 맥심 커피믹스 모카골드 마일드(12g×220입+30입)/화이트골드 (11.7g×220입+30입) 각28,430원

    1+1
    칠성사이다 外 4종(각 1.25L) 각3,280원
    ※칠성사이다제로/펩시/펩시제로/펩시블랙
    ※교차구매 가능

    2+1
    롯데 빈츠/롯데 빈츠 롯데타워 (각 204g) 각4,780원
    ※교차구매 가능

    최대5,920원 할인
    햇님마을 영양농산 태양초 고춧가루 (250g/1kg) 12,900/39,800원

    L.POINT 8,000원
    CJ 스팸 클래식 라이트 기획(200g×3+200g) 11,980원

    L.POINT 6,000원
    종근당건강 락토핏 생유산균 골드(2g×50포) 9,900원

    2개 이상 50% -> 롯데/BC/신한/NH농협/삼성카드 2,000원 추가할인
    크리넥스 수프림소프트(27m×30롤) 해당카드로 2개 이상 구매시 1개당 각17,700원
    ※페이지 하단 카드할인 세부내용 참고
    ※1개 구매시 39,400원

    2+1
    롯데 더쌀로 사르르 핫쉬림프맛(100g) 2,720원

    테팔 행사상품 5만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 '㉫'를 확인하세요 ※결제시 계산대에서 수령
    테팔 스타트이지 프라이팬/뉴 쉐프터치 프라이팬/인텐시움 통3중 냄비/티타늄 블라썸 냄비 (상품별 상이) 40% 할인

    최대 40% -> 롯데/BC/신한/NH농협/삼성카드 추가 10%
    포트메리온 보타닉가든(상품별 상이)
    ※페이지 하단 카드할인 세부내용 참고

    2개 이상 40%
    크린랩 크린장갑(200매) 2개 이상 구매시 1개당 각5,454원
    ※1개 구매시 9,090원

    4입 증량 20% 중량업
    해태 맛동산 20% 중량업(288g)/해태 오예스 24입 기획(720g) 3,480/7,880원

    1+1 기획팩
    3M 항균 삼중양면 고운/다목적 수세미(각 2입) 각3,490원

    각2,000원 할인
    산리오 윈터 슈즈 모음전 각15,800원
    ※점별 입점 상품 상이 ※행사 대상 상품에 한함

    50%
    패스트 뉴블랙트럭캐리어 (3세 이상/62.5×12×20.5cm) 19,950원

    5캔 구매시 캔 당 1,990원
    세계맥주 골라담기 9,950원
    ※1캔 구매시 각3,000원

    ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
    ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
    990원 땡전딜!

    하림/참프레 닭다리살(정육) (각 100g/냉장/국내산) 990원
    ※제주점 제외

    요리하다 사골곰탕(500g) 990원

    오늘좋은 백미밥(210g) 990원

    파워에이드 外 4종(상품별 용량 상이) 각 990원
    ※파워에이드/토레타/토레타 제로/환타 오렌지/환타 파인제로

    오늘좋은 웨이퍼롤 3종 각 990원
    (각 100g) ※바닐라/초코/카푸치노
    
    코코토토 매일매일 깨끗한 물티슈(100매) 990원

    최대510원 할인
    벨버드 황태오리버거/더주오 통살 닭가슴살(상품별 용량 상이) 각 990원
    ※입점점에 한함 

    최대1,510원 할인
    룸바이홈 다용도/손잡이 바스켓, 신발정리대 12종 각 990원
    ※점별 재고 상이 
    ※한정수량으로 조기 품절될 수 있습니다

    4,990원 땡전딜!
    강원 감자(2kg/국산) 4,990원

    2개 이상 각4,000원 할인
    고구마말랭이 外 3종 2개 이상 구매시 1개당 각4,990원
    (상품별 상이/원산지 별도표기)
    ※1개 구매시 각8,990원 ※교차구매 가능

    남양 맛있는 우유 GT 기획(900ml×2) 890원 할인 4,990원

    샤워메이트 바디워시 5종 (상품별 용량 상이) 각4,990원
    ※점별 운영상품 상이

    데일리콤마 로더 디퓨저 4종(각 200ml) 4,910원 할인 각4,990원

    아머올 에탄올 워셔액 (1.8L×2개입) 4,990원

    오늘좋은 데일리 테이프클리너 리필(대형/6P) 1,910원 할인 4,990원

    맥스 폭발방지 CRV 부탄가스(4P) 1,800원 할인 4,990원

    1+1 기획팩
    유니랩 위생장갑 기획팩 4,990원

    9,990원 땡전딜!
    바베큐 오징어 기획 (300g/원산지 별도표기) 9,990원

    L.POINT 50%
    직소싱 번들 새우살 (각 250g×2/냉동/원산지 별도표기) 각9,990원

    3+1 골라담기
    반찬 3+1팩 골라담기 (4팩/원산지 별도표기)
    ※ 비움/미찬/도시곳간/무명반찬/푸드앤찬/초이스쿡 입점점에 한함
    ※정상가 4,000원 이하 상품 한정

    CJ 쿡킷/비비고 밀키트 3종/청정원 호밍스 밀키트 3종(상품별 상이) 각9,990원
    ※ 곱창전골, 곱창순대전골, 불쭈꾸미볶음/김치어묵우동전골, 우삽겹 스키야키, 춘천식 닭갈비

    고려은단 비타민C 2종 (상품별 상이) 각9,990원
    ※C1000/이지비타민

    3캔 구매시
    하이볼 골라담기(상품별 용량 상이) 9,990원
    ※퐁당레몬/라임, 생레몬/라임/청귤 外 5종
    ※1캔 구매시 각 4,000원

    에너자이저 맥스 10입 (5입 보너스팩) 각9,990원
    ※AA/AAA

    엘라스틴 프로폴리테라 7종(상품별 용량 상이) 각9,990원
    ※점별 운영상품 상이

    나무야나무야 깨끗한 무향 3겹 화장지(27m×30롤) 9,990원

    오늘좋은 퍼 방한 슬리퍼 5,810원 할인 9,990원 
    ※입점점에 한함 ※점별 재고 상이

    ※정상가란? 2024년 10월 17일(목) ~ 11월 6일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 10월 10일(목) ~ 10월 30일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

    ※전단적용기간 : 2024. 11. 7(목) ~ 11. 13(수)

  </div>
  `,
  `
  <div>
    축산
    마블나인 한우 전품목 (1++(9)등급/각 100g/냉장/국내산 한우고기) L.POINT 최대 50%
    ※조기 품절될 수 있습니다

    "김장시즌 김치와의 환상궁합" 브랜드 돼지고기 삼겹살/앞다리 수육용 각3,690/1,990원
    (각 100g/냉장/국내산 돼지고기)
    ※제주점 제외 ※입점점포에 한함 ※MAP 규격팩 제외

    미국산 갈비살/아롱사태(도매용) (각 100g/냉장/미국산) 2,490/1,890원
    L.POINT 각 40%

    L.POINT 각 10,000원
    미국산/호주산 LA갈비 (각 1kg, 냉동, 미국산/호주산) 각39,900원
    ※조기 품절될 수 있습니다

    수산
    L.POINT 3,000원
    김장용 햇추젓 (1kg/냉장/국산) 13,900원

    L.POINT 3,000원
    대천 전통 재래 도시락김 (4g×30봉/원산지 별도표기) 8,990원

    L.POINT 30%
    프리미엄 시그니처 장어초밥 (8입/냉장/원산지 별도표기) 8,960원
    ※입점점에 한함

    L.POINT 25%
    완도 활 전복 (대/마리/냉장/국산) 1,987원

    L.POINT 35%
    서해안 꽃게(100g/냉장/국산) 1,293원
    ※산지상황에 따라 품절 될 수 있습니다

    채소
    농협할인 20%
    배추(통/국산) 3,488원

    농협할인 20%
    안동 햇 생강(400g/봉/국산) 3,560원

    GAP친환경느타리버섯 (500g/팩/국산) 1,990원

    피그인더가든 데일리믹스 샐러드 (315g/팩/원산지 별도표기) 3,990원

    과일
    L.POINT 5,000원
    상생배(3kg/박스/국산) 10,990원
    ※조기 품절될 수 있습니다

    레드키위 (9~13입/팩/국산) 9,990원

    L.POINT 3,000원
    스위텔토마토(800g/팩/국산) 9,990원

    10개 구매시
    단감(개/국산) 9,900원
    ※1개 구매시 1,290원

    잡곡/계란
    경기미(20kg/국산) 49,900원

    2개 이상 50%
    파로 혼합곡 (각 1.5kg/원산지 별도표기) 2개 이상 구매시 1개당 각4,995원
    ※1개 구매시 9,990원

    동물복지인증 유정란(대란/25구/국산) 9,990원

    2봉 이상 50%
    풀무원/CJ 유기농 콩나물 (각 270g/원산지 별도표기) 2봉 이상 구매시 1봉당 각1,045원
    ※1봉 구매시 각2,090원
    ※동일 브랜드에 한함

    간편식 요리
    L.POINT 40%
    ALL NEW 새우초밥(20입/1팩/원산지 별도표기) 11,994원

    김밥&롤 닭강정/유부초밥 세트 (1팩/원산지 별도표기) 각7,990원

    L.POINT 40%
    5무 훈제오리 (1팩/원산지 별도표기) 9,594원

    추억의 깨찰빵(8입/1팩/원산지 별도표기) 5,500원 할인 7,500원
    ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함

    ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
    ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
    냉장/냉동 식품
    2개 이상 각1,000원 할인
    CJ 고메 소바바 치킨 5종 (상품별 상이) 2개 이상 구매시 1개당 각7,980원
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각8,980원

    CJ 비비고 갈비왕교자 (420g×2) 9,480원
    ※운영점에 한함

    동원 통그릴비엔나 (1kg) 10,980원

    목우촌 주부9단 프랑크소세지(500g) 5,480원

    유제품/음료 
    1+1
    과채주스 대용량 단품 전품목(1.2L~1.8L) 각3,280~7,980원
    ※동일시리즈간 교차구매 가능 ※점별 운영상품 상이

    1+1
    두유 16입 전품목(상품별 상이) 각14,450~20,000원
    ※동일시리즈간 교차구매 가능

    롯데마트 단독
    풀무원 다논 액티비아 플레인(80g×16입) 6,980원

    롯데마트 단독
    매일유업 상하 마일드 체다 슬라이스 치즈(17g×50입) 9,980원

    주류
    크러시 꼬깔콘 기획팩 (470ml×8) 각12,450원

    2캔 구매시
    카발란 Dry 하이볼(320ml) 8,980원
    ※기간 : 11/1(금)~11/13(수)
    ※1캔 구매시 각4,900원

    ONLY 롯데마트
    요리하다 단팥/슈크림 붕어빵(각 400g) 2,000원 할인 4,990원

    요리하다 쫄깃한 찹쌀 탕수육(550g) 3,000원 할인 4,990원

    500g ×2개입 기획
    요리하다 찰통순대 기획(500g×2) 5,990원

    요리하다 새콤바삭 유린기(480g) 3,000원 할인 4,990원

    롯데웰푸드
    수능 행사상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 ' A 를' 확인하세요 ※결제시 계산대에서 수령

    1+1
    롯데 키세스 초콜릿 4종(각 146g) 각5,280원
    ※밀크/아몬드/다크/쿠키앤크림 ※교차구매 가능

    1+1
    롯데 허쉬 초콜릿 스낵사이즈 3종 각5,520원
    (각 165g) ※밀크/아몬드/쿠키앤크림 ※교차구매 가능

    롯데 길리안 시쉘 초콜릿 2종(오리지날 250g/다크 225g) 1,220원 할인 17,980원

    롯데 명가 찰떡파이 (350g) 300원 할인 5,280원

    롯데웰푸드
    빼빼로데이 행사상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 ' P 를' 확인하세요 ※결제시 계산대에서 수령

    롯데 빼빼로 4종(상품별 규격 상이) 각4,780원
    ※초코/아몬드/크런키/화이트쿠키

    롯데빼빼로 8갑 기획 4종(상품별 규격 상이) 각11,520원
    ※초코/아몬드/크런키/화이트쿠키

    롯데 빼빼로 케이크박스 기획 (380g) 14,400원
    ※점별 운영상품 상이    

    롯데 빼빼로 슬라이딩 기획 (150g) 5,760원
    ※점별 운영상품 상이

    ※정상가란? 2024년 10월 17일(목) ~ 11월 6일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 10월 10일(목) ~ 10월 30일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

    ※전단적용기간 : 2024. 11. 7(목) ~ 11. 13(수)

  </div>
  `,
  `
  <div>
    브레프 행사상품 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ♬ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    브레프 전품목(상품별 상이) 2개 이상 구매시 1개당 각2,250~4,450원
    ※1개 구매시 각4,500~8,900원 ※교차구매 가능

    피죤 행사상품 2만 5천원 이상 구매시
    5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ▣ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    고농축 피죤 시그니처 리필 3종 2개 이상 구매시 1개당 각7,450원
    (각 2.3L) ※미스틱레인/플라워페스티벌/라피에스타
    ※1개 구매시 각14,900원 ※교차구매 가능

    크리넥스 물티슈 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉪ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    크리넥스 물티슈 8종(상품별 상이) 2개 이상 구매시 1개당 각2,145~7,950원
    ※마이비데 클린케어 휴대용/엑스라지 外
    ※1개 구매시 각4,290~15,900원 ※교차구매 가능

    피지오겔/AHC 해당상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수) ※브랜드별 합산불가
    ※각 브랜드별 3만원 이상 구매시 해당
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 : 피지오겔 " ㉲ "/ AHC " ㉳ "를 확인하세요
    ※결제시 계산대에서 수령

    피지오겔 DMT크림/AHC 콜라겐 크림(상품별 상이) 26,910/25,900원

    아모레퍼시픽 행사상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉵ "를 확인하세요
    ※결제시 계산대에서 수령

    1+1
    메디안 잇몸과학 치약(각 120g×3입) 각12,980원
    ※스트롱민트/클린민트 ※교차구매 가능 ※점별 운영상품 상이

    1+1
    다우니 세탁세제 리필 실내건조/헹굼제로(각 3.2L) 각36,900원
    ※교차구매 가능

    2개 이상 50%
    2개 이상 구매시 1개당 6,050원
    크리넥스 홈냅킨 퓨어화이트(130매×6입)
    ※1개 구매시 12,100원
    크리넥스 키친타월 2만 5천원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉸"를 확인하세요 ※결제시 계산대에서 수령

    롯데/BC/신한 NH농협/삼성카드 2개 이상 55%
    리엔 보윤 샴푸/컨디셔너(각 1,000ml) 해당카드로 2개 이상 구매시 1개당 각7,155원
    ※1개 구매시 각15,900원
    ※교차구매 가능 ※점별 운영상품 상이
    ※페이지 하단 카드할인 세부내용 참고

    롯데/BC/신한/NH농협/삼성카드 2개 이상 60%
    히말라야 핑크솔트 바디워시 3종 (각 900g) 해당카드로 2개 이상 구매시 1개당 각5,960원
    ※로즈/리프레싱민트/포레스트민트
    ※1개 구매시 각14,900원 ※교차구매 가능
    ※페이지 하단 카드할인 세부내용 참고

    2개 이상 20%
    핸드&풋 크림 모음전(상품별 상이) 2개 이상 구매시 1개당 각1,520~12,720원
    ※1개 구매시 각1,900~15,900원 ※교차구매 가능
    ※점별 운영상품 상이

    L.POINT 5,000원
    아임비타 이뮨플러스(7입) 11,900원

    쿡셀 전품목 5만원 이상 구매시 10,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/27(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ◆ "를 확인하세요
    ※결제시 계산대에서 수령
    쿡셀 전품목(상품별 상이) 20% 31,120/50,240원

    3M 청소/Bath 전품목 3만원 이상 구매시 5,000원 롯데상품권 증정
    불스원 전품목 3만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "M"를 확인하세요
    ※결제시 계산대에서 수령
    3M 베이킹소다 톡톡 크린스틱 시트 타입(소)/대형 테이프 클리너 팩(상품별 상이) 20% 10,800/15,390원
    ※입점점에 한함 ※해당 상품에 한함 ※점별 재고 상이

    대림바스 전품목 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "D"를 확인하세요
    ※결제시 계산대에서 수령
    대림바스 스마트 초슬림 무소음 하드 변기커버 D형(39.3×48cm) 15% 22,010원
    ※입점점에 한함 ※해당 상품에 한함 ※점별 재고 상이

    불스원 전품목 3만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉥ "를 확인하세요
    ※결제시 계산대에서 수령
    불스원 레인OK 발수 와이퍼 (350~650mm) 1+1 밴딩 8,100~12,800원

    티피링크 전품목 5만원 이상 구매시 10,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉠ "를 확인하세요
    ※결제시 계산대에서 수령
    티피링트 tapo 전품목(상품별 상이) 12,900~79,000원

    보디가드 남성/여성 기모내의 세트(상품별 상이) 각9,900원

    비비안 웜업 터틀넥 (상품별 상이) 각9,900원

    데일리 남성/여성 단목, 중목양말(각 8매) 각9,900원

    개이득 냥이득!
    ※ 기간 : 10/31(목) ~11/13(수)
    colioli 득템위크
    반려동물식품&용품 480여종 할인!
    반려동물식품/용품 3만원 이상 구매시 5천원 할인쿠폰 증정!
    ※해당카드 : 롯데/BC/신한/NH농협/삼성카드
    ※영수증 內 할인쿠폰으로 발급 ※12월 한달 간 사용가능
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " @ "를 확인하세요 ※입점점에 한함
    ※L.POINT회원에 한함 ※페이지 하단 카드할인 세부내용 참고

    롯데/BC/신한/NH농협/삼성카드 30%
    콜리올리 웰밸런스 사료 4종 (상품별 상이) 9,730~14,630원
    ※점별 재고 상이
    ※퍼피/성견/중형견/대형견

    콜리올리 순살가득 시리즈 4종 (각 300g) ※점별 재고 상이 각8,330원
    롯데/BC/신한/NH농협/삼성카드 30%

    콜리올리 깔끔하게 사용하는 1회용 패드 3종(M 100매/M 200매/L 40매) 8,330~16,730원
    ※입점점에 한함
    롯데/BC/신한/NH농협/삼성카드 30%

    L.POINT 10% -> 롯데/BC/신한/NH농협/삼성카드 10% 추가할인
    이나바 챠오츄르 버라이어티 (20P/40P) 12,069/21,465원
    ※입점점에 한함

    롯데/BC/신한/NH농협/삼성카드 30%
    월간스틱/월간통살 고양이 간식(30P) 각7,630원
    ※입점점에 한함

    롯데/BC/신한/NH농협/삼성카드 30%
    콜리올리 냄새잡는 벤토나이트 모래(무향 6.5kg) 9,730원
    ※입점점에 한함

    toysrus
    제프리 생일파티에 초대합니다 
    인기 완구류 해당상품 최대 28,500원 할인!

    슈팅스타! 티니핑 피규어 출시!
    스타 하츄핑 피규어 外 10종 (3세 이상/상품별 상이) 각12,900~14,900원
    ※입점점에 한함 ※점별 재고 상이

    레고 깊고 어두운 전장 (21246) 28,500원 할인 66,400원
    더 많은 상품을 토이저러스몰에서 만나보세요! ▶

    ※정상가란? 2024년 10월 17일(목) ~ 11월 6일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 10월 10일(목) ~ 10월 30일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const zettaSeoulData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
    ·전단적용기간 : 2024. 11. 7(목) ~ 2024. 11. 13(수) < 비신선식품 적용기간 : 2024. 10. 31(목) ~ 11. 13(수) >
    제타플렉스 서울역점

    LOTTE MART ZETTAPLEX SEOUL STATION가 준비한 고객 감사 대축제

    땡큐절
    매일 10시 아침 종이 땡 미친 할인 큐

    땡 잡았다! 
    1 하루ㆍ주말 특가
    2 50%ㆍ1 + 1
    3 득템 찬스
    4 땡 전딜

    초이스 척아이롤/부채살
    L.POINT 50%
    ※기간 : 11/7(목) ~ 11/10(일)
    ※조기 품절될 수 있습니다

    L.POINT 50%
    초이스 척아이롤 (100g/냉장/미국산) 1,590원

    L.POINT 50%
    초이스 부채살 (100g/냉장/미국산) 1,890원

    L.POINT 회원 2,000원 할인!
    L.POINT 2,000원 할인!
    부사사과 (4~6입/봉/국산) L.POINT 2,000원 
    6,990원
    ※조기 품절될 수 있습니다

    봉지굴
    L.POINT 30% -> 롯데/BC/KB국민/신한/NH농협/삼성카드 20%
    L.POINT 30% -> 롯데/BC/KB국민/신한/NH농협/삼성카드 20%
    봉지굴(260g/국산) 4,978원
    ※안전을 위해 가급적 익혀드시기를 권장합니다
    ※페이지 하단 카드할인 세부내용 참고

    4일간, 해당카드 결제시 반값!
    롯데/BC/KB국민/신한/NH농협/삼성카드 50%
    한통 가아아득 치킨(팩/국내산 계육) 6,495원
    ※기간 : 11/7(목) ~ 11/10(일)
    ※ 11/11(월) ~ 11/13(수) 구매시 9,990원
    ※제주점 제외
    ※ 한정물량으로 점별 수량 상이

    대파(봉/국산) 1봉1,580원 1,580원 
    ※L.POINT회원 해당카드 결제시에 한함 
    농협할인 20% -> 롯데/BC/KB국민/신한/NH농협/삼성카드 500원 추가할인
    ※1일 1인 2봉 구매 한정 ※L.POINT 회원 기간 중 최대 2만원 할인
    ※산지 시황에 따라 조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고

    2kg 9,990원 ※L.POINT회원 해당카드 결제시에 한함
    영동 비파괴 당도선별 샤인머스캣 (2kg/박스/국산) 9,990원
    ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고
    L.POINT 4,000원 -> 롯데/BC/KB국민/신한/NH농협/삼성카드 1,000원 추가할인 \
    영동군과 함께 농가를 응원합니다!

    봉지라면 1+1
    봉지라면 8종(상품별 규격 상이) 각3,880~8,700원
    ※동일 브랜드 내 동일 가격 간 교차구매 가능
    ※오뚜기 굴진짬뽕 4개입 外 7종

    매일 오전 10시 ~ 오후 1시
    타임쿠폰팩 다운받고 즉시 사용하세요!
    ※기간 : 10/31(목) ~ 11/13(수) ※쿠폰 관련 세부내용은 롯데마트몰에서 확인하실 수 있습니다
    5만원 이상 구매시 3,000원 할인 
    10만원 이상 구매시 10,000원 할인

    ※정상가란? 2024년 10월 17일(목) ~ 11월 6일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 10월 10일(목) ~ 10월 30일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    땡큐 하루 특가
    11/7 목
    산더미 대파 소불고기(800g/냉장/원산지 별도표기) 12,900원
    ※기간 외 : 11/8(금)~11/13(수) L.POINT 40% 15,480원
    L.POINT 50%

    파프리카(개/국내산) 롯데/BC/신한/NH농협/삼성카드 200원 할인 890원
    ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고

    11/8 금
    국산 생물 흰다리새우 (100g/냉장/국산) 2,392원
    L.POINT 20%

    껍질벗긴새우 (750g/냉동/원산지 별도표기) 11,940원
    L.POINT 40%

    씨제이 햇반 유기농 쌀밥 9,980원
    (210g×12입) ※1인당 2개 구매 한정
    L.POINT 4,000원 할인

    11/9 토
    양파(1.5kg/국내산) 2,960원
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인
    ※산지 시황에 따라 조기 품절될 수 있습니다
    농협할인 20%

    퐁퐁 전품목(상품별 상이) 해당카드로 2개 이상 구매시 1개당 각1,920~3,840원
    ※해당상품에 한함
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각4,800~9,600원
    롯데/BC/신한/NH농협/삼성카드 2개 이상 60%

    11/10 일
    애호박(개/국내산) 990원
    ※조기품절될 수 있습니다

    땡큐 주말특가
    기간 : 11/7(목) ~ 11/10(일)
    캐나다 삼겹살/목심(각 100g/냉장/캐나다산) 각990원
    롯데/BC/신한/NH농협/삼성카드 50%
    ※기간 : 11/9(토)~11/10(일) ※1인 2팩 구매 한정
    ※조기품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고

    롯데/BC/신한/NH농협/삼성카드 2+1
    삼립 호빵 3입/4입 5종(상품별 용량 상이) 각4,980~5,880원
    ※3개 구매시 34% 할인 ※교차구매 가능
    ※페이지 하단 카드할인 세부내용 참고

    페레로로쉐 T16 벨타입(200g) 18,800원
    ※페이지 하단 카드할인 세부내용 참고
    롯데/BC/신한/NH농협/삼성카드 1+1

    1+1
    파스퇴르 쾌변 요구르트 3종(각 150ml×4입) 6,680원
    ※사과/키위/ABC ※교차구매 가능

    국민맥주 라거편(500ml/캔) 990원
    ※기간 : 11/7(목)~11/9(토)
    ※페이지 하단 카드할인 세부내용 참고
    롯데마트 단독
    L.POINT 400원 할인 -> 롯데/BC/신한/NH농협/삼성카드 810원 할인

    땡 잡았다!
    최대 50% 할인
    요리하다 양념육 전품목(상품별 중량 상이/원산지 별도표기) 최대 50% 할인
    ※단, 제주 흑돼지 간장/고추장 불고기 제주점 제외

    동원 양반김 전품목(상품별 상이) 최대 50% 할인

    2개 이상 50%
    청년다방 깻잎고추튀김/오짱떡볶이/하코야 매운닭다리살/통닭다리살 꼬치 2개 이상 구매시 1개당 각4,990~7,490원
    (상품별 상이) ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각9,980~14,980원

    해피콜 뉴 골든 구르메 프라이팬 5종(상품별 상이) 50% 각17,450~26,950원
    
    글라스락 김치맛 지킴이 핸디퓨어 3종(상품별 상이) 50% 각12,450~15,950원

    2개 이상 50%
    쏘피/쏘피에일/라이프리/마미포코 최대 48종 2개 이상 구매시 1개당 각4,250~17,950원
    (상품별 상이) ※각 브랜드별 교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각8,500~35,900원
    행사상품 3만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " G "를 확인하세요 ※결제시 계산대에서 수령

    2개 이상 50%
    퍼실/퍼울 액체세제 전품목(상품별 상이) 2개 이상 구매시 1개당 각8,750~14,950원
    ※교차구매 가능 ※퍼실 디스크 캡슐세제 제외
    ※1개 구매시 각17,500~29,900원

    다우니 유연제 실내건조/생화향기 8종 해당카드로 2개 이상 구매시 1개당 각9,800/11,650원
    (각 2L/2.6L) ※페이지 하단 카드할인 세부내용 참고
    ※1개 구매시 각 19,600/23,300원 ※교차구매 가능
    롯데/BC/신한/NH농협/삼성카드 2개 이상 50%

    2개 이상 50%
    룸바이홈 스마트 S 옷걸이 外 10종 (상품별 상이) 2개 이상 구매시 1개당 각1,950~2,950원
    ※점별 재고 상이 ※교차구매 가능
    ※1개 구매시 각3,900~5,900원

    2개 이상 50%
    오늘좋은 피치 극세사 이불 外 11종(상품별상이) ※교차구매 가능 ※행사상품에 한함
    2개 이상 구매시 1개당 각9,450~29,950원
    ※1개 구매시 각18,900~59,900원

    오늘좋은 커브 메모리폼 베개 2종 2개 이상 50%
    (상품별상이) ※교차구매 가능 ※행사상품에 한함
    2개 이상 구매시 1개당 각11,950/12,950원
    ※1개 구매시 각23,900/25,900원 

    ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
    ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
    땡 잡았다! 하나 사면 하나 더 1+1

    1+1
    사각/국탕용 어묵 5종 (상품별 상이) 3,280~12,980원
    ※동일 상품에 한함

    롯데/BC/신한/NH농협/삼성카드 1+1
    동원 퀴진 크리스피 돈까스 2종 각9,980원
    (통등심 480g/치즈 450g) ※교차구매 가능

    1+1
    오뚜기 떡볶이 3종(상품별 상이) 각4,980원
    ※국물/쌀/라볶이 ※교차구매 가능

    1+1
    동원 딤섬 3종(상품별 상이) 10,980~11,980원
    ※새우하가우/부추창펀/샤오롱바오 ※동일 가격 교차구매 가능

    1+1
    녹차원 절임차(1kg)/자임 하루하나 유기농 레몬즙(20g×14입) 9,900~15,900원
    ※절임차 1kg 교차구매 가능 ※레몬즙 동일 품목 적용

    1+1 롯데마트 단독
    로티로리 유아간식 14종 (상품별 규격 상이) 2,200~4,500원
    ※동일매가 교차구매 가능

    1+1
    청정원 씨간장숙성 양조간장 골드(1.7L) 18,480원

    1+1
    바릴라 파스타면 4종(상품별 규격 상이) 각4,680~7,680원
    ※스파게티/링귀니/푸실리 ※동일 상품에 한함

    1+1
    동원 리챔 오리지널 (200g×3입) 14,480원

    1+1
    사조 살코기/마일드/고추참치 (각 85g×4입) 각8,980원
    ※교차구매 가능

    1+1
    삼양 불닭소스 4종(상품별 규격 상이) 각4,200원
    ※불닭/불닭마요/핵불닭/까르보불닭 ※교차구매 가능

    1+1
    코인 조미료 12종(상품별 규격 상이) ※점별 운영상품 상이 각7,800~9,900원
    ※동일 브랜드&동일 가격 상품간 교차구매 가능

    1+1
    테일러 유기농 하루 레몬즙(20ml×10입) 11,900원

    1+1
    칠성사이다/펩시/펩시제로/밀키스 (각 190ml×6입) 각5,480원
    ※교차구매 가능

    1+1
    남양 아라비카 골드라벨 누보 50입 (10.7g×50입) 11,200원

    1+1
    동원 참치액 3종 (각 500g, 순/진/해물) 각7,980원
    ※교차구매 가능
    동원에프앤비 전품목 4만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉢"를 확인하세요 ※결제시 계산대에서 수령

    1+1
    해찬들 100% 국산고추장/원조 태양초 고추장 각19,800원
    (상품별 규격 상이)
    ※교차구매 가능
    씨제이 제일제당 전품목 4만 5천원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "ⓒ"를 확인하세요 ※결제시 계산대에서 수령

    1+1
    아임리얼 당근/케일 (각 700ml) ※교차구매 가능 각10,980원

    1+1
    슬라이스 치즈/스낵치즈/자연치즈25종 (상품별 상이) 각3,980~10,580원
    ※서울우유/남양/빙그레/동원 外
    ※동일 브랜드 내 동일 가격 간 교차구매 가능

    1+1
    매일 상하 스트링 치즈(18g×10입) 12,980원

    1+1
    떠먹는 요구르트 30종(각 80g×4입/85g×4입) 각3,780~6,480원
    ※빙그레/풀무원/남양/매일/일동/동원
    ※동일 브랜드 내 동일 가격 간 교차구매 가능

    1+1
    가공우유 23종(상품별 상이) 각1,900~9,600원
    ※푸르밀/빙그레/동원/매일 外 ※기간 : 11/1(금)~11/13(수)
    ※동일 브랜드 내 동일 가격 간 교차구매 가능

    1+1
    상온/냉장커피 단품 전품목(상품별 상이) 각1,780~3,980원
    ※동일 시리즈간 교차구매 가능 ※일부품목 제외

    1+1
    마즈 스니커즈 펀사이즈/트윅스 미니스(각 500g) 각12,980원
    ※교차구매 가능

    1+1
    허쉬 초콜릿칩 쿠키/초콜릿칩 베리 쿠키(각 144g) 각4,790원
    ※교차구매 가능

    1+1
    켈로그 프링글스 10종 (상품별 용량 상이) 각3,330원
    ※교차구매 가능

    1+1
    동서 미떼 핫초코 오리지날/마일드 (각 30g×10입) 각6,500원
    ※교차구매 가능

    1+1
    미쟝센 헤어앤 스칼프 부스터 샴푸(800g)/트리트먼트(300g) 각9,800원
    ※너리싱/딥클린/하이드로 ※교차구매 가능

    1+1
    해피바스 비타민 바디워시(각 750g) 각7,900원 
    ※생귤/유채꽃 ※교차구매 가능

    아모레퍼시픽 행사상품 3만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉵ "를 확인하세요 ※결제시 계산대에서 수령
    
    1+1
    수퍼테크 용기 (각 3L, 일반/드럼) 각12,900원
    ※교차구매 가능

    1+1
    룸바이홈 층간소음 줄이는 거실화 (270mm/290mm) 각11,900원
    ※핑크 外 ※교차구매 가능
    ※입점점에 한함 ※해당상품에 한함 ※점별 재고 상이

    1+1
    락앤락 클리어 비스프리(상품별 상이) 4,000~15,200원
    ※동일가격 상품에 한함

    ※정상가란? 2024년 10월 17일(목) ~ 11월 6일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 10월 10일(목) ~ 10월 30일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

    ※전단적용기간 : 2024. 11. 7(목) ~ 11. 13(수)

  </div>
  `,
  `
  <div>
    땡 잡았다 득템찬스

    L.POINT 각 50%
    참프레/정다운 훈제오리 슬라이스 (각 400g/냉장/원산지 별도표기) 각6,990원
    ※전점 70,000개 한정수량

    물가안정 오징어 (마리/해동/원양산) 2,200원

    물가안정 참치회 (350g/냉장/원산지 별도표기) 19,900원
    ※입점점에 한함

    L.POINT 10,000원
    파타고니아 생연어 필렛 (500g/냉장/칠레산) 19,900원

    2개 이상 50%
    코주부 징기스칸 육포 순한맛/매운맛 2개 이상 구매시 1개당 각7,450원
    (각 130g/원산지 별도표기) 
    ※교차구매 가능
    ※1개 구매시 각14,900원

    2개 이상 50%
    동원 리얼크랩스/랍스터 (각 216g) 2개 이상 구매시 1개당 각2,990원
    ※교차구매 가능
    ※1개 구매시 각 5,980원
    
    2개 이상 각 3,980원 할인
    풀무원 노엣지피자/핫도그 6종(상품별 상이) 2개 이상 구매시 1개당 각5,000원
    ※점별 운영상품 상이 ※교차구매 가능
    ※1개 구매시 각 8,980원

    2개 이상 50%
    BBQ 더바삭 닭강정 2종(각 500g, 극한왕갈비맛/매콤양념) 2개 이상 구매시 1개당 각4,990원
    ※교차구매 가능
    ※1개 구매시 각 9,980원

    2개 이상 최대2,480원 할인
    풀무원 전 5종(상품별 규격 상이) 2개 이상 구매시 1개당 각5,000원 
    ※철판 감자채전/오징어부추전, 떡갈비, 동그랑땡, 고기완자
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각 5,980~7,480원

    1+1
    CJ 더건강한 베이컨(90g×2)/육공육 슬라이스 씬(250g) 8,990/9,480원
    ※동일상품에 한함    

    L.POINT 7,000원
    남양 드빈치 자연방목 피자치즈(1kg) 10,980원

    5,920원 할인
    서울 멸균 흰우유 (200ml×24입) 10,980원

    빙그레 바나나맛 우유 기획 3종 800원 할인 각7,280원 
    (각 240ml×6입, 빙그레 바나나맛+딸기맛 外 2종)

    L.POINT 1,000원
    남양 마시는 불가리스 기획 5종(각 150ml×8입) 7,980원
    ※남양 마시는 불가리스 사과 外 4종

    2+1
    스타벅스 미디엄로스트/다크로스트 아메리카노 각11,900원
    (각1.1g×30입) ※교차구매 가능

    2+1
    담터 호두 아몬드 율무차 (18g×30입) 9,900원

    동서식품 전상품 5만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 '#'를 확인하세요 ※결제시 계산대에서 수령    
    동서 맥심 커피믹스 모카골드 마일드(12g×220입+30입)/화이트골드 (11.7g×220입+30입) 각28,430원

    1+1
    칠성사이다 外 4종(각 1.25L) 각3,280원
    ※칠성사이다제로/펩시/펩시제로/펩시블랙
    ※교차구매 가능

    2+1
    롯데 빈츠/롯데 빈츠 롯데타워 (각 204g) 각4,780원
    ※교차구매 가능

    최대5,920원 할인
    햇님마을 영양농산 태양초 고춧가루 (250g/1kg) 12,900/39,800원

    L.POINT 8,000원
    CJ 스팸 클래식 라이트 기획(200g×3+200g) 11,980원

    L.POINT 6,000원
    종근당건강 락토핏 생유산균 골드(2g×50포) 9,900원

    2개 이상 50% -> 롯데/BC/신한/NH농협/삼성카드 2,000원 추가할인
    크리넥스 수프림소프트(27m×30롤) 해당카드로 2개 이상 구매시 1개당 각17,700원
    ※페이지 하단 카드할인 세부내용 참고
    ※1개 구매시 39,400원

    2+1
    롯데 더쌀로 사르르 핫쉬림프맛(100g) 2,720원

    테팔 행사상품 5만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 '㉫'를 확인하세요 ※결제시 계산대에서 수령
    테팔 스타트이지 프라이팬/뉴 쉐프터치 프라이팬/인텐시움 통3중 냄비/티타늄 블라썸 냄비 (상품별 상이) 40% 할인

    최대 40% -> 롯데/BC/신한/NH농협/삼성카드 추가 10%
    포트메리온 보타닉가든(상품별 상이)
    ※페이지 하단 카드할인 세부내용 참고

    2개 이상 40%
    크린랩 크린장갑(200매) 2개 이상 구매시 1개당 각5,454원
    ※1개 구매시 9,090원

    4입 증량 20% 중량업
    해태 맛동산 20% 중량업(288g)/해태 오예스 24입 기획(720g) 3,480/7,880원

    1+1 기획팩
    3M 항균 삼중양면 고운/다목적 수세미(각 2입) 각3,490원

    각2,000원 할인
    산리오 윈터 슈즈 모음전 각15,800원
    ※점별 입점 상품 상이 ※행사 대상 상품에 한함

    50%
    패스트 뉴블랙트럭캐리어 (3세 이상/62.5×12×20.5cm) 19,950원

    5캔 구매시 캔 당 1,990원
    세계맥주 골라담기 9,950원
    ※1캔 구매시 각3,000원

    ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
    ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
    990원 땡전딜!

    하림/참프레 닭다리살(정육) (각 100g/냉장/국내산) 990원
    ※제주점 제외

    요리하다 사골곰탕(500g) 990원

    오늘좋은 백미밥(210g) 990원

    파워에이드 外 4종(상품별 용량 상이) 각 990원
    ※파워에이드/토레타/토레타 제로/환타 오렌지/환타 파인제로

    오늘좋은 웨이퍼롤 3종 각 990원
    (각 100g) ※바닐라/초코/카푸치노
    
    코코토토 매일매일 깨끗한 물티슈(100매) 990원

    최대510원 할인
    벨버드 황태오리버거/더주오 통살 닭가슴살(상품별 용량 상이) 각 990원
    ※입점점에 한함 

    최대1,510원 할인
    룸바이홈 다용도/손잡이 바스켓, 신발정리대 12종 각 990원
    ※점별 재고 상이 
    ※한정수량으로 조기 품절될 수 있습니다

    4,990원 땡전딜!
    강원 감자(2kg/국산) 4,990원

    2개 이상 각4,000원 할인
    고구마말랭이 外 3종 2개 이상 구매시 1개당 각4,990원
    (상품별 상이/원산지 별도표기)
    ※1개 구매시 각8,990원 ※교차구매 가능

    남양 맛있는 우유 GT 기획(900ml×2) 890원 할인 4,990원

    샤워메이트 바디워시 5종 (상품별 용량 상이) 각4,990원
    ※점별 운영상품 상이

    데일리콤마 로더 디퓨저 4종(각 200ml) 4,910원 할인 각4,990원

    아머올 에탄올 워셔액 (1.8L×2개입) 4,990원

    오늘좋은 데일리 테이프클리너 리필(대형/6P) 1,910원 할인 4,990원

    맥스 폭발방지 CRV 부탄가스(4P) 1,800원 할인 4,990원

    1+1 기획팩
    유니랩 위생장갑 기획팩 4,990원

    9,990원 땡전딜!
    바베큐 오징어 기획 (300g/원산지 별도표기) 9,990원

    L.POINT 50%
    직소싱 번들 새우살 (각 250g×2/냉동/원산지 별도표기) 각9,990원

    3+1 골라담기
    반찬 3+1팩 골라담기 (4팩/원산지 별도표기)
    ※ 비움/미찬/도시곳간/무명반찬/푸드앤찬/초이스쿡 입점점에 한함
    ※정상가 4,000원 이하 상품 한정

    CJ 쿡킷/비비고 밀키트 3종/청정원 호밍스 밀키트 3종(상품별 상이) 각9,990원
    ※ 곱창전골, 곱창순대전골, 불쭈꾸미볶음/김치어묵우동전골, 우삽겹 스키야키, 춘천식 닭갈비

    고려은단 비타민C 2종 (상품별 상이) 각9,990원
    ※C1000/이지비타민

    3캔 구매시
    하이볼 골라담기(상품별 용량 상이) 9,990원
    ※퐁당레몬/라임, 생레몬/라임/청귤 外 5종
    ※1캔 구매시 각 4,000원

    에너자이저 맥스 10입 (5입 보너스팩) 각9,990원
    ※AA/AAA

    엘라스틴 프로폴리테라 7종(상품별 용량 상이) 각9,990원
    ※점별 운영상품 상이

    나무야나무야 깨끗한 무향 3겹 화장지(27m×30롤) 9,990원

    오늘좋은 퍼 방한 슬리퍼 5,810원 할인 9,990원 
    ※입점점에 한함 ※점별 재고 상이

    ※정상가란? 2024년 10월 17일(목) ~ 11월 6일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 10월 10일(목) ~ 10월 30일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

    ※전단적용기간 : 2024. 11. 7(목) ~ 11. 13(수)

  </div>
  `,
  `
  <div>
    축산
    마블나인 한우 전품목 (1++(9)등급/각 100g/냉장/국내산 한우고기) L.POINT 최대 50%
    ※조기 품절될 수 있습니다

    "김장시즌 김치와의 환상궁합" 브랜드 돼지고기 삼겹살/앞다리 수육용 각3,690/1,990원
    (각 100g/냉장/국내산 돼지고기)
    ※제주점 제외 ※입점점포에 한함 ※MAP 규격팩 제외

    미국산 갈비살/아롱사태(도매용) (각 100g/냉장/미국산) 2,490/1,890원
    L.POINT 각 40%

    L.POINT 각 10,000원
    미국산/호주산 LA갈비 (각 1kg, 냉동, 미국산/호주산) 각39,900원
    ※조기 품절될 수 있습니다

    수산
    L.POINT 3,000원
    김장용 햇추젓 (1kg/냉장/국산) 13,900원

    L.POINT 3,000원
    대천 전통 재래 도시락김 (4g×30봉/원산지 별도표기) 8,990원

    L.POINT 30%
    프리미엄 시그니처 장어초밥 (8입/냉장/원산지 별도표기) 8,960원
    ※입점점에 한함

    L.POINT 25%
    완도 활 전복 (대/마리/냉장/국산) 1,987원

    L.POINT 35%
    서해안 꽃게(100g/냉장/국산) 1,293원
    ※산지상황에 따라 품절 될 수 있습니다

    채소
    농협할인 20%
    배추(통/국산) 3,488원

    농협할인 20%
    안동 햇 생강(400g/봉/국산) 3,560원

    GAP친환경느타리버섯 (500g/팩/국산) 1,990원

    피그인더가든 데일리믹스 샐러드 (315g/팩/원산지 별도표기) 3,990원

    과일
    L.POINT 5,000원
    상생배(3kg/박스/국산) 10,990원
    ※조기 품절될 수 있습니다

    레드키위 (9~13입/팩/국산) 9,990원

    L.POINT 3,000원
    스위텔토마토(800g/팩/국산) 9,990원

    10개 구매시
    단감(개/국산) 9,900원
    ※1개 구매시 1,290원

    잡곡/계란
    경기미(20kg/국산) 49,900원

    2개 이상 50%
    파로 혼합곡 (각 1.5kg/원산지 별도표기) 2개 이상 구매시 1개당 각4,995원
    ※1개 구매시 9,990원

    동물복지인증 유정란(대란/25구/국산) 9,990원

    2봉 이상 50%
    풀무원/CJ 유기농 콩나물 (각 270g/원산지 별도표기) 2봉 이상 구매시 1봉당 각1,045원
    ※1봉 구매시 각2,090원
    ※동일 브랜드에 한함

    간편식 요리
    L.POINT 40%
    ALL NEW 새우초밥(20입/1팩/원산지 별도표기) 11,994원

    김밥&롤 닭강정/유부초밥 세트 (1팩/원산지 별도표기) 각7,990원

    L.POINT 40%
    5무 훈제오리 (1팩/원산지 별도표기) 9,594원

    추억의 깨찰빵(8입/1팩/원산지 별도표기) 5,500원 할인 7,500원
    ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함

    ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
    ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
    냉장/냉동 식품
    2개 이상 각1,000원 할인
    CJ 고메 소바바 치킨 5종 (상품별 상이) 2개 이상 구매시 1개당 각7,980원
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각8,980원

    CJ 비비고 갈비왕교자 (420g×2) 9,480원
    ※운영점에 한함

    동원 통그릴비엔나 (1kg) 10,980원

    목우촌 주부9단 프랑크소세지(500g) 5,480원

    유제품/음료 
    1+1
    과채주스 대용량 단품 전품목(1.2L~1.8L) 각3,280~7,980원
    ※동일시리즈간 교차구매 가능 ※점별 운영상품 상이

    1+1
    두유 16입 전품목(상품별 상이) 각14,450~20,000원
    ※동일시리즈간 교차구매 가능

    롯데마트 단독
    풀무원 다논 액티비아 플레인(80g×16입) 6,980원

    롯데마트 단독
    매일유업 상하 마일드 체다 슬라이스 치즈(17g×50입) 9,980원

    주류
    크러시 꼬깔콘 기획팩 (470ml×8) 각12,450원

    2캔 구매시
    카발란 Dry 하이볼(320ml) 8,980원
    ※기간 : 11/1(금)~11/13(수)
    ※1캔 구매시 각4,900원

    ONLY 롯데마트
    요리하다 단팥/슈크림 붕어빵(각 400g) 2,000원 할인 4,990원

    요리하다 쫄깃한 찹쌀 탕수육(550g) 3,000원 할인 4,990원

    500g ×2개입 기획
    요리하다 찰통순대 기획(500g×2) 5,990원

    요리하다 새콤바삭 유린기(480g) 3,000원 할인 4,990원

    롯데웰푸드
    수능 행사상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 ' A 를' 확인하세요 ※결제시 계산대에서 수령

    1+1
    롯데 키세스 초콜릿 4종(각 146g) 각5,280원
    ※밀크/아몬드/다크/쿠키앤크림 ※교차구매 가능

    1+1
    롯데 허쉬 초콜릿 스낵사이즈 3종 각5,520원
    (각 165g) ※밀크/아몬드/쿠키앤크림 ※교차구매 가능

    롯데 길리안 시쉘 초콜릿 2종(오리지날 250g/다크 225g) 1,220원 할인 17,980원

    롯데 명가 찰떡파이 (350g) 300원 할인 5,280원

    롯데웰푸드
    빼빼로데이 행사상품 2만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 ' P 를' 확인하세요 ※결제시 계산대에서 수령

    롯데 빼빼로 4종(상품별 규격 상이) 각4,780원
    ※초코/아몬드/크런키/화이트쿠키

    롯데빼빼로 8갑 기획 4종(상품별 규격 상이) 각11,520원
    ※초코/아몬드/크런키/화이트쿠키

    롯데 빼빼로 케이크박스 기획 (380g) 14,400원
    ※점별 운영상품 상이    

    롯데 빼빼로 슬라이딩 기획 (150g) 5,760원
    ※점별 운영상품 상이

    ※정상가란? 2024년 10월 17일(목) ~ 11월 6일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 10월 10일(목) ~ 10월 30일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다 
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

    ※전단적용기간 : 2024. 11. 7(목) ~ 11. 13(수)

  </div>
  `,
  `
  <div>
    브레프 행사상품 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ♬ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    브레프 전품목(상품별 상이) 2개 이상 구매시 1개당 각2,250~4,450원
    ※1개 구매시 각4,500~8,900원 ※교차구매 가능

    피죤 행사상품 2만 5천원 이상 구매시
    5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ▣ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    고농축 피죤 시그니처 리필 3종 2개 이상 구매시 1개당 각7,450원
    (각 2.3L) ※미스틱레인/플라워페스티벌/라피에스타
    ※1개 구매시 각14,900원 ※교차구매 가능

    크리넥스 물티슈 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉪ "를 확인하세요
    ※결제시 계산대에서 수령

    2개 이상 50%
    크리넥스 물티슈 8종(상품별 상이) 2개 이상 구매시 1개당 각2,145~7,950원
    ※마이비데 클린케어 휴대용/엑스라지 外
    ※1개 구매시 각4,290~15,900원 ※교차구매 가능

    피지오겔/AHC 해당상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수) ※브랜드별 합산불가
    ※각 브랜드별 3만원 이상 구매시 해당
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 : 피지오겔 " ㉲ "/ AHC " ㉳ "를 확인하세요
    ※결제시 계산대에서 수령

    피지오겔 DMT크림/AHC 콜라겐 크림(상품별 상이) 26,910/25,900원

    아모레퍼시픽 행사상품 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉵ "를 확인하세요
    ※결제시 계산대에서 수령

    1+1
    메디안 잇몸과학 치약(각 120g×3입) 각12,980원
    ※스트롱민트/클린민트 ※교차구매 가능 ※점별 운영상품 상이

    1+1
    다우니 세탁세제 리필 실내건조/헹굼제로(각 3.2L) 각36,900원
    ※교차구매 가능

    2개 이상 50%
    2개 이상 구매시 1개당 6,050원
    크리넥스 홈냅킨 퓨어화이트(130매×6입)
    ※1개 구매시 12,100원
    크리넥스 키친타월 2만 5천원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉸"를 확인하세요 ※결제시 계산대에서 수령

    롯데/BC/신한 NH농협/삼성카드 2개 이상 55%
    리엔 보윤 샴푸/컨디셔너(각 1,000ml) 해당카드로 2개 이상 구매시 1개당 각7,155원
    ※1개 구매시 각15,900원
    ※교차구매 가능 ※점별 운영상품 상이
    ※페이지 하단 카드할인 세부내용 참고

    롯데/BC/신한/NH농협/삼성카드 2개 이상 60%
    히말라야 핑크솔트 바디워시 3종 (각 900g) 해당카드로 2개 이상 구매시 1개당 각5,960원
    ※로즈/리프레싱민트/포레스트민트
    ※1개 구매시 각14,900원 ※교차구매 가능
    ※페이지 하단 카드할인 세부내용 참고

    2개 이상 20%
    핸드&풋 크림 모음전(상품별 상이) 2개 이상 구매시 1개당 각1,520~12,720원
    ※1개 구매시 각1,900~15,900원 ※교차구매 가능
    ※점별 운영상품 상이

    L.POINT 5,000원
    아임비타 이뮨플러스(7입) 11,900원

    쿡셀 전품목 5만원 이상 구매시 10,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/27(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ◆ "를 확인하세요
    ※결제시 계산대에서 수령
    쿡셀 전품목(상품별 상이) 20% 31,120/50,240원

    3M 청소/Bath 전품목 3만원 이상 구매시 5,000원 롯데상품권 증정
    불스원 전품목 3만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "M"를 확인하세요
    ※결제시 계산대에서 수령
    3M 베이킹소다 톡톡 크린스틱 시트 타입(소)/대형 테이프 클리너 팩(상품별 상이) 20% 10,800/15,390원
    ※입점점에 한함 ※해당 상품에 한함 ※점별 재고 상이

    대림바스 전품목 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "D"를 확인하세요
    ※결제시 계산대에서 수령
    대림바스 스마트 초슬림 무소음 하드 변기커버 D형(39.3×48cm) 15% 22,010원
    ※입점점에 한함 ※해당 상품에 한함 ※점별 재고 상이

    불스원 전품목 3만 5천원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉥ "를 확인하세요
    ※결제시 계산대에서 수령
    불스원 레인OK 발수 와이퍼 (350~650mm) 1+1 밴딩 8,100~12,800원

    티피링크 전품목 5만원 이상 구매시 10,000원 롯데상품권 증정
    ※기간 : 10/31(목)~11/13(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉠ "를 확인하세요
    ※결제시 계산대에서 수령
    티피링트 tapo 전품목(상품별 상이) 12,900~79,000원

    보디가드 남성/여성 기모내의 세트(상품별 상이) 각9,900원

    비비안 웜업 터틀넥 (상품별 상이) 각9,900원

    데일리 남성/여성 단목, 중목양말(각 8매) 각9,900원

    개이득 냥이득!
    ※ 기간 : 10/31(목) ~11/13(수)
    colioli 득템위크
    반려동물식품&용품 480여종 할인!
    반려동물식품/용품 3만원 이상 구매시 5천원 할인쿠폰 증정!
    ※해당카드 : 롯데/BC/신한/NH농협/삼성카드
    ※영수증 內 할인쿠폰으로 발급 ※12월 한달 간 사용가능
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " @ "를 확인하세요 ※입점점에 한함
    ※L.POINT회원에 한함 ※페이지 하단 카드할인 세부내용 참고

    롯데/BC/신한/NH농협/삼성카드 30%
    콜리올리 웰밸런스 사료 4종 (상품별 상이) 9,730~14,630원
    ※점별 재고 상이
    ※퍼피/성견/중형견/대형견

    콜리올리 순살가득 시리즈 4종 (각 300g) ※점별 재고 상이 각8,330원
    롯데/BC/신한/NH농협/삼성카드 30%

    콜리올리 깔끔하게 사용하는 1회용 패드 3종(M 100매/M 200매/L 40매) 8,330~16,730원
    ※입점점에 한함
    롯데/BC/신한/NH농협/삼성카드 30%

    L.POINT 10% -> 롯데/BC/신한/NH농협/삼성카드 10% 추가할인
    이나바 챠오츄르 버라이어티 (20P/40P) 12,069/21,465원
    ※입점점에 한함

    롯데/BC/신한/NH농협/삼성카드 30%
    월간스틱/월간통살 고양이 간식(30P) 각7,630원
    ※입점점에 한함

    롯데/BC/신한/NH농협/삼성카드 30%
    콜리올리 냄새잡는 벤토나이트 모래(무향 6.5kg) 9,730원
    ※입점점에 한함

    toysrus
    제프리 생일파티에 초대합니다 
    인기 완구류 해당상품 최대 28,500원 할인!

    슈팅스타! 티니핑 피규어 출시!
    스타 하츄핑 피규어 外 10종 (3세 이상/상품별 상이) 각12,900~14,900원
    ※입점점에 한함 ※점별 재고 상이

    레고 깊고 어두운 전장 (21246) 28,500원 할인 66,400원
    더 많은 상품을 토이저러스몰에서 만나보세요! ▶

    ※정상가란? 2024년 10월 17일(목) ~ 11월 6일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 10월 10일(목) ~ 10월 30일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getAllStoreData = (type) => {
  const dataArray = [];
  allStoreData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/${type === "ZETTA" ? "jamsil_" : ""}${fillZero(
        2,
        String(i + 1)
      )}.jpg`,
      title: `${type === "ZETTA" ? "제타플렉스" : "롯데마트"} 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaData = () => {
  const dataArray = [];
  zettaData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/jamsil_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 잠실점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaSeoulData = () => {
  const dataArray = [];
  zettaSeoulData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/seoul_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 서울역점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const imgPath = "images/202411_2";
const category = [];

const data = {
  L201: {
    title: "스마트전단지-전점201",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L202: {
    title: "스마트전단지-전점202",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L203: {
    title: "스마트전단지-제타플렉스 잠실점203",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L204: {
    title: "스마트전단지-제타플렉스 잠실점204",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L205: {
    title: "스마트전단지-제타플렉스 서울역점205",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
  L206: {
    title: "스마트전단지-제타플렉스 서울역점206",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
};

export default data;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import smoothscroll from "smoothscroll-polyfill";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper";
import "swiper/css";

import ShareButton from "./ButtonShare";
import MartMallButton from "./ButtonMartMall";
import LauncherPopup from "./LauncherPopup";
import ButtonZoom from "./ButtonZoom";

function LeafletItemV2(props) {
  const { imageInfoList, leafletInfo, isMcouponApp, goMartMall } = props;
  const location = useLocation();
  const params = queryString.parse(location.search);

  const [imageActiveIndex, setImageActiveIndex] = useState(1);

  const [categorySwiper, setCategorySwiper] = useState(null);
  const [imageSwiper, setImageSwiper] = useState(null);

  // 이미지 2개씩 배열로 묶음
  const chunkImageList = [];
  const size = 2;

  for (let i = 0; i < imageInfoList.length; i += size) {
    chunkImageList.push(imageInfoList.slice(i, i + size));
  }

  //이미지 슬라이드 변경 감지
  const handleImageSlideChange = (swiper) => {
    const activeIndex = swiper.realIndex + 1;
    if (activeIndex === undefined) return;

    setImageActiveIndex(activeIndex);

    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, 100);
  };

  //공유 진입 시 특정 슬라이드 진입 체크
  useEffect(() => {
    if (params.tabIndex) {
      if (imageSwiper) {
        imageSwiper.slideTo(params.tabIndex);
      }
    }
  }, [imageSwiper, params.tabIndex]);

  useEffect(() => {
    //IOS scrollTo smooth 버그 예외처리
    smoothscroll.polyfill();
  }, []);

  return (
    <>
      {leafletInfo && (
        <div
          className="wrapper subCont v2212"
          style={{ touchAction: "pan-x pan-y" }} //IOS 확대 막기
        >
          <header className="leaflet_header">
            {/* 헤더 로고 */}
            <div className="headerCont2">
              {process.env.REACT_APP_TYPE === "DEV" && (
                <span
                  style={{
                    position: "absolute",
                    color: "lightgray",
                    left: "1rem",
                    top: "20%",
                    transform: "translateY(-20%)",
                  }}
                >
                  개발
                </span>
              )}
              <div className="logoTit"></div>
            </div>

            {/* 카테고리 */}
            <div className="leaflet_cate">
              <Swiper
                onSwiper={setCategorySwiper}
                slidesPerView={"auto"}
                watchSlidesProgress={true}
                modules={[Thumbs]}
                className="swiper_cate"
              >
                {leafletInfo.category.map((category, i) => (
                  <SwiperSlide key={i}>
                    <button type="button" className="btn_cate">
                      {category}
                    </button>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </header>

          {/* 이미지 스와이퍼 */}
          <div className="leaflet_content">
            <div className="leaflet_list">
              {chunkImageList.length > 0 && (
                <Swiper
                  loop
                  slidesPerView={"1"}
                  navigation={true}
                  autoHeight={true}
                  thumbs={{ swiper: categorySwiper }}
                  modules={[Navigation, Thumbs]}
                  className="swiper_leaflet"
                  onSwiper={(swiper) => setImageSwiper(swiper)}
                  onSlideChange={(swiper) => handleImageSlideChange(swiper)}
                  a11y={{
                    //웹 접근성
                    enabled: true,
                    prevSlideMessage: "이전 슬라이드",
                    nextSlideMessage: "다음 슬라이드",
                    slideLabelMessage:
                      "총 {{slidesLength}}장의 슬라이드 중 {{index}}번 슬라이드 입니다.",
                  }}
                >
                  {chunkImageList.map((chunkImage, idx) => {
                    return (
                      <SwiperSlide key={idx}>
                        {chunkImage.map((image, i) => {
                          return (
                            <div key={i} className="leaflet_item">
                              {/* 전단 이미지 */}
                              <img
                                src={`${leafletInfo.imgPath}${image.imgs}`}
                                alt="이건 읽어주나?"
                              />
                              {/* 장애인차별금지법 웹표준 퍼블리싱 figcaption */}
                              <div
                                className="alternate"
                                dangerouslySetInnerHTML={{
                                  __html: `${image.title} ${image.texts}`,
                                }}
                              />
                            </div>
                          );
                        })}
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
            </div>

            {/* 플로팅 버튼 영역 */}
            <div className="leaflet_btns v2304">
              {/* 확대보기 버튼 */}
              <ButtonZoom
                leafletInfo={leafletInfo}
                chunkImageList={chunkImageList}
                isMcouponApp={isMcouponApp}
              />

              {/* 공유하기 버튼 */}
              <ShareButton
                isMcouponApp={isMcouponApp}
                imageActiveIndex={imageActiveIndex}
                leafletInfo={leafletInfo}
              />

              {/* 롯데마트 몰 바로가기 */}
              {leafletInfo.isFooter && (
                <MartMallButton
                  goMartMall={goMartMall}
                  isMcouponApp={isMcouponApp}
                  leafletInfo={leafletInfo}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {/* APP 다운로드 팝업 */}
      <LauncherPopup />
    </>
  );
}

export default LeafletItemV2;

import { createGlobalStyle } from "styled-components";
import { font, thejamsilFont } from "./font";
import { IMAGE_PATH } from "../containers/LM/constants";

const GlobalStyles = createGlobalStyle`

/* font */
  ${font}
  /* layout */
  html,
  body {
    ${thejamsilFont("light")}
    height: 100%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  
  .txtBr {
    display: block;
  }
  
  .blind {
    color: transparent;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    line-height: 0;
  }
  
  .wrapper {
    overflow: hidden;
  }
  
  /* 팝업영역 */
  .bannerPopBox {
    /* page */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .bannerPopBox .bannerPop {
    /* swiper.min */
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
  }
  
  .bannerPopBox .bannerPop .banner {
    /* swiper.min */
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    /* page css */
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .bannerPopBox .bannerPop .banner a {
    display: block;
  }
  
  .bannerPopBox .bannerPop .banner .bannerImg {
    width: 92%;
    margin: 0 2%;
  }
  
  @keyframes btnClose {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes btnClose {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  .btnPop {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 999;
    margin-top: -55%;
    /* margin-left:35%; */
  }
  
  .btnPop.close button {
    width: 3.3125rem;
    height: 3.4375rem;
    cursor: pointer;
    background-color: transparent;
    background-image: url("${IMAGE_PATH}/common/btn_pop_close.png");
    background-size: 100%;
    animation-name: btnClose;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
  }
  
  /* 카테고리 상품페이지 */
  .subCont {
    overflow: auto;
    position: relative;
  }
  
  .subCont img {
    width: 100%;
    height: auto;
    font-size: 0.75rem;
    color: #666;
  }
  
  .subCont a {
    display: block;
    width: 100%;
  }
  
  .headerCont {
    height: 2.625rem;
    padding-top: 0.4375rem;
    background-color: #e50015;
  }
  
  .headerCont .logoTit {
    position: relative;
    background: url("${IMAGE_PATH}/common/logo_sub02.png")
      no-repeat;
    background-position: 50%;
    background-size: 6.125rem;
  }
  
  .headerCont .logoTit > :first-child {
    font-size: 1rem;
    opacity: 0;
  }
  
  .headerCont2 {
    height: auto;
    padding-top: 0.4375rem;
    background-color: #ffffff;
  }
  
  .headerCont2 .logoTit,
  .headerCont2 .logoTit-vic {
    position: relative;
    height: 2.3125rem;
    text-indent: -10000rem;
    background-repeat: no-repeat;
    background-position: 50%;
  }
  
  .headerCont2 .logoTit {
    background-image: url("${IMAGE_PATH}/common/top_ci.png");
    background-size: 6.125rem;
  }
  
  .headerCont2 .logoTit.logo_super {
    background-image: url("${IMAGE_PATH}/common/logo_super.png");
    background-size: 5.1875rem auto;
  }
  
  .headerCont2 .logoTit > :first-child {
    font-size: 1rem;
    opacity: 0;
  }
  
  .headerCont2 .logoTit-vic {
    background-image: url("${IMAGE_PATH}/common/logo_vic.png");
    background-size: 8.75rem;
  }
  
  .footer {
    padding: 0.9375rem 0.5rem 0.5rem;
    background-color: #ebebeb;
    text-align: center;
  }
  
  .footer > :first-child {
    width: 60%;
    margin: 0 auto 0.6875rem;
  }
  
  /* 대체텍스트 markup */
  .alternate {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    clip-path: polygon(0 0, 0 0, 0 0);
  }
  
  /* swiper css */
  .swiper-button-next {
    width: 2.6875rem;
    height: 2.6875rem;
    position: fixed;
    background-size: 100%;
    background-image: url("${IMAGE_PATH}/common/btn_next.png");
  }
  
  .swiper-button-prev {
    width: 2.6875rem;
    height: 2.6875rem;
    position: fixed;
    background-size: 100%;
    background-image: url("${IMAGE_PATH}/common/btn_prev.png");
  }
  
  /* 220719 버튼 위치 변경 */
  .btns {
    position: relative;
    height: 2.4781rem;
    background: #ebebeb;
  }
  .btns .swiper-button-next,
  .btns .swiper-button-prev {
    position: absolute;
    top: 0;
    width: 50px;
    height: 100%;
    margin-top: 0;
    background-repeat: no-repeat;
    background-size: 20px 17px;
  }
  .btns .swiper-button-next {
    right: 0.5rem;
    background-image: url("${IMAGE_PATH}/common/btn_next_2207.png");
    background-position: calc(100% - 10px) 50%;
  }
  .btns .swiper-button-prev {
    left: 0.5rem;
    background-image: url("${IMAGE_PATH}/common/btn_prev_2207.png");
    background-position: 10px 50%;
  }
  .btns .swiper-button-next.disabled {
    background-image: url("${IMAGE_PATH}/common/btn_next_disabled_2207.png");
  }
  .btns .swiper-button-prev.disabled {
    background-image: url("${IMAGE_PATH}/common/btn_prev_disabled_2207.png");
  }
  .footer {
    margin-top: -2.4781rem;
  }
  .footer > :first-child {
    font-size: 0;
  }
  .footer > :first-child img {
    position: relative;
    height: 0.8531rem;
    vertical-align: top;
  }
  
  /* 221221 스마트전단 개선 */
  .wrapper.v2212 button {
    background-color: transparent;
  }
  .leaflet_header {
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    border-bottom: 3px solid #f7f7f7;
    z-index: 10;
  }
  .leaflet_header .headerCont2 {
    border-bottom: 1px solid #e5e5e5;
  }
  .leaflet_header .headerCont2 .logoTit {
    background-image: url("${IMAGE_PATH}/common/logo_leaflet.png");
    background-size: auto 24px;
  }
  .leaflet_cate {
    width: 100%;
  }
  .leaflet_cate .swiper_cate {
    padding: 0 14px;
    border-bottom: 1px solid #d7d7d7;
  }
  .leaflet_cate .swiper_cate .swiper-wrapper {
    justify-content: space-between;
  }
  .leaflet_cate .swiper_cate .swiper-slide {
    position: relative;
    width: auto;
    width: calc(100% / 6);
    max-width: calc(100% / 6);
    height: 46px;
  }
  .leaflet_cate .swiper_cate .swiper-slide .btn_cate {
    width: 100%;
    height: 100%;
    color: #a5a5a5;
    font-size: 13px;
    background: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .leaflet_cate .swiper_cate .swiper-slide.swiper-slide-thumb-active:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: -2px;
    right: -2px;
    border-bottom: 3px solid #009d49;
  }
  .leaflet_cate .swiper_cate .swiper-slide.swiper-slide-thumb-active .btn_cate {
    font-family: "TheJamsil-3Regular";
    color: #009d49;
  }
  .leaflet_content {
    padding: calc(2.75rem + 65px) 0 25px;
  }
  .leaflet_content.type_nocate {
    padding: calc(2.75rem + 18px) 0 25px;
  }
  .swiper_leaflet .leaflet_item {
    width: 100vw;
  }
  .swiper_leaflet .swiper-button-prev,
  .swiper_leaflet .swiper-button-next {
    top: calc(100vh / 2);
    width: 35px;
    height: 70px;
    margin-top: -35px;
    background-repeat: no-repeat;
    background-size: 25px 50px;
  }
  .swiper_leaflet .swiper-button-prev {
    left: 0;
    background-image: url("${IMAGE_PATH}/common/btn_leaflet_prev.png");
    background-position: 0 50%;
  }
  .swiper_leaflet .swiper-button-next {
    right: 0;
    background-image: url("${IMAGE_PATH}/common/btn_leaflet_next.png");
    background-position: 100% 50%;
  }
  .leaflet_btns {
    position: fixed;
    right: 15px;
    bottom: 40px;
    padding: 2px;
    background: rgba(255, 255, 255, 0.37);
    border-radius: 30px;
    z-index: 10;
  }
  .leaflet_btns .btn_leaflet {
    display: block;
    width: 57px;
    height: 57px;
    font-size: 0;
    color: transparent;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% auto;
    border-radius: 50%;
  }
  .leaflet_btns .btn_leaflet_zoom {
    background-image: url("${IMAGE_PATH}/common/btn_leaflet_zoom_2304.png");
  }
  .leaflet_btns .btn_leaflet_share {
    background-image: url("${IMAGE_PATH}/common/btn_leaflet_share_2304.png");
  }
  .leaflet_btns .btn_leaflet_mall {
    background-image: url("${IMAGE_PATH}/common/btn_leaflet_mall_2304.png");
  }
  
  .zoom_leaflet {
    display: flex;
    flex-wrap: wrap;
  }
  .zoom_leaflet li {
    flex: 0 0 calc(100% / 3);
  }
  
  /* 221221 중개페이지 */
  .app_web_wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
  .app_web_wrap::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
  }
  .app_web_wrap .bg {
    height: 100%;
  }
  .app_web_wrap .bg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .app_web_wrap .content_box {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(calc(-50% + 0.5px));
    width: 100%;
    padding: 0 25px;
    text-align: center;
    box-sizing: border-box;
  }
  .app_web_wrap .tit_img img {
    width: 234px;
  }
  .app_web_wrap .btn_group {
    margin-top: 30px;
    text-align: center;
  }
  .app_web_wrap .btn_group button {
    display: block;
    width: 309px;
    max-width: 100%;
    margin: 0 auto;
    background: transparent;
    cursor: pointer;
  }
  .app_web_wrap .btn_group button + button {
    margin-top: 20px;
  }
  .app_web_wrap .btn_group .btn_mapp {
    height: 54px;
    font-size: 16px;
    color: #fff;
    background: #ff0000;
    border-radius: 25px;
  }
  .app_web_wrap .btn_group .btn_mapp span {
    font-family: "TheJamsil-4Medium";
  }
  .app_web_wrap .btn_group .btn_mweb span {
    display: inline-block;
    font-size: 14px;
    color: #fff;
    border-bottom: 1px solid #fff;
  }
  .app_web_wrap .logo_btm {
    width: 101px;
    margin: 45px auto 0;
  }
  
`;

export default GlobalStyles;

import React from "react";
import { gaEvent } from "../../../utils/ga";
import { LEAFLET_URL } from "../constants";

function ButtonMartMall(props) {
  const { goMartMall, leafletInfo } = props;

  const handleClickMartMall = () => {
    gaEvent(leafletInfo.title, `롯데마트 몰 바로가기(${leafletInfo.title})`);

    const url = LEAFLET_URL.main;
    goMartMall(url);
  };

  return (
    <button
      type="button"
      className="btn_leaflet btn_leaflet_mall"
      onClick={handleClickMartMall}
    >
      롯데마트몰 바로가기
    </button>
  );
}

export default ButtonMartMall;

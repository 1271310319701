import React, { useState, useRef } from "react";
import "../styles/list.css";

function List() {
  const searchInput = useRef();
  const [leafletNum, setLeafletNum] = useState("");

  const moveLeaflet = () => {
    window.location.href = `/${leafletNum}`;
  };

  return (
    <div className="list">
      <h1>롯데마트 스마트전단(개발)</h1>
      <div id="contents">
        <div>
          <h3>전단 번호 입력</h3>
          <div style={{ marginTop: "0.5rem" }}>
            <input
              type="text"
              ref={searchInput}
              value={leafletNum}
              onChange={(e) => setLeafletNum(e.currentTarget.value)}
              style={{
                border: "1px solid gray",
                padding: "5px",
              }}
            />
            <button
              onClick={moveLeaflet}
              style={{
                backgroundColor: "lightgray",
                padding: "5px",
                marginLeft: "5px",
              }}
            >
              이동
            </button>
          </div>
        </div>
        <h2>2024년 7월 2주차 (7/11)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>201 롯데마트</li>
          <li>203 제타플렉스 잠실점</li>
          <li>205 제타플렉스 서울역점</li>
          <li>209 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>202 롯데마트</li>
          <li>204 제타플렉스 잠실점</li>
          <li>206 제타플렉스 서울역점</li>
          <li>210 롯데슈퍼</li>
        </ul>
        <h2>2024년 7월 1주차 (7/4)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>101 롯데마트</li>
          <li>103 제타플렉스 잠실점</li>
          <li>105 제타플렉스 서울역점</li>
          <li>109 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>102 롯데마트</li>
          <li>104 제타플렉스 잠실점</li>
          <li>106 제타플렉스 서울역점</li>
          <li>110 롯데슈퍼</li>
        </ul>
        <h2>2024년 6월 2주차 (6/6)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>201 롯데마트</li>
          <li>203 제타플렉스 잠실점</li>
          <li>205 제타플렉스 서울역점</li>
          <li>209 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>202 롯데마트</li>
          <li>204 제타플렉스 잠실점</li>
          <li>206 제타플렉스 서울역점</li>
          <li>210 롯데슈퍼</li>
        </ul>
        <h2>2024년 5월 5주차 (5/30)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>501 롯데마트</li>
          <li>503 제타플렉스 잠실점</li>
          <li>505 제타플렉스 서울역점</li>
          <li>509 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>502 롯데마트</li>
          <li>504 제타플렉스 잠실점</li>
          <li>506 제타플렉스 서울역점</li>
          <li>510 롯데슈퍼</li>
        </ul>
        <h2>2024년 5월 4주차 (5/23)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>401 롯데마트</li>
          <li>403 제타플렉스 잠실점</li>
          <li>405 제타플렉스 서울역점</li>
          <li>409 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>402 롯데마트</li>
          <li>404 제타플렉스 잠실점</li>
          <li>406 제타플렉스 서울역점</li>
          <li>410 롯데슈퍼</li>
        </ul>
        <h2>2024년 5월 3주차 (5/15)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>301 롯데마트</li>
          <li>303 제타플렉스 잠실점</li>
          <li>305 제타플렉스 서울역점</li>
          <li>309 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>302 롯데마트</li>
          <li>304 제타플렉스 잠실점</li>
          <li>306 제타플렉스 서울역점</li>
          <li>310 롯데슈퍼</li>
        </ul>
        <h2>2024년 5월 2주차 (5/9)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>201 롯데마트</li>
          <li>203 제타플렉스 잠실점</li>
          <li>205 제타플렉스 서울역점</li>
          <li>209 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>202 롯데마트</li>
          <li>204 제타플렉스 잠실점</li>
          <li>206 제타플렉스 서울역점</li>
          <li>210 롯데슈퍼</li>
        </ul>
        <h2>2024년 5월 1주차 (5/8)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>101 롯데마트</li>
          <li>103 제타플렉스 잠실점</li>
          <li>105 제타플렉스 서울역점</li>
          <li>111 수원점 단독</li>
          <li>701 빅마켓</li>
          <li>718 맥스</li>
          <li>109 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>102 롯데마트</li>
          <li>104 제타플렉스 잠실점</li>
          <li>106 제타플렉스 서울역점</li>
          <li>112 수원점 단독</li>
          <li>751 빅마켓</li>
          <li>719 맥스</li>
          <li>110 롯데슈퍼</li>
        </ul>
        <h2>2024년 4월 4주차 (4/11)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>401 롯데마트</li>
          <li>403 제타플렉스 잠실점</li>
          <li>405 제타플렉스 서울역점</li>
          <li>331 수원점 단독</li>
          <li>703 빅마켓</li>
          <li>738 맥스</li>
          <li>409 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>402 롯데마트</li>
          <li>404 제타플렉스 잠실점</li>
          <li>406 제타플렉스 서울역점</li>
          <li>332 수원점 단독</li>
          <li>753 빅마켓</li>
          <li>739 맥스</li>
          <li>410 롯데슈퍼</li>
        </ul>
        <h2>2024년 4월 3주차 (4/11)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>301 롯데마트</li>
          <li>303 제타플렉스 잠실점</li>
          <li>305 제타플렉스 서울역점</li>
          <li>703 빅마켓</li>
          <li>738 맥스</li>
          <li>309 롯데슈퍼</li>
          <li>730 창립 2탄 지류전단</li>
          <li>733 창립 2탄 지류전단 잠실점</li>
          <li>735 창립 2탄 지류전단 서울역점</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>302 롯데마트</li>
          <li>304 제타플렉스 잠실점</li>
          <li>306 제타플렉스 서울역점</li>
          <li>753 빅마켓</li>
          <li>739 맥스</li>
          <li>310 롯데슈퍼</li>
          <li>732 창립 2탄 지류전단</li>
          <li>734 창립 2탄 지류전단 잠실점</li>
          <li>736 창립 2탄 지류전단 서울역점</li>
        </ul>
        <h2>2024년 4월 2주차 (4/11)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>201 롯데마트</li>
          <li>203 제타플렉스 잠실점</li>
          <li>205 제타플렉스 서울역점</li>
          <li>704 빅마켓</li>
          <li>748 맥스</li>
          <li>209 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>202 롯데마트</li>
          <li>204 제타플렉스 잠실점</li>
          <li>206 제타플렉스 서울역점</li>
          <li>754 빅마켓</li>
          <li>749 맥스</li>
          <li>210 롯데슈퍼</li>
        </ul>
        <h2>2024년 4월 1주차 (4/4)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>101 롯데마트</li>
          <li>103 제타플렉스 잠실점</li>
          <li>105 제타플렉스 서울역점</li>
          <li>704 빅마켓</li>
          <li>748 맥스</li>
          <li>109 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>102 롯데마트</li>
          <li>104 제타플렉스 잠실점</li>
          <li>106 제타플렉스 서울역점</li>
          <li>754 빅마켓</li>
          <li>749 맥스</li>
          <li>110 롯데슈퍼</li>
        </ul>

        <h2>2024년 3월 4주차 (3/28)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>401 롯데마트</li>
          <li>403 제타플렉스 잠실점</li>
          <li>405 제타플렉스 서울역점</li>
          <li>704 빅마켓</li>
          <li>748 맥스</li>
          <li>710 창립1탄 지류전단</li>
          <li>409 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>402 롯데마트</li>
          <li>404 제타플렉스 잠실점</li>
          <li>406 제타플렉스 서울역점</li>
          <li>754 빅마켓</li>
          <li>749 맥스</li>
          <li>711 창립1탄 지류전단</li>
          <li>410 롯데슈퍼</li>
        </ul>
        <h2>2024년 3월 3주차 (3/21)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>301 롯데마트</li>
          <li>303 제타플렉스 잠실점</li>
          <li>305 제타플렉스 서울역점</li>
          <li>321 은평점</li>
          <li>309 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>302 롯데마트</li>
          <li>304 제타플렉스 잠실점</li>
          <li>306 제타플렉스 서울역점</li>
          <li>322 은평점</li>
        </ul>
        <h2>2024년 3월 2주차 (3/14)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>201 롯데마트</li>
          <li>203 제타플렉스 잠실점</li>
          <li>205 제타플렉스 서울역점</li>
          <li>702 빅마켓</li>
          <li>728 맥스</li>
          <li>209 롯데슈퍼</li>
          <li>210 롯데슈퍼 복현점</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>202 롯데마트</li>
          <li>204 제타플렉스 잠실점</li>
          <li>206 제타플렉스 서울역점</li>
          <li>752 빅마켓</li>
          <li>729 맥스</li>
        </ul>
        <h2>2024년 3월 1주차 (3/7)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>101 롯데마트</li>
          <li>103 제타플렉스 잠실점</li>
          <li>105 제타플렉스 서울역점</li>
          <li>109 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>102 롯데마트</li>
          <li>104 제타플렉스 잠실점</li>
          <li>106 제타플렉스 서울역점</li>
        </ul>
        <h2>2024년 2월 5주차 (2/29)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>501 롯데마트</li>
          <li>503 제타플렉스 잠실점</li>
          <li>505 제타플렉스 서울역점</li>
          <li>705 빅마켓</li>
          <li>758 맥스</li>
          <li>509 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>502 롯데마트</li>
          <li>504 제타플렉스 잠실점</li>
          <li>506 제타플렉스 서울역점</li>
          <li>755 빅마켓</li>
          <li>759 맥스</li>
        </ul>
        <h2>2024년 2월 4주차 (2/22)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>401 롯데마트</li>
          <li>403 제타플렉스 잠실점</li>
          <li>405 제타플렉스 서울역점</li>
          <li>409 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>402 롯데마트</li>
          <li>404 제타플렉스 잠실점</li>
          <li>406 제타플렉스 서울역점</li>
        </ul>
        <h2>2024년 2월 3주차 (2/15)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>301 롯데마트</li>
          <li>303 제타플렉스 잠실점</li>
          <li>305 제타플렉스 서울역점</li>
          <li>703 빅마켓</li>
          <li>738 맥스</li>
          <li>309 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>302 롯데마트</li>
          <li>304 제타플렉스 잠실점</li>
          <li>306 제타플렉스 서울역점</li>
          <li>753 빅마켓</li>
          <li>739 맥스</li>
        </ul>
        <h2>2024년 2월 2주차 (2/8)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>201 롯데마트</li>
          <li>203 제타플렉스 잠실점</li>
          <li>205 제타플렉스 서울역점</li>
          <li>208 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>202 롯데마트</li>
          <li>204 제타플렉스 잠실점</li>
          <li>206 제타플렉스 서울역점</li>
        </ul>
        <h2>2024년 2월 1주차 (2/1)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>101 롯데마트</li>
          <li>103 제타플렉스 잠실점</li>
          <li>105 제타플렉스 서울역점</li>
          <li>701 빅마켓</li>
          <li>718 맥스</li>
          <li>108 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>102 롯데마트</li>
          <li>104 제타플렉스 잠실점</li>
          <li>106 제타플렉스 서울역점</li>
          <li>751 빅마켓</li>
          <li>719 맥스</li>
        </ul>
        <h2>2024년 1월 4주차 (1/25)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>401 롯데마트</li>
          <li>403 제타플렉스 잠실점</li>
          <li>405 제타플렉스 서울역점</li>
          <li>408 롯데슈퍼</li>
          <li>913 전점 설 본세트</li>
          <li>795 빅마켓 설 본세트</li>
          <li>798 맥스 설 본세트</li>
          <li>915 슈퍼 설 본세트</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>402 롯데마트</li>
          <li>404 제타플렉스 잠실점</li>
          <li>406 제타플렉스 서울역점</li>
          <li>914 전점 설 본세트</li>
          <li>796 빅마켓 설 본세트</li>
          <li>799 맥스 설 본세트</li>
          <li>916 슈퍼 설 본세트</li>
        </ul>
        <h2>2024년 1월 3주차 (1/18)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>301 롯데마트</li>
          <li>303 제타플렉스 잠실점</li>
          <li>305 제타플렉스 서울역점</li>
          <li>310 그랑그로서리은평</li>
          <li>703 빅마켓</li>
          <li>738 맥스</li>
          <li>308 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>302 롯데마트</li>
          <li>304 제타플렉스 잠실점</li>
          <li>306 제타플렉스 서울역점</li>
          <li>311 그랑그로서리은평</li>
          <li>753 빅마켓</li>
          <li>739 맥스</li>
        </ul>
        <h2>2024년 1월 2주차 (1/11)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>201 롯데마트</li>
          <li>203 제타플렉스 잠실점</li>
          <li>205 제타플렉스 서울역점</li>
          <li>208 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>202 롯데마트</li>
          <li>204 제타플렉스 잠실점</li>
          <li>206 제타플렉스 서울역점</li>
        </ul>
        <h2>2024년 1월 1주차 (1/4)</h2>
        <h3>롯데마트 GO</h3>
        <ul>
          <li>101 롯데마트</li>
          <li>103 제타플렉스 잠실점</li>
          <li>105 제타플렉스 서울역점</li>
          <li>110 그랑그로서리은평</li>
          <li>701 빅마켓</li>
          <li>718 맥스</li>
          <li>108 롯데슈퍼</li>
        </ul>
        <h3>LMS</h3>
        <ul>
          <li>102 롯데마트</li>
          <li>104 제타플렉스 잠실점</li>
          <li>106 제타플렉스 서울역점</li>
          <li>111 그랑그로서리은평</li>
          <li>751 빅마켓</li>
          <li>719 맥스</li>
        </ul>
      </div>
    </div>
  );
}

export default List;

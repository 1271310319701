import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getUA, isAndroid, isIOS } from "react-device-detect";
import { gaEvent, gaPageView } from "../../../utils/ga";
import { IMAGE_PATH } from "../constants";

// 전단 지점별 QR 스캔 시 중계페이지
function LauncherPopup() {
  const location = useLocation();
  const isMcouponApp = navigator.userAgent.indexOf("_mcoupon") > -1;
  const leafletNumber = location.pathname.split("/")[1] || "101"; //현재 전단 번호
  const pageName = `APP 다운로드 팝업(${leafletNumber})`;

  const [closeDimPopup, setCloseDimPopup] = useState(true);

  //앱으로 열기 클릭
  const handleClickOpenApp = () => {
    gaEvent(pageName, `APP으로 보기(${leafletNumber})`);

    const url = `${process.env.REACT_APP_GO_URL}/?_route=/leaflet?leafletNum=${leafletNumber}&_from=J`;

    if (!isMcouponApp) {
      try {
        //앱 열기
        if (isIOS) {
          window.location.href =
            getUA.indexOf("mcoupon") > -1
              ? url
              : "lottemart-mcoupon-iphone://?url=" + url;
        } else if (isAndroid) {
          var browser = getUA.toLowerCase();

          if (
            parseFloat(browser.substring(browser.indexOf("android") + 8, 3)) >=
            4.4
          ) {
            window.location.href =
              "intent://m.coupon.lottemart?url=" +
              url +
              "#Intent;scheme=lmscp;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.lottemart.lmscp;end";
          } else {
            window.location.href = "lmscp://m.coupon.lottemart?url=" + url;
            setTimeout(function () {
              window.location.href = "market://details?id=com.lottemart.lmscp";
            }, 300);
          }
        }
      } catch (e) {
        //오류 시 스토어
        if (isIOS) {
          window.location.href = "https://itunes.apple.com/app/id987435592";
        } else {
          window.location.href = "market://details?id=com.lottemart.lmscp";
        }
      }
    }
  };

  //모바일로 보기
  const handleClickMobileWeb = () => {
    gaEvent(pageName, `모바일로 보기(${leafletNumber})`);

    setCloseDimPopup(true);
  };

  //웹뷰 체크
  useEffect(() => {
    if (isMcouponApp) {
      setCloseDimPopup(true);
    } else {
      //모바일 웹인 경우 앱/웹 버튼 노출
      setCloseDimPopup(false);
    }
  }, [isMcouponApp]);

  useEffect(() => {
    if (!leafletNumber) return;
    gaPageView(`APP 다운로드 팝업(${leafletNumber})`, location.pathname);
  }, [location.pathname, leafletNumber]);

  return (
    <>
      <div
        className="app_web_wrap"
        style={{
          display: closeDimPopup ? "none" : "block",
          touchAction: "pan-x pan-y", //IOS 확대 막기
        }}
      >
        <div className="bg">
          <img src={`${IMAGE_PATH}/common/bg_leaflet_martgo.jpg`} alt="" />
        </div>
        <div className="content_box">
          <div className="tit_img">
            <img
              src={`${IMAGE_PATH}/common/tit_reearth.png`}
              alt="지구를 위해 함께 하세요! 매년 30년생 나무 7,000그루를 지킬 수 있습니다. 롯데마트는 보다 건강한 내일의 지구를 위하여 1회용 종이전단지 운영을 중단하며, 모바일 전단으로 매주 새로운 소식을 전하겠습니다."
            />
          </div>
          <div className="btn_group">
            <button
              type="button"
              className="btn_mapp"
              onClick={handleClickOpenApp}
            >
              <span>롯데마트GO APP</span>으로 보기
            </button>
            <button
              type="button"
              className="btn_mweb"
              onClick={handleClickMobileWeb}
            >
              <span>괜찮습니다. 모바일웹으로 볼께요.</span>
            </button>
          </div>
          <div className="logo_btm">
            <img
              src={`${IMAGE_PATH}/common/logo_leaflet_btm.png`}
              alt="RE:EARTH 다시, 지구를 새롭게"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default LauncherPopup;

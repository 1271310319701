import React from "react";
import { gaEvent } from "../../../utils/ga";
import { openLeaflet, setKeyValue } from "../../../utils/utility";

function ButtonZoom(props) {
  const { chunkImageList, leafletInfo, isMcouponApp } = props;

  // 전체보기 클릭
  const handleClickZoom = () => {
    gaEvent(leafletInfo.title, `전체보기(${leafletInfo.title})`);

    // JSON.parse 할 때 texts 오류. 불필요 정보 삭제
    const _chunkImageList = chunkImageList.map((chunkImage) =>
      chunkImage.map((image) => {
        const { texts, href, ...otherImage } = image;
        return otherImage;
      })
    );

    // 전단 정보/이미지 저장
    if (isMcouponApp) {
      // 웹뷰 전단 *iOS 웹뷰 열기 시 localStorage 공유 안됨 -> 앱에 저장.
      setKeyValue("ZOOM_leafletInfo", JSON.stringify(leafletInfo));
      setKeyValue("ZOOM_leafletImage", JSON.stringify(_chunkImageList));

      const url = `${process.env.REACT_APP_LEAFLET_URL}/zoomimage`;
      openLeaflet(url);
    } else {
      // 웹 전단
      localStorage.setItem("ZOOM_leafletInfo", JSON.stringify(leafletInfo));
      localStorage.setItem(
        "ZOOM_leafletImage",
        JSON.stringify(_chunkImageList)
      );

      window.open("/zoomimage");
    }
  };

  return (
    <button
      type="button"
      className="btn_leaflet btn_leaflet_zoom"
      onClick={handleClickZoom}
    >
      확대보기
    </button>
  );
}

export default ButtonZoom;

import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { gaPageView } from "../../utils/ga";
import { getKeyValue } from "../../utils/utility";

function ZoomImage() {
  const location = useLocation();
  const isMcouponApp = navigator.userAgent.indexOf("_mcoupon") > -1;

  const [leafletInfo, setLeafletInfo] = useState();
  const [chunkImageList, setChunkImageList] = useState([]);

  //해당 전단 데이터 추출
  const returnLeafletInfo = useCallback(() => {
    if (isMcouponApp) {
      // 웹뷰 전단
      getKeyValue("ZOOM_leafletInfo", "ZOOM_leafletInfoCallback"); //전단 정보
      getKeyValue("ZOOM_leafletImage", "ZOOM_chunkImageListCallback"); //이미지 정보

      window.ZOOM_leafletInfoCallback = (key, callbackLeafletInfo) => {
        if (callbackLeafletInfo) {
          const _leafletInfo = JSON.parse(callbackLeafletInfo) || null;
          setLeafletInfo(_leafletInfo);
        }
      };

      window.ZOOM_chunkImageListCallback = (key, callbackChunkImageList) => {
        if (callbackChunkImageList) {
          const _chunkImageList = JSON.parse(callbackChunkImageList) || null;
          setChunkImageList(_chunkImageList);
        }
      };
    } else {
      // 웹 전단
      const _leafletInfo = JSON.parse(
        localStorage.getItem("ZOOM_leafletInfo") || ""
      );
      const _chunkImageList = JSON.parse(
        localStorage.getItem("ZOOM_leafletImage") || ""
      );

      setLeafletInfo(_leafletInfo);
      setChunkImageList(_chunkImageList);
    }
  }, [isMcouponApp]);

  useEffect(() => {
    returnLeafletInfo();
  }, [returnLeafletInfo]);

  useEffect(() => {
    if (!leafletInfo) return;
    if (chunkImageList.length < 1) return;

    gaPageView(`${leafletInfo.title} - 이미지 확대`, location.pathname);

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }, [chunkImageList, leafletInfo, location.pathname]);

  return (
    <>
      {leafletInfo && (
        <>
          <Helmet>
            <title>{`${leafletInfo.title} - 이미지 확대`}</title>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, maximum-scale=10.0, user-scalable=yes"
            />
          </Helmet>
          <div className="wrapper subCont">
            <header className="leaflet_header">
              {/* 헤더 로고 */}
              <div className="headerCont2">
                <div className="logoTit"></div>
              </div>
            </header>

            <div className="leaflet_content type_nocate">
              {chunkImageList.length > 0 && (
                <ul className="zoom_leaflet">
                  {chunkImageList.map((chunkImage, i) => {
                    return (
                      <li key={i}>
                        {chunkImage.map((image, idx) => {
                          return (
                            <div className="leaflet_item" key={idx}>
                              <img
                                src={`${leafletInfo.imgPath}${image.imgs}`}
                                alt={image.title}
                              />
                            </div>
                          );
                        })}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ZoomImage;

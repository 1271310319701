import React from "react";
import { useLocation } from "react-router-dom";
import { isIOS, isAndroid } from "react-device-detect";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { gaEvent } from "../../../utils/ga";

function ButtonShare(props) {
  const { isMcouponApp, imageActiveIndex, leafletInfo } = props;
  const location = useLocation();

  //공유 링크 : https://www.mlotte.net/201?tabIndex=1
  const shareUrl = `${process.env.REACT_APP_LEAFLET_URL}/${location.pathname}?tabIndex=${imageActiveIndex}`;

  const handleClickCopyBridge = () => {
    gaEvent(leafletInfo.title, `공유하기(${leafletInfo.title})`);

    //브릿지로 copy
    try {
      if (isIOS) {
        window.webkit.messageHandlers.setClipBoard.postMessage({
          text: shareUrl,
          message: "URL이 복사되었습니다.",
        });
      } else if (isAndroid) {
        window.binder?.setClipBoard(shareUrl, "URL이 복사되었습니다.");
      }
    } catch (error) {
      console.log("setClipBoard Bridge Error");
    }
  };

  return (
    <>
      {isMcouponApp ? (
        <button
          type="button"
          className="btn_leaflet btn_leaflet_share"
          onClick={handleClickCopyBridge}
        >
          공유
        </button>
      ) : (
        <CopyToClipboard
          text={shareUrl}
          onCopy={() => alert("클립보드에 복사되었습니다.")}
        >
          <button className="btn_leaflet btn_leaflet_share">공유</button>
        </CopyToClipboard>
      )}
    </>
  );
}

export default ButtonShare;

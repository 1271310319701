/* 309,310 롯데슈퍼(~11/20) */
const superData = [
  `
  <h1>LOTTE SUPER</h1>
  <div>
    ·전단적용기간 : 2024. 11. 14(목) ~ 2024. 11. 20(수) ※일부 상품 행사 기간 상이 ※일부 상품 행사 가격 연장 가능 ※점별 취급상품 상이

    코리아 세일 페스타 11.9. ~ 11.30.
    롯데슈퍼가 준비한 고객 감사 대축제 Again 땡큐절

    땡 잡았다! 
    1 하루 특가 2 득템찬스 3 1+1ㆍ50% 4 땡전딜 5 주말 특가

    국내산 삼겹살 최대 50%
    ※조기 품절될 수 있습니다
    
    L.POINT 40% -> 롯데/신한/삼성카드 10% 추가할인
    한돈 삼겹살(100g/냉장/국내산) 1,990원
    ※페이지 하단 카드할인 세부내용 참고

    L.POINT 40%
    한돈 목심(100g/냉장/국내산) 2,388원

    '24년 김장 재료 농할갑시다! 20%
    ※L.POINT 회원 기간 중 최대 2만원 할인
    ※산지 시황에 따라 조기 품절될 수 있습니다

    농할할인 20%
    배추(3입/망/국산) 5,880원
    ※기간 : 11/14(목)~11/16(토)

    농할할인 20%
    다발무(1단/국산) 5,992원

    농할할인 20%
    깐마늘(1kg/봉/국산) 7,192원

    땡 잡았다! 득템찬스
    상생 사과(4~7입 /봉/국산)/팬시 엔비사과(1.8kg/봉/국산) 10,990/12,990원

    스위트 마운틴 바나나 (1.2kg 내외/송이/에콰도르산) 2,990원

    L.POINT 30%
    호주산 청정와규 (각 100g/냉장/호주산)
    ※점포별 취급품 상이

    청정원 행복놀이터 동물복지 유정란 (15입/대란/국산) 6,990원

    삼성카드 1,000원 할인
    풀무원 특등급 국산콩 큰두부 (500g/국산) 2,990원
    ※페이지 하단 카드할인 세부내용 참고

    동원 비티더스 명장 (사과/포도, 각 125ml×8) 각1,990원

    L.POINT 40%
    궁 쇠고기육포 오리지날/골든올리브(각 100g/원산지 별도표기) 8,940원

    2개 구매시
    CJ 소바바치킨 소이허니/양념 순살(각 240g) 10,000원
    ※1개 구매시 5,990원 ※교차구매 가능

    공구핫딜 한성 와일드 크래미(180g×3) 6,980원

    크리넥스 순수소프트 3겹 화장지(30m×30롤) 16,900원

    땡큐 하루 특가
    11/14 목 
    1+1
    양념 돼지 왕구이 (800g/냉장/원산지 별도표기) 17,800원
    
    11/15 금 
    L.POINT 5,000원 할인
    냉동 국내산 대패 삼겹살 (500g/냉동/국내산) 13,900원

    11/16 토 
    L.POINT 3,000원 할인
    통영 가리비 (700g/팩/국산) 4,990원

    11/17 일
    L.POINT 각 500원 할인
    롯데/신한/삼성카드 각2,000원 추가할인
    풀무원 얇은피 꽉찬 만두 3종(각 400g×2입) 각5,490원
    ※고기/김치/육즙진한교자
    ※페이지 하단 카드할인 세부내용 참고

    ※정상가란? 2024년 10월 24일(목) ~ 11월 13일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    땡 잡았다! 1+1 / 2개 이상 구매시 50%

    1+1
    마늘치킨 훈제 슬라이스 (400g/원산지 별도표기) 10,900원

    1+1
    하림 더미식 육즙고기교자/김치교자(각 350g×2) 각10,990원
    ※교차구매 가능

    1+1
    삼진 전통모듬어묵(802g) 12,990원

    1+1
    풀무원 해물생수제비/즉석해물칼국수 (각 2인) 각7,490원
    ※367g/424.8g ※교차구매 가능

    1+1
    풀무원 식물성 지구식단 런천미트(190g) 4,680원

    1+1
    폰타나 파스타소스 5종(각 430g) 각9,310~11,980원
    ※ 베네치아갈릭봉골레/볼로냐베이컨볼로네제/카르니아베이컨머쉬룸크림/투움바그릴드머쉬룸/라치오베이컨까르보나리따
    ※동일 가격대 교차구매 가능

    1+1
    동원/CJ/청정원 참치액 8종(상품별 상이) 각7,980~14,980원
    ※CJ 백설(순 더깔끔한맛/진 더풍부한맛, 각 900g)
    ※동원(진/순/해물/프리미엄, 각 500g)
    ※청정원(맛선생/맛선생꽃게, 각950g)
    ※동일 가격대 교차구매 가능

    1+1
    CJ 해찬들 사계절 쌈장(500g) 각5,980원

    1+1
    서울 고단백치즈/짜지않아 건강한 치즈(각 180g) 각7,590원
    ※교차구매 가능

    1+1
    청우 찰떡쿠키 오리지널(215g) 4,690원

    1+1
    CJ 명가 파래김/재래김(각 4g×12봉/원산지 별도표기) 각6,980원
    ※교차구매 가능

    1+1
    롯데 델몬트 오렌지/포도 각5,980원
    (각 1.8L) ※교차구매 가능

    1+1
    칠성사이다 外 4종(각 1.25L) 각3,580원
    ※ 칠성사이다제로/펩시콜라/펩시콜라블랙/펩시콜라제로라임 ※교차구매 가능

    2개 이상 50% 
    수퍼 테크 액체세제 리필 2종 2개이상 구매시 1개당 각5,950원
    (일반/드럼, 각 2.6L) ※교차구매 가능
    ※1개 구매시 각11,900원

    2개 이상 50%
    명장불난로 핫팩 4종 (상품별 상이) 2개이상 구매시 1개당 각1,790~3,290원
    ※교차구매 가능
    ※1개 구매시 각3,590~6,590원

    땡전딜!
    1캔 구매시
    세계맥주 BEST 1,990원
    ※4캔 구매시 7,960원
    
    상생대봉시 (4입/팩/국산) 4,990원

    종근당건강 락토핏 생유산균 골드(2g×50포) 9,990원

    통영 생굴 사전예약!
    ※사전예약 기간 : 11/14(목) ~ 11/20(수)
    ※수령일 : 11/23(토) ※사전예약 문의는 매장 안내데스크로 와주세요
    L.POINT 20%
    통영 생굴 (1kg/냉장/국산) 26,320원

    L.POINT 2,000원 -> 삼성카드 2,000원 추가할인
    스윗볼 스테비아 대추방울 토마토(500g/팩/국산) 5,990원
    ※페이지 하단 카드할인 세부내용 참고

    가지 (3입/봉/국산) 3,490원

    1등급 한우 국거리/불고기 L.POINT 30% 할인
    (각 100g/냉장/국내산 한우고기)
    ※ 점포별 취급품 상이

    L.POINT 50% 
    양념 한우불고기 (700g/냉장/원산지 별도표기) 17,900원

    왕새우튀김 (8입/15입, 원산지 별도표기) 5,490/9,990원
    ※델리코너 운영 점포에 한함

    생광어생연어초밥 (12입/원산지 별도표기) 12,900원
    ※델리코너 운영 점포에 한함

    동서 맥심 모카믹스/화이트골드 믹스 (각 180입+20입) 각27,980원

    서울 후레쉬밀크 2입 기획(900ml×2) 4,990원

    롯데/신한/삼성카드 1,000원 할인
    하림 용가리 치킨+치킨너겟 기획(300g+300g) 6,990원
    ※페이지 하단 카드할인 세부내용 참고

    삼립/롯데 호빵 11종(상품별 상이) 4,580~6,480원

    6캔 구매시
    국민맥주 에일편 10,800원
    ※1캔 구매시 2,000원

    깨끗한나라 페퍼민트 블루 물티슈캡 (70매×6입) 6,950원

    땡큐 주말 특가
    기간 : 11/14(목)~11/17(일)
    L.POINT 40% 할인
    호주산 와규등심 (100g/냉장/호주산)

    L.POINT 3,000원
    닭볶음탕용(800g/냉장/국내산) 4,990원

    1+1
    동원 덴마크 인포켓 플레인(20g×10) 14,000원

    1+1
    하림 수비드 닭가슴살 3종 (각 100g/냉장/국내산) 각4,290원
    ※교차구매 가능
    ※블랙페퍼/오리지널/바질&올리

    1+1
    롯데 알뜰 소세지 (500g) 3,790원

    ※정상가란? 2024년 10월 24일(목) ~ 11월 13일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

/* 209 210 롯데슈퍼(~11/13) */
const superNewData = [
  `
  <h1>LOTTE SUPER</h1>
  <div>
    ·전단적용기간 : 2024. 11. 7(목) ~ 2024. 11. 13(수) ※일부 상품 행사 기간 상이 ※일부 상품 행사 가격 연장 가능 ※점별 취급상품 상이
    
    롯데슈퍼가 준비한 고객 감사 대축제
    매일 매일 땡큐절 아침 종이땡 미친 할인 큐

    땡 잡았다!
    1 1+1ㆍ 50 %
    2 하루ㆍ주말 특가
    3 득템찬스
    4 땡전딜

    최대 50%
    수입갈비

    롯데/신한/삼성카드 50%
    호주청정우 찜용갈비 (100g/냉장/호주산)
    ※기간 : 11/7(목)~11/10(일)

    롯데/신한/삼성카드 30%
    수입 냉동 꽃갈비 구이 (각 100g, 냉동, 호주산/미국산)
    ※기간 : 11/11(월)~11/13(수)
    ※점별 한정물량 ※조기 품절될 수 있습니다
    ※페이지 하단 카드할인 세부내용 참고

    L.POINT 4,000원
    롯데/신한/삼성카드 1,000원 추가할인
    비파괴 당도선별 샤인머스캣 (2kg/박스/국산) 9,990원
    ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고

    GAP 신선콩나물, 맑은물에 청국장 (450g, 200g/원산지 별도표기)/국산 실속두부(520g/국산) 각990/2,990원
    ※조기 품절될 수 있습니다

    L.POINT 30% 롯데카드/삼성카드 20% 추가할인
    봉지굴(260g/봉/국산) 4,445원
    ※점포별 취급 상이
    ※페이지 하단 카드할인 세부내용 참고

    농할할인 20% 롯데/신한/삼성카드 500원 할인
    대파(봉/국산) 1,580원
    ※1일 1인 2봉 한정 ※L.POINT 회원 기간 중 1인 최대 2만원 할인
    ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고

    상생 홍시 (10~12입/팩/국산) 6,990원

    땡 잡았다! 득템찬스!
    L.POINT 2,000원
    부사 사과 (4~6입/봉/국내산) 6,990원
    ※조기 품절될 수 있습니다

    L.POINT 25%
    '김장시즌 김치와의 환상궁합' 한돈 무항생제 삼겹살/앞다리 보쌈용(각 100g/냉장/국내산) 

    L.POINT 50%
    훈제오리 슬라이스 (400g/냉장/원산지 별도표기) 8,900원

    총 41종 세계맥주 골라담기 4캔 구매시 7,960원
    ※1캔 구매시 각 2,040~3,500원 ※교차구매 가능

    끝장초밥 (12입/원산지 별도표기) 7,990원
    ※델리코너 운영 점포에 한함

    THE큰치킨 (마리/원산지 별도표기) 9,900원
    ※델리코너 운영 점포에 한함

    동원 비피더스 명장 (각 125ml×8입) 각1,990원
    ※샤인/베리

    1+1
    정식품 17곡 한끼두유,뼈에좋은 칼슘두유(콩깨쌀)/삼육 검은콩과 칼슘두유 각20,000원
    (각 190ml×16/190ml×20) ※교차구매 가능

    롯데카드/삼성카드 2,000원 할인
    크리넥스 클린케어 3겹 화장지(30m×30롤) 18,900원
    ※페이지 하단 카드할인 세부내용 참고 

    2개 이상 각 990원 할인
    고메 소바바치킨 2종/고메 너겟(상품별 용량 상이) 개당5,000원
    ※1개 구매시 각 5,990원 ※교차구매 가능

    1+1
    레모나 에스산(1.5g×90포) 각21,800원
    ※교차구매 가능

    롯데카드/삼성카드 1,030원 할인
    히말라야 핑크솔트 바디워시(각 900g) 각5,960원
    ※페이지 하단 카드할인 세부내용 참고

    땡큐 하루특가
    ※조기 품절될 수 있습니다

    11/7 목
    롯데/신한/삼성카드 
    파프리카 (개/국산) 200원 할인 890원
    ※페이지 하단카드할인 세부내용 참고
    ※조기 품절될 수 있습니다

    L.POINT 50%
    산더미 대파 소불고기(800g/팩/원산지 별도표기) 13,900원

    11/8 금
    L.POINT 1,000원
    스위텔 토마토 (800g/국산) 8,990원

    11/9 토
    농할할인 20%
    양파(1.5kg/국내산) 2,960원
    ※L.POINT 회원 기간 중 1인 최대 2만원 할인 
    ※산지 시황에 따라 조기 품절될 수 있습니다

    11/10 일
    애호박(개/국내산) 990원
    ※조기 품절될 수 있습니다

    매일 오전 10시 ~ 오후 1시
    타임쿠폰 받고 즉시 사용하세요!
    ※기간 : 10/31(목) ~ 11/13(수)
    ※쿠폰 관련 세부내용은 롯데마트몰에서 확인하실 수 있습니다
    4만원 이상 구매시 3,000원 할인

    ※정상가란? 2024년 10월 17일(목) ~ 11월 6일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div> 
  `,
  `
  <div>
    땡큐 주말특가
    11/7 목 ~11/10 일
    1+1
    파스퇴르 쾌변(각 150ml) 각1,690원
    ※사과/키위/포도 ※교차구매 가능

    1+1
    매일 바리스타(각 250ml) 각2,700원
    ※스모키/모카프레소/에스프레소라떼/카라멜딥프레소
    ※교차구매 가능

    11/8 금~11/10 일
    롯데/신한/삼성카드 50%
    캐나다 삼겹살/목심 (각 100g/냉장/캐나다산) 각1,290원
    ※기간 : 11/9(토)~11/10(일)※조기 품절될 수 있습니다
    ※ 페이지 하단 카드할인 세부내용 참고

    삼립/롯데 호빵 11종 (상품별 용량 상이) 각4,680~6,480원

    땡 잡았다! 1+1 / 2개 이상 구매시 50%
    1+1
    마늘치킨 훈제 슬라이스 (400g/원산지 별도표기) 10,900원

    1+1
    하림 더미식 육즙고기교자/김치교자(각 350g×2) ※교차구매 가능 각10,990원

    1+1
    봉지라면 6종(상품별 용량 상이) 각5,450~8,700원
    ※오뚜기 굴진짬뽕/풀무원 로스팅 서울짜장/하림 더미식 장인라면 外
    ※동일 브랜드 내 동일가격 교차구매 가능

    1+1
    떠먹는 요구르트 18종 (상품별 용량 상이) 각3,780~6,090원
    ※브랜드별 동일중량 상품 교차구매 가능

    1+1
    롯데 칠성사이다/펩시콜라/펩시콜라제로/밀키스 각5,480원
    (각 190ml×6) ※교차구매 가능

    1+1
    사조 살코기/마일드/고추참치 (각 85g×4) 각8,980원
    ※교차구매 가능

    1+1
    고래사 국탕용 모둠 어묵(415g) 7,990원

    1+1
    맛선생 국물내기 한알 3종(상품별 상이) 각9,980원
    ※디포리/야채/사골
    ※교차구매 가능

    1+1
    삼립 하이면 포차우동 2인 (담백 428g/얼큰 424g) 각5,990원
    ※교차구매 가능

    1+1
    삼양 불닭소스 4종 (상품별 규격 상이) 각4,500원
    ※교차구매 가능
    ※불닭, 핵불닭, 까르보불닭 각 200g/불닭마요 250g

    동원 양반김 전품목(상품별 상이)
    최대 50% 할인

    1+1
    절임차 전품목(상품별 용량 상이) 각9,900~10,900원
    ※동일 브랜드 내 동일가격 교차구매 가능 ※일부 상품 제외

    1+1
    켈로그 프링글스 7종 각3,380원
    (상품별 용량 상이) ※교차구매 가능

    1+1
    칠성사이다 外 4종(각 1.25L) 각3,580원
    ※ 칠성사이다제로/펩시콜라/펩시콜라블랙/펩시콜라제로라임 ※교차구매 가능

    3,300원 할인 -> 롯데카드/삼성카드 800원 추가할인
    다우니 섬유유연제 (각 1L, 블루/퍼플/핑크) 4,100원
    ※페이지 하단 카드할인 세부내용 참고

    2개 이상 50%
    퍼실 8종/퍼울 2종(상품별 상이) 2개이상 구매시 1개당 각9,950~15,450원
    ※1개 구매시 각19,900~30,900원 ※동일 상품에 한함

    빼빼로데이 행사상품 2만원 이상 구매시 L.POINT 3,000점 증정
    롯데 빼빼로 아몬드 8갑 기획(432g) 11,520원

    롯데 빼빼로 케이크박스 기획(380g) 14,400원

    땡전딜! 990/4,990/9,990원
    오늘좋은 백미밥 (210g) 990원

    삼성카드 1,000원 할인
    강원 감자(2kg/국내산) 4,990원
    ※페이지 하단 카드할인 세부내용 참고

    L.POINT 50%
    BIG 오징어(200g)/BIG 쥐포(270g) 각9,990원

    L.POINT 5,000원
    상생배 (3kg/박스/국산) 10,990원
    ※조기 품절될 수 있습니다

    가지 (3입/봉/국산) 3,490원

    마블나인이란?
    1++한우 중 가장 높은 9등급인 최상급만 선별한 프리미엄 한우
    마블나인 한우 국거리/불고기 (각 100g/냉장/국내산 한우고기) 
    L.POINT 30% 할인
    ※ 점포별 취급품 상이

    L.POINT 30% 할인
    요리하다 냉장 양념육 전품목 (상품별 상이/냉장/원산지 별도표기)

    한돈 보쌈용 앞다리 구매시 보쌈용 티백 증정! 사전예약!
    ※예약기간 : 11/7(목)~11/12(화), 6일간
    ※수령일자 : 11/15(금), 단하루
    '김장시즌 김치와의 환상궁합' 한돈 보쌈용 앞다리 (1kg/냉장/국내산 돼지고기) 15,900원

    롯데/신한/삼성카드 2,000원 할인
    완도 활 전복 (5마리/봉/냉장/국산) 7,990원
    ※페이지 하단 카드할인 세부내용 참고

    공구핫딜 한성 와일드 크래미(180g×3) 6,980원

    서울우유 (2.3L) 6,990원
    
    롯데/신한/삼성카드 1,000원 할인
    하림 용가리 치킨+치킨너겟 기획(300g+300g) 6,990원
    ※페이지 하단 카드할인 세부내용 참고

    요리하다 쫄깃한 찹쌀탕수육(550g) 2,000원 할인 5,990원

    롯데/신한/삼성카드 810원 할인
    국민맥주 라거편(500ml/캔) 990원
    ※기간 : 11/7(목)~11/9(토), 3일간
    ※페이지 하단 카드할인 세부내용 참고

    크리넥스 안심 클래식 키친타월(145매×8롤) 6,990원

    ※정상가란? 2024년 10월 17일(목) ~ 11월 6일(수) 롯데슈퍼 잠원점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함
    
  </div>
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getSuperData = () => {
  const dataArray = [];
  superData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getSuperNewData = () => {
  const dataArray = [];
  superNewData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `롯데슈퍼 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const data = {
  L309: {
    title: "스마트전단지-슈퍼309",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202411_3/02",
    contents: getSuperData(),
  },
  L310: {
    title: "스마트전단지-슈퍼310",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202411_3/02",
    contents: getSuperData(),
  },
  L209: {
    title: "스마트전단지-슈퍼209",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202411_2/02",
    contents: getSuperNewData(),
  },
  L210: {
    title: "스마트전단지-슈퍼210",
    category: [],
    type: "SUPER",
    isFooter: false,
    imgPath: "images/202411_2/02",
    contents: getSuperNewData(),
  },
};

export default data;

import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isIOS, isAndroid } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import queryString from "query-string";

import axios from "axios";

import { leafletState } from "./constants";

import fixedLeaflet from "./data/fixedLeaflet";
import superLeaflet from "./data/super";
import vicMaxx from "./data/vicMaxx";
import thisWeek from "./data/202411_2";
import willThisWeek from "./data/202411_3";

import LeafletItemV2 from "./components/LeafletItemV2";
import { gaPageView } from "../../utils/ga";
import LeafletItemPaper from "./components/LeafletItemPaper";

function List() {
  const location = useLocation();
  const navigate = useNavigate();
  let leafletType =
    queryString.parse(location.search).type === "SUPER" ? "SUPER" : "MART";

  const isMcouponApp = navigator.userAgent.indexOf("_mcoupon") > -1;
  const leafletNum = Number(location.pathname.split("/")[1]);

  const [leafletInfo, setLeafletInfo] = useState();
  const [imageInfoList, setImageInfoList] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [leafletIndex, setLeafletIndex] = useState([]);

  //마트몰 이동 스키마
  const goMartMall = (href) => {
    /*
     * "MAXX", "VIC" : 링크 이동 막기
     * "HYPER" : 롯데마트 전단행사 페이지
     * "" (룸바이홈, 매거진) : 링크 이동 막기
     * 롯데마트몰 바로가기 배너 : 롯데마트 메인 페이지
     * 롯데슈퍼 URL : /000?type=SUPER (230727 슈퍼전단 분리, 자동화)
     */
    if (!href) return;

    if (isMcouponApp) {
      //브라우저 열기
      if (isAndroid) {
        window.binder?.openBrowser(href);
      } else if (isIOS) {
        window.webkit.messageHandlers.openBrowser.postMessage({
          url: href,
        });
      }
    } else {
      //비 모바일 처리
      window.open(href, "_blank");
    }
  };

  // 현재 진행중인 행사가 아닌 경우 redirect
  const redirectLeaflet = useCallback(() => {
    if (leafletIndex.length < 1) return;

    //전점
    navigate(`/${leafletIndex[0]}${location.search}`, { replace: true });
  }, [leafletIndex, location.search, navigate]);

  const getLeafletInfo = useCallback(() => {
    //고정전단 합치기
    const sumLeaflet = {
      ...fixedLeaflet,
      ...superLeaflet,
      ...vicMaxx,
      ...thisWeek,
      ...willThisWeek,
    };

    // 전단 데이터 추출
    let leafletData = "";

    if (!leafletNum) {
      //메인도메인 진입 시 HYPER의 현재 전단 고정
      leafletData = sumLeaflet[`L${leafletIndex[0]}`];
    } else if (leafletIndex.indexOf(leafletNum) < 0) {
      //진행중인 전단이 아닌 경우 redirect
      redirectLeaflet();
    } else {
      leafletData = sumLeaflet[`L${leafletNum}`];
    }

    if (leafletData) {
      setLeafletInfo({
        imgPath: leafletData.imgPath,
        title: leafletData.title,
        type: leafletData.type,
        leafletType: leafletData.leafletType || "",
        isFooter: leafletData.isFooter || false,
        isCoupon: leafletData.isCoupon || false,
        category: leafletData.category || [],
      });
      setImageInfoList(leafletData.contents);
    } else {
      redirectLeaflet();
    }
  }, [leafletIndex, leafletNum, redirectLeaflet]);

  //전단 노출 시간 체크
  const getCrntTimeCheck = useCallback(() => {
    // 새로운 전단지 작업시 reload 되는 걸 막기 위해
    const _leafletStateThisWeek = leafletState[leafletType].thisWeek.concat(
      leafletState[leafletType].willThisWeek
    );

    axios
      .post(
        `${process.env.REACT_APP_API}/fo/user/common/current-time`,
        {},
        {
          headers: {
            "x-ssp-channel": "1",
            "Content-Type": "application/json; charset=utf-8",
            "x-ssp-gateway-service-id": "USER",
          },
        }
      )
      .then((res) => {
        const crntTime = res.data.data.current_time.substr(0, 8);

        const leafletIndex =
          crntTime - leafletState[leafletType].openDate >= 0
            ? leafletState[leafletType].willThisWeek
            : _leafletStateThisWeek;

        setLeafletIndex(leafletIndex);
      })
      .then(() => {
        setIsSuccess(true);
      });
  }, [leafletType]);

  useEffect(() => {
    getCrntTimeCheck();
  }, [getCrntTimeCheck]);

  useEffect(() => {
    if (leafletIndex.length < 1) return;
    getLeafletInfo();
  }, [getLeafletInfo, leafletIndex.length]);

  useEffect(() => {
    if (!isSuccess) return;
    if (!leafletInfo) return;
    if (leafletNum && leafletInfo.title.slice(-3) !== String(leafletNum))
      return;

    gaPageView(leafletInfo.title, location.pathname);
  }, [isSuccess, location.pathname, leafletNum, leafletInfo]);

  //개편 화면 노출 조건 = 카테고리 있는 경우
  const isRenewalView =
    leafletInfo && leafletInfo.category.length > 0 ? true : false;

  //viewport mata 확대 설정
  let viewportSetting =
    "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no";
  if (!isRenewalView) {
    viewportSetting =
      "width=device-width, initial-scale=1, maximum-scale=5.0, user-scalable=yes";
  }

  return (
    <>
      {isSuccess && leafletInfo && (
        <>
          <Helmet>
            <title>{`롯데마트 전단-${leafletNum || leafletIndex[0]}`}</title>
            <meta name="viewport" content={viewportSetting} />
          </Helmet>
          {isRenewalView ? (
            <LeafletItemV2
              imageInfoList={imageInfoList}
              leafletInfo={leafletInfo}
              isMcouponApp={isMcouponApp}
              leafletNum={leafletNum}
              goMartMall={goMartMall}
            />
          ) : (
            <LeafletItemPaper
              imageInfoList={imageInfoList}
              leafletInfo={leafletInfo}
            />
          )}
        </>
      )}
    </>
  );
}

export default List;

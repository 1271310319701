import { getUA } from "react-device-detect";

const isAndWebView = !!window.binder;
const isIOSWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(getUA);

/**
 * 값 저장
 * @param key  string
 * @param value  string
 */
export const setKeyValue = (key, value) => {
  try {
    if (isIOSWebView) {
      window.webkit.messageHandlers.setKeyValue.postMessage({
        key: key,
        value: value,
      });
    } else if (isAndWebView) {
      window.binder?.setKeyValue(key, value);
    }
  } catch (e) {
    console.log("bridgeError setKeyValue ", key);
  }
};

/**
 * 값 조회
 * @param key  string
 * @param callback  string
 */
export const getKeyValue = (key, callback) => {
  try {
    if (isIOSWebView) {
      window.webkit.messageHandlers.getKeyValue.postMessage({
        key: key,
        callback: callback,
      });
    } else if (isAndWebView) {
      window.binder?.getKeyValue(key, callback);
    }
  } catch (e) {
    console.log("bridgeError getKeyValue ", key);
  }
};

/**
 * 전단 웹뷰 열기
 * @param url  string
 */
export const openLeaflet = (url) => {
  try {
    if (isIOSWebView) {
      window.webkit.messageHandlers.openLeaflet.postMessage({ url: url });
    } else if (isAndWebView) {
      window.binder?.openLeaflet(url);
    }
  } catch (e) {
    console.log("bridgeError openLeaflet");
  }
};

import { LEAFLET_URL } from "../constants";

const allStoreData = [
  `
  <h1>LOTTE Mart</h1>
  <div>
    ·전단적용기간 : 2024. 11. 14(목) ~ 2024. 11. 27(수) < 신선식품 적용기간 : 2024. 11. 14(목) ~ 11. 20(수) >

    코리아 세일 페스타 11.9. ~ 11.30.

    국가대표 쇼핑축제
    롯데마트가 준비한 고객 감사 대축제 Again  땡큐절

    땡 잡았다!
    1 하루ㆍ주말 특가
    2 1+1ㆍ최대 50%
    3 겨울 먹거리&아이템 득템찬스

    국내산 한돈 삼겹살 반값!
    ※제주점 제외 ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고

    롯데/신한/NH농협/삼성카드 50%
    한돈 삼겹살 (100g/냉장/국내산 돼지고기) 1,490원

    롯데/신한/NH농협/삼성카드 40%
    한돈 목심 (100g/냉장/국내산 돼지고기) 1,788원

    롯데/신한/NH농협/삼성카드 10% 
    수산대전 20%
    양준혁 대방어회 (300g 내외/냉장/대방어 : 국산) 36,000원
    ※기간 : 11/14(목) ~ 11/20(수) ※9kg 이상 대방어만 사용합니다
    ※해당상품에 한함 ※입점점에 한함 ※산지시황에 따라 조기품절될 수 있습니다
    ※L.POINT 회원 기간 중 1인 주차별 최대 1만원 할인
    ※이미지 연출컷입니다 ※페이지 하단 카드할인 세부내용 참고

    뗑큐 주말 특가
    기간 : 11/14(목) ~ 11/17(일)

    11/16 토 11/17 일
    L.POINT 최대 50%
    청정와규 윗등심/호주산 살치살 (각100g/냉장/호주산) 3,990/3,990원

    L.POINT 30%
    내가 만드는 연어(횟감용/구이용) (각 100g/냉장/노르웨이산) 3,990/3,920원

    L.POINT 20%
    볼케이노 봉구이 (1팩/원산지 별도표기) 7,992원
    ※기간 : 11/15(금) ~ 11/17(일), 3일간

    1+1
    코카콜라 외 탄산음료 PET 6종 (1.2L~1.25L) 각3,280~3,480원
    ※동일 브랜드 간 교차구매 가능

    L.POINT 3,000원 할인 -> 롯데/신한/NH농협/삼성카드 2,000원 할인
    오뚜기 맛있는밥 12입 (210g×12입) 7,980원
    ※1일 1회, 1인당 4개 한정
    ※페이지 하단 카드할인 세부내용 참고

    1+1
    서울 유기농우유 (700ml) 4,980원

    1+1
    서울 트리플 슈레드치즈 (1kg) 17,980원
    ※10,000개 한정수량

    3개 골라담기
    크라운 비스켓/파이 6종 9,900원
    (상품별 용량 상이) ※교차구매 가능
    ※오프라인 한정 진행 ※1인 1일 1회, 9개 한정
    ※1개 구매시 각 3,840~4,780원

    L.POINT 10,000원 할인
    닥터비데 센서티브 캡형 (80매×4입) 6,900원

    50%
    도루코 퓨어우드 프라이팬 각16,450~21,950원

    50%
    도루코 주방가위/식과도16종 각3,450~17,950원

    땡큐 하루 특가
    11/14 목
    2팩 이상 50%
    양념 돼지 왕구이 (800g/팩/원산지 별도표기) 2팩 이상 구매시 1팩당 각9,950원
    ※1팩 구매시 각 19,900원
    ※해당일 외 : L.POINT 40% 11,940원

    11/15 금
    L.POINT 1,000원 할인
    대추방울토마토 (1kg/박스/국산) 9,990원
    ※일 10,000박스 한정

    1+1
    상온 반찬 전품목 28종(상품별 규격 상이) 각1,480~4,980원
    ※종가집 오징어채볶음 60g 外 27종
    ※동일 브랜드 내 동일 가격 간 교차구매 가능

    11/16 토
    하림/참프레 냉장 닭가슴살 (100g/냉장/원산지 별도표기) 890원
    ※제주점 제외

    11/17 일
    롯데/신한/NH농협/삼성카드 2개 이상 50%
    물/찐/군만두 22종(상품별 규격 상이) 해당카드로 2개 이상 구매시 1개당 각3,490~5,490원
    ※교차구매 가능
    ※점별 운영상품 상이 ※페이지 하단 카드할인 세부내용 참고
    ※1팩 구매시 각 6,980~10,980원

    1+1 각1,490~4,180원
    호떡/핫케익 믹스 9종(상품별 용량 상이)
    ※동일 브랜드 내 동일 가격 간 교차구매 가능
    ※오뚜기 핫케익가루 外 8종

    ※정상가란? 2024년 10월 24일(목) ~ 11월 13일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    땡 잡았다! 하나 사면 하나 더 1+1

    1+1
    마늘치킨 훈제슬라이스 (400g/원산지 별도표기) 8,990원

    1+1
    냉동 데친 바지락살 (300g/냉동/원산지 별도표기) 9,900원

    1+1
    CJ 명가김 2종(각 4g×12봉/원산지 별도표기) 각6,980원
    ※재래/파래 ※교차구매 가능

    1+1
    동원 양반 김부각 6종 (상품별 상이) 각4,470원
    ※교차구매 가능

    1+1
    HBAF 시즈닝 아몬드 6종 (각 120g, 원산지 별도표기) 각4,990원
    ※허니버터 아몬드 外 ※교차구매 가능

    1+1
    사각/국탕용 어묵 5종(상품별 상이) 각4,480~9,980원
    ※동일 브랜드간 교차구매 가능

    1+1
    풀무원 해물 수제비/칼국수(각 2인) 각7,480원
    ※기간 : 11/14(목)~11/20(수) ※교차구매 가능

    1+1
    진주햄 천하장사 오리지널/치즈 각7,980원
    (각 448g) ※교차구매 가능

    1+1
    한만두 탱글한 통새우만두(200g) 6,980원 

    1+1
    히밥 어묵쏙만두 누들떡볶이/고래사 어묵떡볶이/롯데 쉐푸드 우유미니 핫도그(상품별 규격 상이) 각6,600~10,980원
    ※동일상품에 한함

    1+1
    하림 누룽지 순살치킨/치킨텐더 (각 400g) 각9,980원
    ※교차구매 가능

    1+1
    직소싱 포도씨/해바라기 오일 4종(각 1L) 각7,900~16,900원
    ※동일 가격 상품간 교차구매 가능

    1+1
    해찬들 찍장 쌈장/매콤쌈장 (각 300g) 각4,100원
    ※교차구매 가능

    1+1
    떠먹는 요구르트 15종(각 80g×4입/85g×4입) 각3,780~6,480원
    ※빙그레/남양/매일 ※동일 브랜드 內 동일가격 교차구매 가능

    1+1
    남양 슈레드 모짜렐라 피자치즈(200g) 5,980원

    1+1
    제주우유 A2 유기농/저지/무항생제 우유 (각 750ml) 각4,980원
    ※교차구매 가능

    1+1
    동원 인포켓치즈(200g) 14,000원
    ※기간 : 11/14(목)~11/20(수) ※8,000개 한정수량

    1+1
    상온 커피 음료(상품별 상이) 각1,780~3,980원
    ※동일 시리즈간 교차구매 가능 ※일부상품 제외

    1+1
    돈시몬 착즙주스 5종 (각 1L) 각6,980~8,980원
    ※오렌지/만다린/홍자몽/적포도/토마토
    ※동일 가격간 교차구매 가능

    1+1
    대용량 곡물 차음료 전품목(각 1.35L~1.5L) 각 2,380원
    ※동일 시리즈간 교차구매 가능

    1+1
    삼육두유 전품목(상품별 상이) 각2,880~24,900원
    ※동일 가격간 교차구매 가능

    1+1
    롯데마트 단독
    유자레몬사이다(500ml) 1,980원

    1+1
    리치 켄주 야채크래커 (192g) 5,980원

    1+1
    미니 초코 크리스피 (175g) 6,980원

    1+1
    동서 포스트 오곡코코볼(570g) 6,480원

    1+1
    구카 누가 크래커 2종(각 400g) 각9,980원
    ※오리지널/솔티커피 ※교차구매 가능

    1+1
    려 발효영양 샴푸/컨디셔너(상품별 상이) 각11,800원
    ※모발/두피 ※교차구매 가능 ※점별 운영상품 상이

    1+1
    케라시스 케라틴본드 4종(각 750ml) 각12,900원
    ※교차구매 가능 ※점별 운영상품 상이

    1+1
    액츠 리필 3종 각9,900원
    (딥클린, 베이킹 2L/실내건조 2.2L) ※교차구매 가능

    ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
    ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
    땡 잡았다! 최대 50%

    L.POINT 50%
    창녕양파 품은 한우불고기 (700g/냉장/원산지 별도표기) 14,900원

    L.POINT 50%
    궁 쇠고기육포 오리지널, 골든올리브 (각 100g/원산지 별도표기) 각7,450원
    
    2개 이상 50%
    사조대림 떡볶이 3종(상품별 상이) 2개 이상 구매시 1개당 각2,490원
    ※국물/매운/짜장 ※교차구매 가능
    ※1개 구매시 각4,980원

    2개 이상 50%
    마니커 바사삭 돼지후라이드/고추순살(각 300g) 2개 이상 구매시 1개당 각4,990원
    ※교차구매 가능
    ※1개 구매시 각9,980원

    2개 이상 50%
    삼립 레디비 단팥/꿀견과 모찌붕어빵 (각 560g) 2개 이상 구매시 1개당 각4,990원
    ※교차구매 가능
    ※1개 구매시 각9,980원

    2개 이상 20%
    롯데 의성마늘 순한 비엔나/프랑크 (180g×2/210g×2) 2개 이상 구매시 1개당 각6,384/7,184원
    ※교차구매 가능
    ※1개 구매시 각7,980/8,980원

    2개 이상 50%
    엉클팝 길쭉이 찹쌀과자/보리과자 (상품별 상이) 2개 이상 구매시 1개당 각2,995/3,495원
    ※점별 운영물량 상이 ※교차구매 가능
    ※1개 구매시 각5,990/6,990원

    2개 이상 55%
    엘라스틴 퍼퓸 샴푸/컨디셔너 3종(각 1,100ml) 2개 이상 구매시 1개당 각6,705원
    ※로즈/라벤더 ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각14,900원

    2개 이상 50%
    크리넥스 안심클래식 키친타월(145매×8입) 2개 이상 구매시 1개당 각4,850원
    ※1개 구매시 9,700원

    입는 생리대 3만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ● "를 확인하세요 ※결제시 계산대에서 수령
    2개 이상 50%
    좋은느낌/화이트 입는 생리대 12종(상품별 상이) 2개 이상 구매시 1개당 각6,700~8,450원
    ※1개 구매시 각13,400~16,900원 ※교차구매 가능

    L.POINT 50%
    해피콜 루시드 프라이팬(상품별 상이) 17,450~26,950원

    L.POINT 50%
    락앤락 익스트롱 프라이팬(상품별 상이) 15,450~18,450원

    2개 이상 50%
    오늘좋은 세탁이 편한 빨아쓰는 경추베개(소 40×60cm/대 50×70cm) 2개 이상 구매시 1개당 각10,950/14,950원
    ※입점점에 한함 ※해당상품에 한함 ※교차구매 가능
    ※1개 구매시 각21,900/29,900원

    2개 이상 50%
    온더바디 리얼모이스처 바디워시 3종(각 900g) 2개 이상 구매시 1개당 각4,950원
    ※웜코튼/피오니/코코넛아몬드향
    ※1개 구매시 각9,900원
    ※교차구매 가능

    땡 잡았다! 겨울 먹거리&아이템 득템찬스

    수산물 할인 바다!
    2024 대한민국수산대전

    해양수산부와 함께하는 수산대전 20%
    ※기간 : 11/14(목) ~ 11/27(수) ※해당상품에 한함
    ※산지시황에 따라 조기품절될 수 있습니다
    ※L.POINT 회원 기간 중 1인 주차별 최대 1만원 할인 ※이미지 연출컷입니다

    롯데/신한/NH농협/삼성카드 10% -> 수산대전 20%
    남해안 생굴(100g/냉장/국산)/대용량 박스굴(1kg) 2,124/19,440원
    ※점별 취급 상이
    ※페이지 하단 카드할인 세부내용 참고
    
    상생사과(4~7입/봉/국산)/엔비사과(4~6입/봉/국산) 9,990/11,990원

    2+1
    담터 생강차/쌍화차 플러스(각 50입) 각13,500원
    ※교차구매 가능

    오늘좋은 밍크터치 극세사 이불 (S 150×200cm/Q180×220cm) 30% 각46,130/55,930원

    2개 이상 50%
    하루 핸드워머/바디워머 (상품별 상이) 2개 이상 구매시 1개당 각4,950~5,450원
    ※교차구매 가능
    ※1개 구매시 각9,900~10,900원

    2개 이상 30%
    오늘좋은 밍크스판/수면 홈웨어 (상품별 상이) 2개 이상 구매시 1개당 각9,730~17,430원
    ※입점점에 한함 ※교차구매 가능
    ※1개 구매시 각13,900~24,900원

    ※정상가란? 2024년 10월 24일(목) ~ 11월 13일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

    ※전단적용기간 : 2024. 11. 14(목) ~ 11. 27(수)

  </div>
  `,
  `
  <div>
    ·신선식품 적용기간 : 2024. 11. 14(목) ~ 11. 20(수)

    2024년 김장준비는 롯데마트에서!
    ※L.POINT 회원 기간 중 최대 2만원 할인
    ※산지 시황에 따라 조기 품절될 수 있습니다

    농할할인 20%
    배추(3입/망/국산) 5,880원
    ※기간 : 11/14(목) ~ 11/16(토)

    농할할인 20%
    다발무(1단/국산) 5,992원

    홍갓/청갓(각 1단/국산) 각2,990원

    L.POINT 3,000원
    김장용 햇추젓 (1kg/냉장/새우,천일염:국산) 13,900원

    농할할인 20%
    깐마늘(1kg/봉/국산) 7,192원

    농할할인 20%
    깐쪽파(200g/봉/국산) 3,192원

    바나나 1송이 2,990원
    ※조기 품절될 수 있습니다
    ※이미지 연출컷입니다

    스위트마운틴 바나나 (1.2kg 내외/송이/에콰도르산) 2,990원

    상생 대봉시 4입 1팩 4,990원
    ※조기 품절될 수 있습니다
    ※이미지 연출컷입니다

    상생 대봉시 (4입/팩/국산) 4,990원

    해양수산부와 함께하는 수산대전
    ※조기 품절될 수 있습니다
    ※이미지 연출컷입니다

    L.POINT 최대 25%
    완도 활 전복(중/왕) (각 1마리/냉장/국산) 1,800/4,250원

    롯데/신한/NH농협/삼성카드 20% -> 수산대전 20%
    고등어자반(특대) (1손 2마리/냉장/고등어,천일염:국산) 3,834원
    ※L.POINT 회원 기간 중 1인 주차별 최대 1만원 할인
    ※페이지 하단 카드할인 세부내용 참고

    인기 수산물 L.POINT 20%
    ※조기 품절될 수 있습니다 ※점별 운영 상품 상이
    ※이미지 연출컷입니다

    L.POINT 20%
    제주 생물갈치 (마리/특대/국산) 12,720원
    ※ 산지 시황에 따라 조기 품절될 수 있습니다

    L.POINT 20%
    직소싱 껍질벗긴 새우 (750g/원산지 별도표기) 15,920원

    L.POINT 20%
    광어연어 모둠초밥 (16입/팩/원산지 별도표기) 15,920원
    ※입점점에 한함

    L.POINT 30%
    한우 국거리/불고기(1등급, 1++(9)등급) (각 100g/냉장/국내산 한우고기) 
    ※조기 품절될 수 있습니다

    L.POINT 최대 50%
    청정와규 간편구이 모음전 와규 5초구이/야끼니꾸/자이언트 육전
    (품목별 상이/냉동/호주산)

    L.POINT 3,000원
    닭볶음탕용 (1.2kg/냉장/국내산) 7,990원
    ※제주점 제외

    2판 이상 각1,000원 할인
    행복생생란(대란/30구/국산) 2판 이상 구매시 1판당 각5,990원
    ※기간 : 11/14(목) ~ 11/17(일)    
    ※1판 구매시 6,990원

    당진 해나루쌀 (10kg/국산) 29,900원

    L.POINT 3,000원
    닭볶음탕용 (1.2kg/냉장/국내산) 7,990원
    ※제주점 제외

    L.POINT 5,000원
    철판에 구운 진미채 (250g/원산지 별도표기) 9,900원

    2개 이상 각3,500원 할인
    HBAF 시즈닝 믹스넛 3종(원산지 별도표기) 2개 이상 구매시 1개당 각4,990원
    ※ 호두강정 20g×10입/메이플 피칸 아몬드 20g×10입/마카다미아 치즈 40g×5입 ※교차구매 가능
    ※1개 구매시 각 8,490원

    ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
    ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
    반값 알뜰 초밥(20입) (1팩/원산지 별도표기) 9,990원

    네가지맛 닭강정 (1팩/원산지 별도표기) 10,990원

    L.POINT 25%
    해물 양장피 (1팩/원산지 별도표기) 14,993원

    특대 통족발 (1팩/원산지 별도표기) 19,800원
    ※기간 : 11/14(목) ~ 11/17(일)
    ※족발 입점점에 한함

    베스트 세트 (1팩/원산지 별도표기) 2,000원 할인 6,900원
    ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함

    세계맥주 5캔 구매시 9,950원
    ※조기 품절될 수 있습니다 ※점별 운영 상품 상이
    ※이미지 연출컷입니다

    캔 당 1,990원 
    세계맥주 Best(상품별 용량 상이) 5캔 구매시 9,950원
    ※1캔 구매시 각 3,000원

    L.POINT 5,000원
    조니워커 블랙 오징어게임 에디션(750ml) 54,800원

    L.POINT 5,100원
    탈리스커 포트리 (700ml) 86,900원

    3캔 구매시
    하이볼 골라담기 (상품별 용량 상이) 9,990원
    ※생레몬/라임/청귤 하이볼, 퐁당 하이볼 外 5종
    ※1캔 구매시 각 4,000원

    L.POINT 3,000원
    롱 반 메를로/피노누아 (각 750ml) 각11,900원

    요리하다 월드뷔페!
    ※52개점 한정 운영
    각양각색 다양한 나라의 문화와 스타일을 맛보다! 셰프의 월드 레시피를 즐겨보세요!

    요리하다 월드뷔페 상품 2개 이상 구매시 오늘좋은 탄산수 라임(500ml) 증정!
    ※한정물량 소진시 행사 자동 종료

    작아도 맛있는 그린키위(개/뉴질랜드산) 10개 구매시 3,000원
    ※기간 : 11/14(목) ~ 11/17(일) ※조기 품절될 수 있습니다
    ※ 1개 구매시 300원

    L.POINT 1,000원
    델몬트 컷팅 파인애플(400g/팩/필리핀산) 4,990원
    ※기간 : 11/15(금) ~ 11/17(일)

    감자 (2kg/박스/국산) 4,990원

    친환경 어린잎 채소 (90g×2팩/국산) 2,990원

    L.POINT 1,000원
    칠레산 블루베리(310g/팩/칠레산) 9,990원
    ※조기 품절될 수 있습니다

    L.POINT 2,000원
    캐나다산 냉동 야생 블루베리 13,990원
    (1.36kg/봉/캐나다산)

    국내산 킹 단호박 (개/국산) 3,990원

    전골용 모듬채소 (430g/팩/국산) 3,990원

    ※정상가란? 2024년 10월 24일(목) ~ 11월 13일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 10월 30일(목) ~ 11월 13일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

    ※전단적용기간 : 2024. 11. 14(목) ~ 11. 27(수)

  </div>
  `,
  `
  <div>
    냉장/냉동 식품

    롯데/신한/NH농협/삼성카드 3개 이상 각1,980원 할인
    오뚜기 콤비네이션/불고기 피자 (415g/396g) 해당카드로 3개 이상 구매시 1개당 각4,000원
    ※기간 : 11/14(목)~11/17(일) ※교차구매 가능
    ※1개 구매시 각5,980원

    풀무원 통째로 통치즈 만두/통새우 만두(각 630g) 각10,980원

    CJ 육공육 스모크소시지(260g×2)/안심 비엔나(200g×2) 6,980/7,480원

    한성 크래미(180g×2) 4,980원

    조미대용 식품

    롯데마트 단독
    해찬들 우리쌀 태양초 고추장 2입 기획(500g×2입) 9,980원
    ※우리쌀 매운 태양초 고추장 500g+100% 우리쌀 태양초 고추장 500g
    
    2개 이상 각1,500원 할인
    청정원 파스타소스 11종 (상품별 규격 상이) 2개 이상 구매시 1개당 각4,480~5,480원
    ※교차구매 가능
    ※1개 구매시 각5,980~6,980원

    L.POINT 1,000원
    롯데마트 단독
    참치액을 넣어 감칠맛을 더욱 살린 동원 참치!
    동원참치 참치액 ADDED (135g×6입) 14,980원

    L.POINT 5,000원
    씨제이 스팸 클래식(120g×5입) 9,980원
    ※기간 : 11/14(목)~11/17(일)

    유제품/음료
    2개 이상 20%
    제주 삼다수그린 (500ml×20입/2L×6입) 2개 이상 구매시 1개당 각5,184/7,680원
    ※제주점 행사 상이
    ※1개 구매시 각 6,480/9,600원 ※교차구매 가능

    3개 구매시
    코카콜라/롯데칠성 6입 캔 11종(상품별 상이) 9,900원
    ※교차구매 가능
    ※코카콜라 7종 1개 구매시 각 3,430~4,170원
    ※칠성 4종 1개 구매시 각 5,480원

    L.POINT 2,000원
    롯데마트 단독
    서울 체다 슬라이스 치즈 특별기획(18g×50입) 10,980원

    서울 후레쉬 밀크 기획 (900ml×2입) 500원 할인 4,980원

    롯데/신한/NH농협/삼성카드 1만원 이상 30%
    풀무원 다논 요구르트 전품목
    ※기간 : 11/14(목)~11/20(수)

    기호식품
    크라운제과 과자 전품목 1만 3천원 이상 구매시 칠성사이다 제로(1.5L) 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉪ "를 확인하세요
    크라운 참크래커 2번들(560g)/크라운 빅파이 영동포도(18g×18입) 6,080/4,180원

    L.POINT 900원
    삼립 호빵 8입 4종(각 90g×8입) 7,880/8,380원
    ※단팥/단팥&고구마/단팥&야채/단팥&피자

    롯데웰푸드 정년이 온팩상품 1만 5천원 이상 구매시 호빵 그립톡 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉣ "를 확인하세요
    롯데 마가렛트 오리지널(352g)/롯데 빅단팥빵 3입(300g) 4,990/3,380원

    네슬레 코리아(스타벅스 커피) 전품목 2만 5천원 이상 구매시 스타벅스 홀리데이노트(줄무늬) 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1개)
    ※전점 5,000개 한정 ※구분자 " # "를 확인하세요
    ※점포별 사은품 한정 수량 배분 ※점포별 입고 상품 상이
    스타벅스 홀리데이 미디엄/다크 로스트 머그 기획(각 1.1g×90입) 각31,490원

    농심
    농심 라면 전품목 1만원 이상 구매시 신라면 리유저블백 증정!
    ※기간 : 11/14(목)~11/27(수)
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1개)
    ※구분자 ' ㉡ '를 확인하세요 ※전점 1만개 한정
    2개 이상 10%
    농심 신라면/얼큰한 너구리(각 5개입) 2개 이상 구매시 1개당 각3,510/3,762원
    ※1개 구매시 각3,900/4,180원 ※교차구매 가능

    오뚜기
    오뚜기 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 ' ㉧ '를 확인하세요 ※결제시 계산대에서 수령
    2개 이상 50%
    오뚜기 마르게리타/콘치즈/스파이시마요 포테이토 피자(상품별 규격 상이) 2개 이상 구매시 1개당 각4,990원
    ※1개 구매시 각9,980원 ※교차구매 가능

    대상주식회사 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 ' ㉢ '를 확인하세요 ※결제시 계산대에서 수령
    1+1
    종가 맛김치(800g)/청정원 씨간장숙성 양조간장(1.7L)/100% 국산 고추장(1kg) 14,900/14,980/19,800원
    ※동일상품에 한함

    밥 말아 먹으면 더 맛있는 롯데 단독 라면!
    L.POINT 500원
    팔도&양반 미역국라면(4개입) 3,480원

    1+1
    동원 양반 백합 미역국 (460g) 5,480원

    요리하다 후라이드 바사삭 치킨(400g)/허니 바사삭 치킨 (375g) 1,000원 할인 각4,990원

    오늘좋은 꿀유자차/꿀레몬차/생강차(각 1kg) 1,500원 할인 각4,990원

    ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
    ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
    2개 이상 50%
    살림 안정 물가 안정 인기 생필품
    테크 액체세제 전품목(상품별 상이) 2개 이상 구매시 1개당 각4,950~9,900원
    ※기간 : 11/14(목)~11/17(일)
    ※교차구매 가능 ※공구핫딜/시트/캡슐세제 제외
    ※1개 구매시 각9,900~19,800원

    2개 이상 50%
    다우니 섬유유연제 13종(상품별 상이) 2개 이상 구매시 1개당 각 8,500~11,650원
    ※교차구매 가능 ※롯데마트몰 제외
    ※1개 구매시 각17,000~23,300원

    2개 이상 20%
    울세제 전품목(상품별 상이) 2개 이상 구매시 1개당 각4,400~7,920원
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각5,500~9,900원

    2개 이상 50%
    홈스타 전품목(상품별 상이) 2개 이상 구매시 1개당 각2,950~5,450원
    ※해당상품에 한함
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각5,900~10,900원

    2개 이상 50%
    자연퐁 11종(상품별 상이) 2개 이상 구매시 1개당 각3,950~9,900원
    ※해당상품에 한함
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각7,900~19,800원

    2개 이상 50%
    깨끗한나라 순수한면 생리대 2만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉦"를 확인하세요 ※결제시 계산대에서 수령
    순수한면 제로 울트라날개/입는 오버나이트(상품별 상이) 2개 이상 구매시 1개당 각4,000~7,950원
    ※교차구매 가능
    ※1개 구매시 각8,000~15,900원

    2개 이상 50% 
    마미포코 기저귀 3만원 이상 구매시 5천원 롯데상품권 증정!
    ※기간 : 11/14(목)~11/27(수) ※브랜드별 합산불가
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉲"를 확인하세요 ※결제시 계산대에서 수령
    마미포코 하이퍼드라이/슈퍼컴포트 6종(상품별 상이) 2개 이상 구매시 1개당 각13,950~14,950원
    ※1개 구매시 각27,900~29,900원 ※교차구매 가능

    롯데/신한/NH농협/삼성카드 60%
    크리넥스 순수 소프트 3겹 화장지(35m×30롤) 15,560원

    2개 이상 50% 
    깨끗한나라 페퍼민트/퓨어 물티슈(상품별 상이) 2개 이상 구매시 1개당 각6,950원
    ※교차구매 가능
    ※1개 구매시 각13,900원

    2개 이상 55% 
    히말라야 핑크솔트 클렌징 폼 2종 (각 200ml) 2개 이상 구매시 1개당 각4,950원
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각11,000원

    VT 전품목 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 ' V 를' 확인하세요 ※결제시 계산대에서 수령

    VT 시카 바이탈 에센스 기획 (100ml+리들샷 2ml×2입) 23,900원

    아리얼 세븐데이즈 마스크팩 레몬C/알로에H(각 10매) 각7,500원
    ※구/신형 패키지 혼용 운영(점포별 상이)

    밀크바오밥 딥 데미지 샴푸/딥 퍼퓸 바디워시(각 1,200ml) 각9,900/8,900원
    ※화이트머스크/은방울꽃 비누 향 ※점별 운영상품 상이

    센소다인 화이트 치약 기획세트 (치약 2개+칫솔 3개) 13,900원
    ※점별 재고 수량 상이
    ※창립기획 한정수량

    아이깨끗해 기획 (각 용기 250ml+리필 200ml×2입) 2,000원 할인 각6,490원
    ※레몬/순 ※점별 운영상품 상이

    아모레퍼시픽×종근당건강 행사상품 3만 5천원 이상 구매시 스페셜 3종 키트 증정!
    ※기간 : 11/14(목)~11/27(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1개)
    ※구분자 ' ♥ '를 확인하세요 ※한정수량으로 소진시 조기종료
    ※해당상품에 한함 ※점별 입점상품 상이

    미쟝센 퍼펙트세럼 샴푸/컨디셔너/트리트먼트 6종 각7,900원
    (상품별 상이) ※점별 운영상품 상이

    해피바스 인기 바디워시&로션 7종 (상품별 상이) 각5,900원
    ※점별 운영상품 상이

    1+1
    닥터메디안 멀티케어 치약 (120g×3입) 8,900원

    L.POINT 3,000원
    아임비타 이뮨플러스 12+3 기획 (23.5g×15입) 22,800원
    
    2+1
    락토핏 당케어 60포 (2g×60포) 27,900원

    프릴 전품목 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 ' ㉬ '를 확인하세요 ※결제시 계산대에서 수령

    2개 이상 50%
    프릴 전품목(용기 700ml/리필 1L) 2개 이상 구매시 1개당 각4,750원
    ※교차구매 가능 ※해당상품에 한함 ※점별 운영상품 상이
    ※1개 구매시 각9,500원

    화장품 3만원 이상 구매시 건강기능식품 5% 추가 할인
    3만원 이상 구매시 건강기능식품 화장품 5% 추가 할인
    ※기간 : 11/14(목)~11/17(일)
    ※점별 입점점/단일 브랜드/당일 영수증에 한함
    ※참여 브랜드 매장 내 안내문 참고

    ※정상가란? 2024년 10월 24일(목) ~ 11월 13일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

    ※전단적용기간 : 2024. 11. 14(목) ~ 11. 27(수)

  </div>
  `,
  `
  <div>
    테팔 해당상품 최대 50% 할인
    테팔 스타트이지 프라이팬(상품별 상이) 40% 24,480~33,480원

    인텐시움 통3중/티타늄 블라썸 냄비(상품별 상이) 40% 26,280~37,680원

    시큐어 트랜디 압력솥(2L) 50% 43,400원

    크린랩 인기상품 최대 50%/2+1기획팩
    2개 이상 50% 
    크린롤백(각 200매, 미니/중/대) 2개 이상 구매시 1개당 각2,045/3,045/4,045원
    ※1개 구매시 각4,090/6,090/8,090원

    2+1 기획팩
    크린랩/크린장갑 2+1기획팩(22cm×50m/100매) 각10,980/10,180원

    크린랩 간편 물걸레 청소포 (표준형/대형, 각 40매) 30% 3,000/3,490원 
    ※입점점에 한함 ※해당상품에 한함
    ※매장에 더 많은 상품이 준비되어 있습니다

    겨울시즌 보온용품 최대 20% 할인
    겨울시즌 가전, 보온시트(상품별 상이) 최대 20% 4,880~78,400원

    전기요(싱글 105×180cm/더블 135×180cm) 20% 55,920~71,920원
    ※입점점에 한함 ※해당상품에 한함

    쿡셀 전품목 5만원 이상 구매시 10,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수) ※브랜드별 합산불가
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※일부품목제외 ※결제시 계산대에서 수령
    ※구분자 "◆"를 확인하세요
    쿡셀 브랜드(상품별 상이) 20% 31,120~50,240원

    글라스락 브랜드 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수) ※브랜드별 합산불가
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉺"를 확인하세요 ※결제시 계산대에서 수령
    L.POINT 최대 50%
    글라스락 브랜드(상품별 상이) 각2,450~15,950원

    2개 이상 40%
    오늘좋은 스웨트셔츠/긴팔티셔츠/조거팬츠/레깅스 外 2개 이상 구매시 1개당 각5,940~13,140원
    ※입점점에 한함 ※해당상품에 한함※교차구매 가능
    ※기간 : 11/14(목)~11/17(일)
    ※1개 구매시 각9,900~21,900원

    2개 이상 30%
    오늘좋은 히트 성인/아동 동내복, 양말 2개 이상 구매시 1개당 각2,730/11,130원
    ※입점점에 한함 ※교차구매 가능
    ※1개 구매시 각3,900~15,900원

    2개 이상 30%
    비비안 스타킹 2개 이상 구매시 1개당 각2,030~5,530원
    ※입점점에 한함 ※교차구매 가능
    ※1개 구매시 각2,900~7,900원

    오늘좋은 발이 따뜻한 거실화 (핑크 250mm/그레이 280mm) 20% 10,320원
    ※입점점에 한함 ※해당상품에 한함

    2개 이상 20%
    브랜드 양말(상품별 상이) 2개 이상 구매시 1개당 각7,920~8,720원
    ※뉴발란스, 언더아머, 휠라, 네파, MLB 등
    ※1개 구매시 각 9,900~10,900원
    ※교차구매 가능 ※점별 상품 상이
    ※기간 : 10/31(목)~11/27(수)

    1+1 기획팩
    테이팩스 니트릴장갑 1+1기획팩 (상품별 상이) 각8,900원

    최대 30%
    3M 베이비 핑크 고무장갑/제로스크레치 수세미(10입) 外 1,950~8,790원

    30%
    룸바이홈 럭셔리 호텔 세면타월 4P(네이비/화이트/그레이) 18,130원
    ※입점점에 한함 ※해당상품에 한함
    ※매장에 더 많은 상품이 준비되어 있습니다

    1+1 밴딩
    그라스 디퓨저 (105ml) 24,900원

    1+1 기획팩
    벡셀 알카라인 건전지 (각 10입, AA/AAA) 각9,900원

    반려동물 식품
    최대 30%
    시저/아이엠즈 행사상품 2만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉠"를 확인하세요 ※결제시 계산대에서 수령
    시저 간식/사료 30종 (상품별 상이) 2,100~10,115원
    ※점별 재고 상이

    11/20 수 세계 어린이의 날
    어린이를 위한 필수 & 추천 아이템
    하기스 기저귀 8만원 이상 구매시 10,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/20(수) ※브랜드별 합산불가
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉻"를 확인하세요 ※결제시 계산대에서 수령

    2개 이상 50%
    하기스 네이처메이드/맥스드라이 19종(상품별 상이) 2개 이상 구매시 1개당 각15,300~22,950원
    ※1개 구매시 각30,600~45,900원 ※교차구매 가능
    ※기간 : 11/14(목)~11/20(수)

    베이비페어 25대 브랜드 롯데/신한/NH농협/삼성카드 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수) ※브랜드별 합산불가
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉥"를 확인하세요 ※결제시 계산대에서 수령
    메디안 키즈 365 Days(3단계) (12입) 1,400원 할인 11,900원
    ※점별 재고 상이

    슈팅스타 티니핑 미용티슈 (230매×6입) 7,650원

    아토마일드 빅물티슈(120매×6입) 9,900원

    1+1
    인기 이유식/유아간식 54종 (상품별 상이) 1,500~5,400원
    ※동일브랜드/동일 매가 간 교차구매 가능

    레고 Lamborghini Lambo V12 VisionGT(76923) 7,000원 할인 27,900원
    (10세 이상/26.2×7.2×14.1cm)

    슈팅스타 무빙 스타하츄핑 外 피규어 11종(3세이상/상품별 상이) 12,900~29,900원
    ※입점점에 한함 ※점별 재고 상이

    스위치 OLED 모여봐요 동물의 숲 세트 + 스위치 모여봐요 동물의 숲 해피홈 파라다이스
    동시구매시 15,000원 할인 425,000원
    ※스위치 OLED 모여봐요 동물의숲 415,000원
    ※ 스위치 모여봐요 동물의숲 해피홈 파라다이스 25,000원
    ※전체이용가 ※입점점에 한함 ※점별 재고 상이

    ※정상가란? 2024년 10월 24일(목) ~ 11월 13일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const zettaData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
    ·전단적용기간 : 2024. 11. 14(목) ~ 2024. 11. 27(수) < 신선식품 적용기간 : 2024. 11. 14(목) ~ 11. 20(수) >
    ·제타플렉스 잠실점

    코리아 세일 페스타 11.9. ~ 11.30.
    국가대표 쇼핑축제
    롯데마트 제타플렉스가 준비한 고객 감사 대축제 Again 땡큐절

    땡 잡았다!
    1 하루ㆍ주말 특가
    2 1+1ㆍ최대 50%
    3 겨울 먹거리&아이템 득템찬스

    국내산 한돈 삼겹살 반값!
    ※제주점 제외 ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고

    롯데/신한/NH농협/삼성카드 50%
    한돈 삼겹살 (100g/냉장/국내산 돼지고기) 1,490원

    롯데/신한/NH농협/삼성카드 40%
    한돈 목심 (100g/냉장/국내산 돼지고기) 1,788원

    롯데/신한/NH농협/삼성카드 10% 
    수산대전 20%
    양준혁 대방어회 (300g 내외/냉장/대방어 : 국산) 36,000원
    ※기간 : 11/14(목) ~ 11/20(수) ※9kg 이상 대방어만 사용합니다
    ※해당상품에 한함 ※입점점에 한함 ※산지시황에 따라 조기품절될 수 있습니다
    ※L.POINT 회원 기간 중 1인 주차별 최대 1만원 할인
    ※이미지 연출컷입니다 ※페이지 하단 카드할인 세부내용 참고

    뗑큐 주말 특가
    기간 : 11/14(목) ~ 11/17(일)

    11/16 토 11/17 일
    L.POINT 최대 50%
    청정와규 윗등심/호주산 살치살 (각100g/냉장/호주산) 3,990/3,990원

    L.POINT 30%
    내가 만드는 연어(횟감용/구이용) (각 100g/냉장/노르웨이산) 3,990/3,920원

    L.POINT 20%
    볼케이노 봉구이 (1팩/원산지 별도표기) 7,992원
    ※기간 : 11/15(금) ~ 11/17(일), 3일간

    1+1
    코카콜라 외 탄산음료 PET 6종 (1.2L~1.25L) 각3,280~3,480원
    ※동일 브랜드 간 교차구매 가능

    L.POINT 3,000원 할인 -> 롯데/신한/NH농협/삼성카드 2,000원 할인
    오뚜기 맛있는밥 12입 (210g×12입) 7,980원
    ※1일 1회, 1인당 4개 한정
    ※페이지 하단 카드할인 세부내용 참고

    1+1
    서울 유기농우유 (700ml) 4,980원

    1+1
    서울 트리플 슈레드치즈 (1kg) 17,980원
    ※10,000개 한정수량

    3개 골라담기
    크라운 비스켓/파이 6종 9,900원
    (상품별 용량 상이) ※교차구매 가능
    ※오프라인 한정 진행 ※1인 1일 1회, 9개 한정
    ※1개 구매시 각 3,840~4,780원

    L.POINT 10,000원 할인
    닥터비데 센서티브 캡형 (80매×4입) 6,900원

    50%
    도루코 퓨어우드 프라이팬 각16,450~21,950원

    50%
    도루코 주방가위/식과도16종 각3,450~17,950원

    땡큐 하루 특가
    11/14 목
    2팩 이상 50%
    양념 돼지 왕구이 (800g/팩/원산지 별도표기) 2팩 이상 구매시 1팩당 각9,950원
    ※1팩 구매시 각 19,900원
    ※해당일 외 : L.POINT 40% 11,940원

    11/15 금
    L.POINT 1,000원 할인
    대추방울토마토 (1kg/박스/국산) 9,990원
    ※일 10,000박스 한정

    1+1
    상온 반찬 전품목 28종(상품별 규격 상이) 각1,480~4,980원
    ※종가집 오징어채볶음 60g 外 27종
    ※동일 브랜드 내 동일 가격 간 교차구매 가능

    11/16 토
    하림/참프레 냉장 닭가슴살 (100g/냉장/원산지 별도표기) 890원
    ※제주점 제외

    11/17 일
    롯데/신한/NH농협/삼성카드 2개 이상 50%
    물/찐/군만두 22종(상품별 규격 상이) 해당카드로 2개 이상 구매시 1개당 각3,490~5,490원
    ※교차구매 가능
    ※점별 운영상품 상이 ※페이지 하단 카드할인 세부내용 참고
    ※1팩 구매시 각 6,980~10,980원

    1+1 각1,490~4,180원
    호떡/핫케익 믹스 9종(상품별 용량 상이)
    ※동일 브랜드 내 동일 가격 간 교차구매 가능
    ※오뚜기 핫케익가루 外 8종

    ※정상가란? 2024년 10월 24일(목) ~ 11월 13일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    땡 잡았다! 하나 사면 하나 더 1+1

    1+1
    마늘치킨 훈제슬라이스 (400g/원산지 별도표기) 8,990원

    1+1
    냉동 데친 바지락살 (300g/냉동/원산지 별도표기) 9,900원

    1+1
    CJ 명가김 2종(각 4g×12봉/원산지 별도표기) 각6,980원
    ※재래/파래 ※교차구매 가능

    1+1
    동원 양반 김부각 6종 (상품별 상이) 각4,470원
    ※교차구매 가능

    1+1
    HBAF 시즈닝 아몬드 6종 (각 120g, 원산지 별도표기) 각4,990원
    ※허니버터 아몬드 外 ※교차구매 가능

    1+1
    사각/국탕용 어묵 5종(상품별 상이) 각4,480~9,980원
    ※동일 브랜드간 교차구매 가능

    1+1
    풀무원 해물 수제비/칼국수(각 2인) 각7,480원
    ※기간 : 11/14(목)~11/20(수) ※교차구매 가능

    1+1
    진주햄 천하장사 오리지널/치즈 각7,980원
    (각 448g) ※교차구매 가능

    1+1
    한만두 탱글한 통새우만두(200g) 6,980원 

    1+1
    히밥 어묵쏙만두 누들떡볶이/고래사 어묵떡볶이/롯데 쉐푸드 우유미니 핫도그(상품별 규격 상이) 각6,600~10,980원
    ※동일상품에 한함

    1+1
    하림 누룽지 순살치킨/치킨텐더 (각 400g) 각9,980원
    ※교차구매 가능

    1+1
    직소싱 포도씨/해바라기 오일 4종(각 1L) 각7,900~16,900원
    ※동일 가격 상품간 교차구매 가능

    1+1
    해찬들 찍장 쌈장/매콤쌈장 (각 300g) 각4,100원
    ※교차구매 가능

    1+1
    떠먹는 요구르트 15종(각 80g×4입/85g×4입) 각3,780~6,480원
    ※빙그레/남양/매일 ※동일 브랜드 內 동일가격 교차구매 가능

    1+1
    남양 슈레드 모짜렐라 피자치즈(200g) 5,980원

    1+1
    제주우유 A2 유기농/저지/무항생제 우유 (각 750ml) 각4,980원
    ※교차구매 가능

    1+1
    동원 인포켓치즈(200g) 14,000원
    ※기간 : 11/14(목)~11/20(수) ※8,000개 한정수량

    1+1
    상온 커피 음료(상품별 상이) 각1,780~3,980원
    ※동일 시리즈간 교차구매 가능 ※일부상품 제외

    1+1
    돈시몬 착즙주스 5종 (각 1L) 각6,980~8,980원
    ※오렌지/만다린/홍자몽/적포도/토마토
    ※동일 가격간 교차구매 가능

    1+1
    대용량 곡물 차음료 전품목(각 1.35L~1.5L) 각 2,380원
    ※동일 시리즈간 교차구매 가능

    1+1
    삼육두유 전품목(상품별 상이) 각2,880~24,900원
    ※동일 가격간 교차구매 가능

    1+1
    롯데마트 단독
    유자레몬사이다(500ml) 1,980원

    1+1
    리치 켄주 야채크래커 (192g) 5,980원

    1+1
    미니 초코 크리스피 (175g) 6,980원

    1+1
    동서 포스트 오곡코코볼(570g) 6,480원

    1+1
    구카 누가 크래커 2종(각 400g) 각9,980원
    ※오리지널/솔티커피 ※교차구매 가능

    1+1
    려 발효영양 샴푸/컨디셔너(상품별 상이) 각11,800원
    ※모발/두피 ※교차구매 가능 ※점별 운영상품 상이

    1+1
    케라시스 케라틴본드 4종(각 750ml) 각12,900원
    ※교차구매 가능 ※점별 운영상품 상이

    1+1
    액츠 리필 3종 각9,900원
    (딥클린, 베이킹 2L/실내건조 2.2L) ※교차구매 가능

    ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
    ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
        땡 잡았다! 최대 50%

    L.POINT 50%
    창녕양파 품은 한우불고기 (700g/냉장/원산지 별도표기) 14,900원

    L.POINT 50%
    궁 쇠고기육포 오리지널, 골든올리브 (각 100g/원산지 별도표기) 각7,450원
    
    2개 이상 50%
    사조대림 떡볶이 3종(상품별 상이) 2개 이상 구매시 1개당 각2,490원
    ※국물/매운/짜장 ※교차구매 가능
    ※1개 구매시 각4,980원

    2개 이상 50%
    마니커 바사삭 돼지후라이드/고추순살(각 300g) 2개 이상 구매시 1개당 각4,990원
    ※교차구매 가능
    ※1개 구매시 각9,980원

    2개 이상 50%
    삼립 레디비 단팥/꿀견과 모찌붕어빵 (각 560g) 2개 이상 구매시 1개당 각4,990원
    ※교차구매 가능
    ※1개 구매시 각9,980원

    2개 이상 20%
    롯데 의성마늘 순한 비엔나/프랑크 (180g×2/210g×2) 2개 이상 구매시 1개당 각6,384/7,184원
    ※교차구매 가능
    ※1개 구매시 각7,980/8,980원

    2개 이상 50%
    엉클팝 길쭉이 찹쌀과자/보리과자 (상품별 상이) 2개 이상 구매시 1개당 각2,995/3,495원
    ※점별 운영물량 상이 ※교차구매 가능
    ※1개 구매시 각5,990/6,990원

    2개 이상 55%
    엘라스틴 퍼퓸 샴푸/컨디셔너 3종(각 1,100ml) 2개 이상 구매시 1개당 각6,705원
    ※로즈/라벤더 ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각14,900원

    2개 이상 50%
    크리넥스 안심클래식 키친타월(145매×8입) 2개 이상 구매시 1개당 각4,850원
    ※1개 구매시 9,700원

    입는 생리대 3만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ● "를 확인하세요 ※결제시 계산대에서 수령
    2개 이상 50%
    좋은느낌/화이트 입는 생리대 12종(상품별 상이) 2개 이상 구매시 1개당 각6,700~8,450원
    ※1개 구매시 각13,400~16,900원 ※교차구매 가능

    L.POINT 50%
    해피콜 루시드 프라이팬(상품별 상이) 17,450~26,950원

    L.POINT 50%
    락앤락 익스트롱 프라이팬(상품별 상이) 15,450~18,450원

    2개 이상 50%
    오늘좋은 세탁이 편한 빨아쓰는 경추베개(소 40×60cm/대 50×70cm) 2개 이상 구매시 1개당 각10,950/14,950원
    ※입점점에 한함 ※해당상품에 한함 ※교차구매 가능
    ※1개 구매시 각21,900/29,900원

    2개 이상 50%
    온더바디 리얼모이스처 바디워시 3종(각 900g) 2개 이상 구매시 1개당 각4,950원
    ※웜코튼/피오니/코코넛아몬드향
    ※1개 구매시 각9,900원
    ※교차구매 가능

    땡 잡았다! 겨울 먹거리&아이템 득템찬스

    수산물 할인 바다!
    2024 대한민국수산대전

    해양수산부와 함께하는 수산대전 20%
    ※기간 : 11/14(목) ~ 11/27(수) ※해당상품에 한함
    ※산지시황에 따라 조기품절될 수 있습니다
    ※L.POINT 회원 기간 중 1인 주차별 최대 1만원 할인 ※이미지 연출컷입니다

    롯데/신한/NH농협/삼성카드 10% -> 수산대전 20%
    남해안 생굴(100g/냉장/국산)/대용량 박스굴(1kg) 2,124/19,440원
    ※점별 취급 상이
    ※페이지 하단 카드할인 세부내용 참고
    
    상생사과(4~7입/봉/국산)/엔비사과(4~6입/봉/국산) 9,990/11,990원

    2+1
    담터 생강차/쌍화차 플러스(각 50입) 각13,500원
    ※교차구매 가능

    오늘좋은 밍크터치 극세사 이불 (S 150×200cm/Q180×220cm) 30% 각46,130/55,930원

    2개 이상 50%
    하루 핸드워머/바디워머 (상품별 상이) 2개 이상 구매시 1개당 각4,950~5,450원
    ※교차구매 가능
    ※1개 구매시 각9,900~10,900원

    2개 이상 30%
    오늘좋은 밍크스판/수면 홈웨어 (상품별 상이) 2개 이상 구매시 1개당 각9,730~17,430원
    ※입점점에 한함 ※교차구매 가능
    ※1개 구매시 각13,900~24,900원

    ※정상가란? 2024년 10월 24일(목) ~ 11월 13일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

    ※전단적용기간 : 2024. 11. 14(목) ~ 11. 27(수)

  </div>
  `,
  `
  <div>
    ·신선식품 적용기간 : 2024. 11. 14(목) ~ 11. 20(수)

    2024년 김장준비는 롯데마트에서!
    ※L.POINT 회원 기간 중 최대 2만원 할인
    ※산지 시황에 따라 조기 품절될 수 있습니다

    농할할인 20%
    배추(3입/망/국산) 5,880원
    ※기간 : 11/14(목) ~ 11/16(토)

    농할할인 20%
    다발무(1단/국산) 5,992원

    홍갓/청갓(각 1단/국산) 각2,990원

    L.POINT 3,000원
    김장용 햇추젓 (1kg/냉장/새우,천일염:국산) 13,900원

    농할할인 20%
    깐마늘(1kg/봉/국산) 7,192원

    농할할인 20%
    깐쪽파(200g/봉/국산) 3,192원

    바나나 1송이 2,990원
    ※조기 품절될 수 있습니다
    ※이미지 연출컷입니다

    스위트마운틴 바나나 (1.2kg 내외/송이/에콰도르산) 2,990원

    상생 대봉시 4입 1팩 4,990원
    ※조기 품절될 수 있습니다
    ※이미지 연출컷입니다

    상생 대봉시 (4입/팩/국산) 4,990원

    해양수산부와 함께하는 수산대전
    ※조기 품절될 수 있습니다
    ※이미지 연출컷입니다

    L.POINT 최대 25%
    완도 활 전복(중/왕) (각 1마리/냉장/국산) 1,800/4,250원

    롯데/신한/NH농협/삼성카드 20% -> 수산대전 20%
    고등어자반(특대) (1손 2마리/냉장/고등어,천일염:국산) 3,834원
    ※L.POINT 회원 기간 중 1인 주차별 최대 1만원 할인
    ※페이지 하단 카드할인 세부내용 참고

    인기 수산물 L.POINT 20%
    ※조기 품절될 수 있습니다 ※점별 운영 상품 상이
    ※이미지 연출컷입니다

    L.POINT 20%
    제주 생물갈치 (마리/특대/국산) 12,720원
    ※ 산지 시황에 따라 조기 품절될 수 있습니다

    L.POINT 20%
    직소싱 껍질벗긴 새우 (750g/원산지 별도표기) 15,920원

    L.POINT 20%
    광어연어 모둠초밥 (16입/팩/원산지 별도표기) 15,920원
    ※입점점에 한함

    L.POINT 30%
    한우 국거리/불고기(1등급, 1++(9)등급) (각 100g/냉장/국내산 한우고기) 
    ※조기 품절될 수 있습니다

    L.POINT 최대 50%
    청정와규 간편구이 모음전 와규 5초구이/야끼니꾸/자이언트 육전
    (품목별 상이/냉동/호주산)

    L.POINT 3,000원
    닭볶음탕용 (1.2kg/냉장/국내산) 7,990원
    ※제주점 제외

    2판 이상 각1,000원 할인
    행복생생란(대란/30구/국산) 2판 이상 구매시 1판당 각5,990원
    ※기간 : 11/14(목) ~ 11/17(일)    
    ※1판 구매시 6,990원

    당진 해나루쌀 (10kg/국산) 29,900원

    L.POINT 3,000원
    닭볶음탕용 (1.2kg/냉장/국내산) 7,990원
    ※제주점 제외

    L.POINT 5,000원
    철판에 구운 진미채 (250g/원산지 별도표기) 9,900원

    2개 이상 각3,500원 할인
    HBAF 시즈닝 믹스넛 3종(원산지 별도표기) 2개 이상 구매시 1개당 각4,990원
    ※ 호두강정 20g×10입/메이플 피칸 아몬드 20g×10입/마카다미아 치즈 40g×5입 ※교차구매 가능
    ※1개 구매시 각 8,490원

    ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
    ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
    반값 알뜰 초밥(20입) (1팩/원산지 별도표기) 9,990원

    네가지맛 닭강정 (1팩/원산지 별도표기) 10,990원

    L.POINT 25%
    해물 양장피 (1팩/원산지 별도표기) 14,993원

    특대 통족발 (1팩/원산지 별도표기) 19,800원
    ※기간 : 11/14(목) ~ 11/17(일)
    ※족발 입점점에 한함

    베스트 세트 (1팩/원산지 별도표기) 2,000원 할인 6,900원
    ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함

    세계맥주 5캔 구매시 9,950원
    ※조기 품절될 수 있습니다 ※점별 운영 상품 상이
    ※이미지 연출컷입니다

    캔 당 1,990원 
    세계맥주 Best(상품별 용량 상이) 5캔 구매시 9,950원
    ※1캔 구매시 각 3,000원

    3캔 구매시
    하이볼 골라담기 (상품별 용량 상이) 9,990원
    ※생레몬/라임/청귤 하이볼, 퐁당 하이볼 外 5종
    ※1캔 구매시 각 4,000원

    요리하다 월드뷔페!
    ※52개점 한정 운영
    각양각색 다양한 나라의 문화와 스타일을 맛보다! 셰프의 월드 레시피를 즐겨보세요!

    요리하다 월드뷔페 상품 2개 이상 구매시 오늘좋은 탄산수 라임(500ml) 증정!
    ※한정물량 소진시 행사 자동 종료

    작아도 맛있는 그린키위(개/뉴질랜드산) 10개 구매시 3,000원
    ※기간 : 11/14(목) ~ 11/17(일) ※조기 품절될 수 있습니다
    ※ 1개 구매시 300원

    L.POINT 1,000원
    델몬트 컷팅 파인애플(400g/팩/필리핀산) 4,990원
    ※기간 : 11/15(금) ~ 11/17(일)

    감자 (2kg/박스/국산) 4,990원

    친환경 어린잎 채소 (90g×2팩/국산) 2,990원

    L.POINT 1,000원
    칠레산 블루베리(310g/팩/칠레산) 9,990원
    ※조기 품절될 수 있습니다

    L.POINT 2,000원
    캐나다산 냉동 야생 블루베리 13,990원
    (1.36kg/봉/캐나다산)

    국내산 킹 단호박 (개/국산) 3,990원

    전골용 모듬채소 (430g/팩/국산) 3,990원

    ※정상가란? 2024년 10월 24일(목) ~ 11월 13일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 10월 30일(목) ~ 11월 13일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

    ※전단적용기간 : 2024. 11. 14(목) ~ 11. 27

  </div>
  `,
  `
  <div>
    냉장/냉동 식품

    롯데/신한/NH농협/삼성카드 3개 이상 각1,980원 할인
    오뚜기 콤비네이션/불고기 피자 (415g/396g) 해당카드로 3개 이상 구매시 1개당 각4,000원
    ※기간 : 11/14(목)~11/17(일) ※교차구매 가능
    ※1개 구매시 각5,980원

    풀무원 통째로 통치즈 만두/통새우 만두(각 630g) 각10,980원

    CJ 육공육 스모크소시지(260g×2)/안심 비엔나(200g×2) 6,980/7,480원

    한성 크래미(180g×2) 4,980원

    조미대용 식품

    롯데마트 단독
    해찬들 우리쌀 태양초 고추장 2입 기획(500g×2입) 9,980원
    ※우리쌀 매운 태양초 고추장 500g+100% 우리쌀 태양초 고추장 500g
    
    2개 이상 각1,500원 할인
    청정원 파스타소스 11종 (상품별 규격 상이) 2개 이상 구매시 1개당 각4,480~5,480원
    ※교차구매 가능
    ※1개 구매시 각5,980~6,980원

    L.POINT 1,000원
    롯데마트 단독
    참치액을 넣어 감칠맛을 더욱 살린 동원 참치!
    동원참치 참치액 ADDED (135g×6입) 14,980원

    L.POINT 5,000원
    씨제이 스팸 클래식(120g×5입) 9,980원
    ※기간 : 11/14(목)~11/17(일)

    유제품/음료
    2개 이상 20%
    제주 삼다수그린 (500ml×20입/2L×6입) 2개 이상 구매시 1개당 각5,184/7,680원
    ※제주점 행사 상이
    ※1개 구매시 각 6,480/9,600원 ※교차구매 가능

    3개 구매시
    코카콜라/롯데칠성 6입 캔 11종(상품별 상이) 9,900원
    ※교차구매 가능
    ※코카콜라 7종 1개 구매시 각 3,430~4,170원
    ※칠성 4종 1개 구매시 각 5,480원

    L.POINT 2,000원
    롯데마트 단독
    서울 체다 슬라이스 치즈 특별기획(18g×50입) 10,980원

    서울 후레쉬 밀크 기획 (900ml×2입) 500원 할인 4,980원

    롯데/신한/NH농협/삼성카드 1만원 이상 30%
    풀무원 다논 요구르트 전품목
    ※기간 : 11/14(목)~11/20(수)

    기호식품
    크라운제과 과자 전품목 1만 3천원 이상 구매시 칠성사이다 제로(1.5L) 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉪ "를 확인하세요
    크라운 참크래커 2번들(560g)/크라운 빅파이 영동포도(18g×18입) 6,080/4,180원

    L.POINT 900원
    삼립 호빵 8입 4종(각 90g×8입) 7,880/8,380원
    ※단팥/단팥&고구마/단팥&야채/단팥&피자

    롯데웰푸드 정년이 온팩상품 1만 5천원 이상 구매시 호빵 그립톡 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉣ "를 확인하세요
    롯데 마가렛트 오리지널(352g)/롯데 빅단팥빵 3입(300g) 4,990/3,380원

    네슬레 코리아(스타벅스 커피) 전품목 2만 5천원 이상 구매시 스타벅스 홀리데이노트(줄무늬) 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1개)
    ※전점 5,000개 한정 ※구분자 " # "를 확인하세요
    ※점포별 사은품 한정 수량 배분 ※점포별 입고 상품 상이
    스타벅스 홀리데이 미디엄/다크 로스트 머그 기획(각 1.1g×90입) 각31,490원

    농심
    농심 라면 전품목 1만원 이상 구매시 신라면 리유저블백 증정!
    ※기간 : 11/14(목)~11/27(수)
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1개)
    ※구분자 ' ㉡ '를 확인하세요 ※전점 1만개 한정
    2개 이상 10%
    농심 신라면/얼큰한 너구리(각 5개입) 2개 이상 구매시 1개당 각3,510/3,762원
    ※1개 구매시 각3,900/4,180원 ※교차구매 가능

    오뚜기
    오뚜기 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 ' ㉧ '를 확인하세요 ※결제시 계산대에서 수령
    2개 이상 50%
    오뚜기 마르게리타/콘치즈/스파이시마요 포테이토 피자(상품별 규격 상이) 2개 이상 구매시 1개당 각4,990원
    ※1개 구매시 각9,980원 ※교차구매 가능

    대상주식회사 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 ' ㉢ '를 확인하세요 ※결제시 계산대에서 수령
    1+1
    종가 맛김치(800g)/청정원 씨간장숙성 양조간장(1.7L)/100% 국산 고추장(1kg) 14,900/14,980/19,800원
    ※동일상품에 한함

    밥 말아 먹으면 더 맛있는 롯데 단독 라면!
    L.POINT 500원
    팔도&양반 미역국라면(4개입) 3,480원

    1+1
    동원 양반 백합 미역국 (460g) 5,480원

    요리하다 후라이드 바사삭 치킨(400g)/허니 바사삭 치킨 (375g) 1,000원 할인 각4,990원

    오늘좋은 꿀유자차/꿀레몬차/생강차(각 1kg) 1,500원 할인 각4,990원

    ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
    ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
    2개 이상 50%
    살림 안정 물가 안정 인기 생필품
    테크 액체세제 전품목(상품별 상이) 2개 이상 구매시 1개당 각4,950~9,900원
    ※기간 : 11/14(목)~11/17(일)
    ※교차구매 가능 ※공구핫딜/시트/캡슐세제 제외
    ※1개 구매시 각9,900~19,800원

    2개 이상 50%
    다우니 섬유유연제 13종(상품별 상이) 2개 이상 구매시 1개당 각 8,500~11,650원
    ※교차구매 가능 ※롯데마트몰 제외
    ※1개 구매시 각17,000~23,300원

    2개 이상 20%
    울세제 전품목(상품별 상이) 2개 이상 구매시 1개당 각4,400~7,920원
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각5,500~9,900원

    2개 이상 50%
    홈스타 전품목(상품별 상이) 2개 이상 구매시 1개당 각2,950~5,450원
    ※해당상품에 한함
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각5,900~10,900원

    2개 이상 50%
    자연퐁 11종(상품별 상이) 2개 이상 구매시 1개당 각3,950~9,900원
    ※해당상품에 한함
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각7,900~19,800원

    2개 이상 50%
    깨끗한나라 순수한면 생리대 2만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉦"를 확인하세요 ※결제시 계산대에서 수령
    순수한면 제로 울트라날개/입는 오버나이트(상품별 상이) 2개 이상 구매시 1개당 각4,000~7,950원
    ※교차구매 가능
    ※1개 구매시 각8,000~15,900원

    2개 이상 50% 
    마미포코 기저귀 3만원 이상 구매시 5천원 롯데상품권 증정!
    ※기간 : 11/14(목)~11/27(수) ※브랜드별 합산불가
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉲"를 확인하세요 ※결제시 계산대에서 수령
    마미포코 하이퍼드라이/슈퍼컴포트 6종(상품별 상이) 2개 이상 구매시 1개당 각13,950~14,950원
    ※1개 구매시 각27,900~29,900원 ※교차구매 가능

    롯데/신한/NH농협/삼성카드 60%
    크리넥스 순수 소프트 3겹 화장지(35m×30롤) 15,560원

    2개 이상 50% 
    깨끗한나라 페퍼민트/퓨어 물티슈(상품별 상이) 2개 이상 구매시 1개당 각6,950원
    ※교차구매 가능
    ※1개 구매시 각13,900원

    2개 이상 55% 
    히말라야 핑크솔트 클렌징 폼 2종 (각 200ml) 2개 이상 구매시 1개당 각4,950원
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각11,000원

    VT 전품목 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 ' V 를' 확인하세요 ※결제시 계산대에서 수령

    VT 시카 바이탈 에센스 기획 (100ml+리들샷 2ml×2입) 23,900원

    아리얼 세븐데이즈 마스크팩 레몬C/알로에H(각 10매) 각7,500원
    ※구/신형 패키지 혼용 운영(점포별 상이)

    밀크바오밥 딥 데미지 샴푸/딥 퍼퓸 바디워시(각 1,200ml) 각9,900/8,900원
    ※화이트머스크/은방울꽃 비누 향 ※점별 운영상품 상이

    센소다인 화이트 치약 기획세트 (치약 2개+칫솔 3개) 13,900원
    ※점별 재고 수량 상이
    ※창립기획 한정수량

    아이깨끗해 기획 (각 용기 250ml+리필 200ml×2입) 2,000원 할인 각6,490원
    ※레몬/순 ※점별 운영상품 상이

    아모레퍼시픽×종근당건강 행사상품 3만 5천원 이상 구매시 스페셜 3종 키트 증정!
    ※기간 : 11/14(목)~11/27(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1개)
    ※구분자 ' ♥ '를 확인하세요 ※한정수량으로 소진시 조기종료
    ※해당상품에 한함 ※점별 입점상품 상이

    미쟝센 퍼펙트세럼 샴푸/컨디셔너/트리트먼트 6종 각7,900원
    (상품별 상이) ※점별 운영상품 상이

    해피바스 인기 바디워시&로션 7종 (상품별 상이) 각5,900원
    ※점별 운영상품 상이

    1+1
    닥터메디안 멀티케어 치약 (120g×3입) 8,900원

    L.POINT 3,000원
    아임비타 이뮨플러스 12+3 기획 (23.5g×15입) 22,800원
    
    2+1
    락토핏 당케어 60포 (2g×60포) 27,900원

    프릴 전품목 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 ' ㉬ '를 확인하세요 ※결제시 계산대에서 수령

    2개 이상 50%
    프릴 전품목(용기 700ml/리필 1L) 2개 이상 구매시 1개당 각4,750원
    ※교차구매 가능 ※해당상품에 한함 ※점별 운영상품 상이
    ※1개 구매시 각9,500원

    화장품 3만원 이상 구매시 건강기능식품 5% 추가 할인
    3만원 이상 구매시 건강기능식품 화장품 5% 추가 할인
    ※기간 : 11/14(목)~11/17(일)
    ※점별 입점점/단일 브랜드/당일 영수증에 한함
    ※참여 브랜드 매장 내 안내문 참고

    ※정상가란? 2024년 10월 24일(목) ~ 11월 13일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

    ※전단적용기간 : 2024. 11. 14(목) ~ 11. 27(수)

  </div>
  `,
  `
  <div>
    테팔 해당상품 최대 50% 할인
    테팔 스타트이지 프라이팬(상품별 상이) 40% 24,480~33,480원

    인텐시움 통3중/티타늄 블라썸 냄비(상품별 상이) 40% 26,280~37,680원

    시큐어 트랜디 압력솥(2L) 50% 43,400원

    크린랩 인기상품 최대 50%/2+1기획팩
    2개 이상 50% 
    크린롤백(각 200매, 미니/중/대) 2개 이상 구매시 1개당 각2,045/3,045/4,045원
    ※1개 구매시 각4,090/6,090/8,090원

    2+1 기획팩
    크린랩/크린장갑 2+1기획팩(22cm×50m/100매) 각10,980/10,180원

    크린랩 간편 물걸레 청소포 (표준형/대형, 각 40매) 30% 3,000/3,490원 
    ※입점점에 한함 ※해당상품에 한함
    ※매장에 더 많은 상품이 준비되어 있습니다

    겨울시즌 보온용품 최대 20% 할인
    겨울시즌 가전, 보온시트(상품별 상이) 최대 20% 4,880~78,400원

    전기요(싱글 105×180cm/더블 135×180cm) 20% 55,920~71,920원
    ※입점점에 한함 ※해당상품에 한함

    쿡셀 전품목 5만원 이상 구매시 10,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수) ※브랜드별 합산불가
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※일부품목제외 ※결제시 계산대에서 수령
    ※구분자 "◆"를 확인하세요
    쿡셀 브랜드(상품별 상이) 20% 31,120~50,240원

    글라스락 브랜드 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수) ※브랜드별 합산불가
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉺"를 확인하세요 ※결제시 계산대에서 수령
    L.POINT 최대 50%
    글라스락 브랜드(상품별 상이) 각2,450~15,950원

    2개 이상 40%
    오늘좋은 스웨트셔츠/긴팔티셔츠/조거팬츠/레깅스 外 2개 이상 구매시 1개당 각5,940~13,140원
    ※입점점에 한함 ※해당상품에 한함※교차구매 가능
    ※기간 : 11/14(목)~11/17(일)
    ※1개 구매시 각9,900~21,900원

    2개 이상 30%
    오늘좋은 히트 성인/아동 동내복, 양말 2개 이상 구매시 1개당 각2,730/11,130원
    ※입점점에 한함 ※교차구매 가능
    ※1개 구매시 각3,900~15,900원

    쿡셀 브랜드(상품별 상이) 20% 31,120~50,240원

    L.POINT 최대 50%
    글라스락 브랜드(상품별 상이) 각2,450~15,950원

    2개 이상 30%
    비비안 스타킹 2개 이상 구매시 1개당 2개 이상 구매시 1개당
    ※입점점에 한함 ※교차구매 가능
    ※1개 구매시 각2,900~7,900원

    오늘좋은 발이 따뜻한 거실화 (핑크 250mm/그레이 280mm) 20% 10,320원
    ※입점점에 한함 ※해당상품에 한함

    2개 이상 20%
    브랜드 양말(상품별 상이) 2개 이상 구매시 1개당 각7,920~8,720원
    ※뉴발란스, 언더아머, 휠라, 네파, MLB 등
    ※1개 구매시 각 9,900~10,900원
    ※교차구매 가능 ※점별 상품 상이
    ※기간 : 10/31(목)~11/27(수)

    글로벌 1위 프랑스 멀티 스포츠용품 스토어
    세상의 모든 스포츠, 데카트론!
    "Move People Through the wonders of sport"
    스포츠의 경이로움으로 사람들을 움직인다

    러닝 모자 1,000원 할인 5,900원 

    러닝 보온 헤드밴드 8,900원

    러닝 터치스크린 장갑 웜 플러스V2 1,000원 할인 8,900원

    러닝 스마트폰 벨트 베이직2 1,100원 할인 12,900원

    남성 러닝 반집업/여성 반집업 긴팔 티셔츠 런 웜100 각2,000원 할인 각17,900원

    11/20 수 세계 어린이의 날
    어린이를 위한 필수 & 추천 아이템

    하기스 기저귀 8만원 이상 구매시 10,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/20(수) ※브랜드별 합산불가
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉻"를 확인하세요 ※결제시 계산대에서 수령
    2개 이상 50%
    하기스 네이처메이드/맥스드라이 19종(상품별 상이) 2개 이상 구매시 1개당 각15,300~22,950원
    ※1개 구매시 각30,600~45,900원 ※교차구매 가능
    ※기간 : 11/14(목)~11/20(수)

    베이비페어 25대 브랜드 롯데/신한/NH농협/삼성카드 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수) ※브랜드별 합산불가
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉥"를 확인하세요 ※결제시 계산대에서 수령
    메디안 키즈 365 Days(3단계) (12입) 1,400원 할인 11,900원
    ※점별 재고 상이

    슈팅스타 티니핑 미용티슈 (230매×6입) 7,650원

    아토마일드 빅물티슈(120매×6입) 9,900원

    1+1
    인기 이유식/유아간식 54종 (상품별 상이) 1,500~5,400원
    ※동일브랜드/동일 매가 간 교차구매 가능

    레고 Lamborghini Lambo V12 VisionGT(76923) 7,000원 할인 27,900원
    (10세 이상/26.2×7.2×14.1cm)

    슈팅스타 무빙 스타하츄핑 外 피규어 11종(3세이상/상품별 상이) 12,900~29,900원
    ※입점점에 한함 ※점별 재고 상이

    스위치 OLED 모여봐요 동물의 숲 세트 + 스위치 모여봐요 동물의 숲 해피홈 파라다이스
    동시구매시 15,000원 할인
    15,000원 할인 425,000원
    ※스위치 OLED 모여봐요 동물의숲 415,000원
    ※ 스위치 모여봐요 동물의숲 해피홈 파라다이스 25,000원
    ※전체이용가 ※입점점에 한함 ※점별 재고 상이

    ※정상가란? 2024년 10월 24일(목) ~ 11월 13일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const zettaSeoulData = [
  `
  <h1>LOTTE Mart ZETTAPLEX</h1>
  <div>
    ·전단적용기간 : 2024. 11. 14(목) ~ 2024. 11. 27(수) < 신선식품 적용기간 : 2024. 11. 14(목) ~ 11. 20(수) >
    ·제타플렉스 서울역점

    코리아 세일 페스타 11.9. ~ 11.30.

    국가대표 쇼핑축제
    ZETTAPLEX SEOUL STATION가 준비한 고객감사 대축제 Again 땡큐절

    땡 잡았다!
    1 하루ㆍ주말 특가
    2 1+1ㆍ최대 50%
    3 겨울 먹거리&아이템 득템찬스

    국내산 한돈 삼겹살 반값!
    ※제주점 제외 ※조기 품절될 수 있습니다 ※페이지 하단 카드할인 세부내용 참고

    롯데/신한/NH농협/삼성카드 50%
    한돈 삼겹살 (100g/냉장/국내산 돼지고기) 1,490원

    롯데/신한/NH농협/삼성카드 40%
    한돈 목심 (100g/냉장/국내산 돼지고기) 1,788원

    롯데/신한/NH농협/삼성카드 10% 
    수산대전 20%
    양준혁 대방어회 (300g 내외/냉장/대방어 : 국산) 36,000원
    ※기간 : 11/14(목) ~ 11/20(수) ※9kg 이상 대방어만 사용합니다
    ※해당상품에 한함 ※입점점에 한함 ※산지시황에 따라 조기품절될 수 있습니다
    ※L.POINT 회원 기간 중 1인 주차별 최대 1만원 할인
    ※이미지 연출컷입니다 ※페이지 하단 카드할인 세부내용 참고

    뗑큐 주말 특가
    기간 : 11/14(목) ~ 11/17(일)

    11/16 토 11/17 일
    L.POINT 최대 50%
    청정와규 윗등심/호주산 살치살 (각100g/냉장/호주산) 3,990/3,990원

    L.POINT 30%
    내가 만드는 연어(횟감용/구이용) (각 100g/냉장/노르웨이산) 3,990/3,920원

    L.POINT 20%
    볼케이노 봉구이 (1팩/원산지 별도표기) 7,992원
    ※기간 : 11/15(금) ~ 11/17(일), 3일간

    1+1
    코카콜라 외 탄산음료 PET 6종 (1.2L~1.25L) 각3,280~3,480원
    ※동일 브랜드 간 교차구매 가능

    L.POINT 3,000원 할인 -> 롯데/신한/NH농협/삼성카드 2,000원 할인
    오뚜기 맛있는밥 12입 (210g×12입) 7,980원
    ※1일 1회, 1인당 4개 한정
    ※페이지 하단 카드할인 세부내용 참고

    1+1
    서울 유기농우유 (700ml) 4,980원

    1+1
    서울 트리플 슈레드치즈 (1kg) 17,980원
    ※10,000개 한정수량

    3개 골라담기
    크라운 비스켓/파이 6종 9,900원
    (상품별 용량 상이) ※교차구매 가능
    ※오프라인 한정 진행 ※1인 1일 1회, 9개 한정
    ※1개 구매시 각 3,840~4,780원

    L.POINT 10,000원 할인
    닥터비데 센서티브 캡형 (80매×4입) 6,900원

    50%
    도루코 퓨어우드 프라이팬 각16,450~21,950원

    50%
    도루코 주방가위/식과도16종 각3,450~17,950원

    땡큐 하루 특가
    11/14 목
    2팩 이상 50%
    양념 돼지 왕구이 (800g/팩/원산지 별도표기) 2팩 이상 구매시 1팩당 각9,950원
    ※1팩 구매시 각 19,900원
    ※해당일 외 : L.POINT 40% 11,940원

    11/15 금
    L.POINT 1,000원 할인
    대추방울토마토 (1kg/박스/국산) 9,990원
    ※일 10,000박스 한정

    1+1
    상온 반찬 전품목 28종(상품별 규격 상이) 각1,480~4,980원
    ※종가집 오징어채볶음 60g 外 27종
    ※동일 브랜드 내 동일 가격 간 교차구매 가능

    11/16 토
    하림/참프레 냉장 닭가슴살 (100g/냉장/원산지 별도표기) 890원
    ※제주점 제외

    11/17 일
    롯데/신한/NH농협/삼성카드 2개 이상 50%
    물/찐/군만두 22종(상품별 규격 상이) 해당카드로 2개 이상 구매시 1개당 각3,490~5,490원
    ※교차구매 가능
    ※점별 운영상품 상이 ※페이지 하단 카드할인 세부내용 참고
    ※1팩 구매시 각 6,980~10,980원

    1+1 각1,490~4,180원
    호떡/핫케익 믹스 9종(상품별 용량 상이)
    ※동일 브랜드 내 동일 가격 간 교차구매 가능
    ※오뚜기 핫케익가루 外 8종

    ※정상가란? 2024년 10월 24일(목) ~ 11월 13일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
  `
  <div>
    땡 잡았다! 하나 사면 하나 더 1+1

    1+1
    마늘치킨 훈제슬라이스 (400g/원산지 별도표기) 8,990원

    1+1
    냉동 데친 바지락살 (300g/냉동/원산지 별도표기) 9,900원

    1+1
    CJ 명가김 2종(각 4g×12봉/원산지 별도표기) 각6,980원
    ※재래/파래 ※교차구매 가능

    1+1
    동원 양반 김부각 6종 (상품별 상이) 각4,470원
    ※교차구매 가능

    1+1
    HBAF 시즈닝 아몬드 6종 (각 120g, 원산지 별도표기) 각4,990원
    ※허니버터 아몬드 外 ※교차구매 가능

    1+1
    사각/국탕용 어묵 5종(상품별 상이) 각4,480~9,980원
    ※동일 브랜드간 교차구매 가능

    1+1
    풀무원 해물 수제비/칼국수(각 2인) 각7,480원
    ※기간 : 11/14(목)~11/20(수) ※교차구매 가능

    1+1
    진주햄 천하장사 오리지널/치즈 각7,980원
    (각 448g) ※교차구매 가능

    1+1
    한만두 탱글한 통새우만두(200g) 6,980원 

    1+1
    히밥 어묵쏙만두 누들떡볶이/고래사 어묵떡볶이/롯데 쉐푸드 우유미니 핫도그(상품별 규격 상이) 각6,600~10,980원
    ※동일상품에 한함

    1+1
    하림 누룽지 순살치킨/치킨텐더 (각 400g) 각9,980원
    ※교차구매 가능

    1+1
    직소싱 포도씨/해바라기 오일 4종(각 1L) 각7,900~16,900원
    ※동일 가격 상품간 교차구매 가능

    1+1
    해찬들 찍장 쌈장/매콤쌈장 (각 300g) 각4,100원
    ※교차구매 가능

    1+1
    떠먹는 요구르트 15종(각 80g×4입/85g×4입) 각3,780~6,480원
    ※빙그레/남양/매일 ※동일 브랜드 內 동일가격 교차구매 가능

    1+1
    남양 슈레드 모짜렐라 피자치즈(200g) 5,980원

    1+1
    제주우유 A2 유기농/저지/무항생제 우유 (각 750ml) 각4,980원
    ※교차구매 가능

    1+1
    동원 인포켓치즈(200g) 14,000원
    ※기간 : 11/14(목)~11/20(수) ※8,000개 한정수량

    1+1
    상온 커피 음료(상품별 상이) 각1,780~3,980원
    ※동일 시리즈간 교차구매 가능 ※일부상품 제외

    1+1
    돈시몬 착즙주스 5종 (각 1L) 각6,980~8,980원
    ※오렌지/만다린/홍자몽/적포도/토마토
    ※동일 가격간 교차구매 가능

    1+1
    대용량 곡물 차음료 전품목(각 1.35L~1.5L) 각 2,380원
    ※동일 시리즈간 교차구매 가능

    1+1
    삼육두유 전품목(상품별 상이) 각2,880~24,900원
    ※동일 가격간 교차구매 가능

    1+1
    롯데마트 단독
    유자레몬사이다(500ml) 1,980원

    1+1
    리치 켄주 야채크래커 (192g) 5,980원

    1+1
    미니 초코 크리스피 (175g) 6,980원

    1+1
    동서 포스트 오곡코코볼(570g) 6,480원

    1+1
    구카 누가 크래커 2종(각 400g) 각9,980원
    ※오리지널/솔티커피 ※교차구매 가능

    1+1
    려 발효영양 샴푸/컨디셔너(상품별 상이) 각11,800원
    ※모발/두피 ※교차구매 가능 ※점별 운영상품 상이

    1+1
    케라시스 케라틴본드 4종(각 750ml) 각12,900원
    ※교차구매 가능 ※점별 운영상품 상이

    1+1
    액츠 리필 3종 각9,900원
    (딥클린, 베이킹 2L/실내건조 2.2L) ※교차구매 가능

    ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
    ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
    땡 잡았다! 최대 50%

    L.POINT 50%
    창녕양파 품은 한우불고기 (700g/냉장/원산지 별도표기) 14,900원

    L.POINT 50%
    궁 쇠고기육포 오리지널, 골든올리브 (각 100g/원산지 별도표기) 각7,450원
    
    2개 이상 50%
    사조대림 떡볶이 3종(상품별 상이) 2개 이상 구매시 1개당 각2,490원
    ※국물/매운/짜장 ※교차구매 가능
    ※1개 구매시 각4,980원

    2개 이상 50%
    마니커 바사삭 돼지후라이드/고추순살(각 300g) 2개 이상 구매시 1개당 각4,990원
    ※교차구매 가능
    ※1개 구매시 각9,980원

    2개 이상 50%
    삼립 레디비 단팥/꿀견과 모찌붕어빵 (각 560g) 2개 이상 구매시 1개당 각4,990원
    ※교차구매 가능
    ※1개 구매시 각9,980원

    2개 이상 20%
    롯데 의성마늘 순한 비엔나/프랑크 (180g×2/210g×2) 2개 이상 구매시 1개당 각6,384/7,184원
    ※교차구매 가능
    ※1개 구매시 각7,980/8,980원

    2개 이상 50%
    엉클팝 길쭉이 찹쌀과자/보리과자 (상품별 상이) 2개 이상 구매시 1개당 각2,995/3,495원
    ※점별 운영물량 상이 ※교차구매 가능
    ※1개 구매시 각5,990/6,990원

    2개 이상 55%
    엘라스틴 퍼퓸 샴푸/컨디셔너 3종(각 1,100ml) 2개 이상 구매시 1개당 각6,705원
    ※로즈/라벤더 ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각14,900원

    2개 이상 50%
    크리넥스 안심클래식 키친타월(145매×8입) 2개 이상 구매시 1개당 각4,850원
    ※1개 구매시 9,700원

    입는 생리대 3만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ● "를 확인하세요 ※결제시 계산대에서 수령
    2개 이상 50%
    좋은느낌/화이트 입는 생리대 12종(상품별 상이) 2개 이상 구매시 1개당 각6,700~8,450원
    ※1개 구매시 각13,400~16,900원 ※교차구매 가능

    L.POINT 50%
    해피콜 루시드 프라이팬(상품별 상이) 17,450~26,950원

    L.POINT 50%
    락앤락 익스트롱 프라이팬(상품별 상이) 15,450~18,450원

    2개 이상 50%
    오늘좋은 세탁이 편한 빨아쓰는 경추베개(소 40×60cm/대 50×70cm) 2개 이상 구매시 1개당 각10,950/14,950원
    ※입점점에 한함 ※해당상품에 한함 ※교차구매 가능
    ※1개 구매시 각21,900/29,900원

    2개 이상 50%
    온더바디 리얼모이스처 바디워시 3종(각 900g) 2개 이상 구매시 1개당 각4,950원
    ※웜코튼/피오니/코코넛아몬드향
    ※1개 구매시 각9,900원
    ※교차구매 가능

    땡 잡았다! 겨울 먹거리&아이템 득템찬스

    수산물 할인 바다!
    2024 대한민국수산대전

    해양수산부와 함께하는 수산대전 20%
    ※기간 : 11/14(목) ~ 11/27(수) ※해당상품에 한함
    ※산지시황에 따라 조기품절될 수 있습니다
    ※L.POINT 회원 기간 중 1인 주차별 최대 1만원 할인 ※이미지 연출컷입니다

    롯데/신한/NH농협/삼성카드 10% -> 수산대전 20%
    남해안 생굴(100g/냉장/국산)/대용량 박스굴(1kg) 2,124/19,440원
    ※점별 취급 상이
    ※페이지 하단 카드할인 세부내용 참고
    
    상생사과(4~7입/봉/국산)/엔비사과(4~6입/봉/국산) 9,990/11,990원

    2+1
    담터 생강차/쌍화차 플러스(각 50입) 각13,500원
    ※교차구매 가능

    오늘좋은 밍크터치 극세사 이불 (S 150×200cm/Q180×220cm) 30% 각46,130/55,930원

    2개 이상 50%
    하루 핸드워머/바디워머 (상품별 상이) 2개 이상 구매시 1개당 각4,950~5,450원
    ※교차구매 가능
    ※1개 구매시 각9,900~10,900원

    2개 이상 30%
    오늘좋은 밍크스판/수면 홈웨어 (상품별 상이) 2개 이상 구매시 1개당 각9,730~17,430원
    ※입점점에 한함 ※교차구매 가능
    ※1개 구매시 각13,900~24,900원

    ※정상가란? 2024년 10월 24일(목) ~ 11월 13일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

    ※전단적용기간 : 2024. 11. 14(목) ~ 11. 27(수)

  </div>
  `,
  `
  <div>
    ·신선식품 적용기간 : 2024. 11. 14(목) ~ 11. 20(수)

    2024년 김장준비는 롯데마트에서!
    ※L.POINT 회원 기간 중 최대 2만원 할인
    ※산지 시황에 따라 조기 품절될 수 있습니다

    농할할인 20%
    배추(3입/망/국산) 5,880원
    ※기간 : 11/14(목) ~ 11/16(토)

    농할할인 20%
    다발무(1단/국산) 5,992원

    홍갓/청갓(각 1단/국산) 각2,990원

    L.POINT 3,000원
    김장용 햇추젓 (1kg/냉장/새우,천일염:국산) 13,900원

    농할할인 20%
    깐마늘(1kg/봉/국산) 7,192원

    농할할인 20%
    깐쪽파(200g/봉/국산) 3,192원

    바나나 1송이 2,990원
    ※조기 품절될 수 있습니다
    ※이미지 연출컷입니다

    스위트마운틴 바나나 (1.2kg 내외/송이/에콰도르산) 2,990원

    상생 대봉시 4입 1팩 4,990원
    ※조기 품절될 수 있습니다
    ※이미지 연출컷입니다

    상생 대봉시 (4입/팩/국산) 4,990원

    해양수산부와 함께하는 수산대전
    ※조기 품절될 수 있습니다
    ※이미지 연출컷입니다

    L.POINT 최대 25%
    완도 활 전복(중/왕) (각 1마리/냉장/국산) 1,800/4,250원

    롯데/신한/NH농협/삼성카드 20% -> 수산대전 20%
    고등어자반(특대) (1손 2마리/냉장/고등어,천일염:국산) 3,834원
    ※L.POINT 회원 기간 중 1인 주차별 최대 1만원 할인
    ※페이지 하단 카드할인 세부내용 참고

    인기 수산물 L.POINT 20%
    ※조기 품절될 수 있습니다 ※점별 운영 상품 상이
    ※이미지 연출컷입니다

    L.POINT 20%
    제주 생물갈치 (마리/특대/국산) 12,720원
    ※ 산지 시황에 따라 조기 품절될 수 있습니다

    L.POINT 20%
    직소싱 껍질벗긴 새우 (750g/원산지 별도표기) 15,920원

    L.POINT 20%
    광어연어 모둠초밥 (16입/팩/원산지 별도표기) 15,920원
    ※입점점에 한함

    L.POINT 30%
    한우 국거리/불고기(1등급, 1++(9)등급) (각 100g/냉장/국내산 한우고기) 
    ※조기 품절될 수 있습니다

    L.POINT 최대 50%
    청정와규 간편구이 모음전 와규 5초구이/야끼니꾸/자이언트 육전
    (품목별 상이/냉동/호주산)

    L.POINT 3,000원
    닭볶음탕용 (1.2kg/냉장/국내산) 7,990원
    ※제주점 제외

    2판 이상 각1,000원 할인
    행복생생란(대란/30구/국산) 2판 이상 구매시 1판당 각5,990원
    ※기간 : 11/14(목) ~ 11/17(일)    
    ※1판 구매시 6,990원

    당진 해나루쌀 (10kg/국산) 29,900원

    L.POINT 3,000원
    닭볶음탕용 (1.2kg/냉장/국내산) 7,990원
    ※제주점 제외

    L.POINT 5,000원
    철판에 구운 진미채 (250g/원산지 별도표기) 9,900원

    2개 이상 각3,500원 할인
    HBAF 시즈닝 믹스넛 3종(원산지 별도표기) 2개 이상 구매시 1개당 각4,990원
    ※ 호두강정 20g×10입/메이플 피칸 아몬드 20g×10입/마카다미아 치즈 40g×5입 ※교차구매 가능
    ※1개 구매시 각 8,490원

    ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
    ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
    반값 알뜰 초밥(20입) (1팩/원산지 별도표기) 9,990원

    네가지맛 닭강정 (1팩/원산지 별도표기) 10,990원

    L.POINT 25%
    해물 양장피 (1팩/원산지 별도표기) 14,993원

    특대 통족발 (1팩/원산지 별도표기) 19,800원
    ※기간 : 11/14(목) ~ 11/17(일)
    ※족발 입점점에 한함

    베스트 세트 (1팩/원산지 별도표기) 2,000원 할인 6,900원
    ※베이커리팩토리/쁘띠르뽀미에 입점점에 한함

    세계맥주 5캔 구매시 9,950원
    ※조기 품절될 수 있습니다 ※점별 운영 상품 상이
    ※이미지 연출컷입니다

    캔 당 1,990원 
    세계맥주 Best(상품별 용량 상이) 5캔 구매시 9,950원
    ※1캔 구매시 각 3,000원

    3캔 구매시
    하이볼 골라담기 (상품별 용량 상이) 9,990원
    ※생레몬/라임/청귤 하이볼, 퐁당 하이볼 外 5종
    ※1캔 구매시 각 4,000원

    요리하다 월드뷔페!
    ※52개점 한정 운영
    각양각색 다양한 나라의 문화와 스타일을 맛보다! 셰프의 월드 레시피를 즐겨보세요!

    요리하다 월드뷔페 상품 2개 이상 구매시 오늘좋은 탄산수 라임(500ml) 증정!
    ※한정물량 소진시 행사 자동 종료

    작아도 맛있는 그린키위(개/뉴질랜드산) 10개 구매시 3,000원
    ※기간 : 11/14(목) ~ 11/17(일) ※조기 품절될 수 있습니다
    ※ 1개 구매시 300원

    L.POINT 1,000원
    델몬트 컷팅 파인애플(400g/팩/필리핀산) 4,990원
    ※기간 : 11/15(금) ~ 11/17(일)

    감자 (2kg/박스/국산) 4,990원

    친환경 어린잎 채소 (90g×2팩/국산) 2,990원

    L.POINT 1,000원
    칠레산 블루베리(310g/팩/칠레산) 9,990원
    ※조기 품절될 수 있습니다

    L.POINT 2,000원
    캐나다산 냉동 야생 블루베리 13,990원
    (1.36kg/봉/캐나다산)

    국내산 킹 단호박 (개/국산) 3,990원

    전골용 모듬채소 (430g/팩/국산) 3,990원

    ※정상가란? 2024년 10월 24일(목) ~ 11월 13일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다(비신선식품은 2024년 10월 30일(목) ~ 11월 13일(수)) ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

    ※전단적용기간 : 2024. 11. 14(목) ~ 11. 27
    
  </div>
  `,
  `
  <div>
    냉장/냉동 식품

    롯데/신한/NH농협/삼성카드 3개 이상 각1,980원 할인
    오뚜기 콤비네이션/불고기 피자 (415g/396g) 해당카드로 3개 이상 구매시 1개당 각4,000원
    ※기간 : 11/14(목)~11/17(일) ※교차구매 가능
    ※1개 구매시 각5,980원

    풀무원 통째로 통치즈 만두/통새우 만두(각 630g) 각10,980원

    CJ 육공육 스모크소시지(260g×2)/안심 비엔나(200g×2) 6,980/7,480원

    한성 크래미(180g×2) 4,980원

    조미대용 식품

    롯데마트 단독
    해찬들 우리쌀 태양초 고추장 2입 기획(500g×2입) 9,980원
    ※우리쌀 매운 태양초 고추장 500g+100% 우리쌀 태양초 고추장 500g
    
    2개 이상 각1,500원 할인
    청정원 파스타소스 11종 (상품별 규격 상이) 2개 이상 구매시 1개당 각4,480~5,480원
    ※교차구매 가능
    ※1개 구매시 각5,980~6,980원

    L.POINT 1,000원
    롯데마트 단독
    참치액을 넣어 감칠맛을 더욱 살린 동원 참치!
    동원참치 참치액 ADDED (135g×6입) 14,980원

    L.POINT 5,000원
    씨제이 스팸 클래식(120g×5입) 9,980원
    ※기간 : 11/14(목)~11/17(일)

    유제품/음료
    2개 이상 20%
    제주 삼다수그린 (500ml×20입/2L×6입) 2개 이상 구매시 1개당 각5,184/7,680원
    ※제주점 행사 상이
    ※1개 구매시 각 6,480/9,600원 ※교차구매 가능

    3개 구매시
    코카콜라/롯데칠성 6입 캔 11종(상품별 상이) 9,900원
    ※교차구매 가능
    ※코카콜라 7종 1개 구매시 각 3,430~4,170원
    ※칠성 4종 1개 구매시 각 5,480원

    L.POINT 2,000원
    롯데마트 단독
    서울 체다 슬라이스 치즈 특별기획(18g×50입) 10,980원

    서울 후레쉬 밀크 기획 (900ml×2입) 500원 할인 4,980원

    롯데/신한/NH농협/삼성카드 1만원 이상 30%
    풀무원 다논 요구르트 전품목
    ※기간 : 11/14(목)~11/20(수)

    기호식품
    크라운제과 과자 전품목 1만 3천원 이상 구매시 칠성사이다 제로(1.5L) 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉪ "를 확인하세요
    크라운 참크래커 2번들(560g)/크라운 빅파이 영동포도(18g×18입) 6,080/4,180원

    L.POINT 900원
    삼립 호빵 8입 4종(각 90g×8입) 7,880/8,380원
    ※단팥/단팥&고구마/단팥&야채/단팥&피자

    롯데웰푸드 정년이 온팩상품 1만 5천원 이상 구매시 호빵 그립톡 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉣ "를 확인하세요
    롯데 마가렛트 오리지널(352g)/롯데 빅단팥빵 3입(300g) 4,990/3,380원

    네슬레 코리아(스타벅스 커피) 전품목 2만 5천원 이상 구매시 스타벅스 홀리데이노트(줄무늬) 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1개)
    ※전점 5,000개 한정 ※구분자 " # "를 확인하세요
    ※점포별 사은품 한정 수량 배분 ※점포별 입고 상품 상이
    스타벅스 홀리데이 미디엄/다크 로스트 머그 기획(각 1.1g×90입) 각31,490원

    농심
    농심 라면 전품목 1만원 이상 구매시 신라면 리유저블백 증정!
    ※기간 : 11/14(목)~11/27(수)
    ※당일 영수증에 한함
    ※비연속식 증정(1인 1일 1개)
    ※구분자 ' ㉡ '를 확인하세요 ※전점 1만개 한정
    2개 이상 10%
    농심 신라면/얼큰한 너구리(각 5개입) 2개 이상 구매시 1개당 각3,510/3,762원
    ※1개 구매시 각3,900/4,180원 ※교차구매 가능

    오뚜기
    오뚜기 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 ' ㉧ '를 확인하세요 ※결제시 계산대에서 수령
    2개 이상 50%
    오뚜기 마르게리타/콘치즈/스파이시마요 포테이토 피자(상품별 규격 상이) 2개 이상 구매시 1개당 각4,990원
    ※1개 구매시 각9,980원 ※교차구매 가능

    대상주식회사 전품목 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 ' ㉢ '를 확인하세요 ※결제시 계산대에서 수령
    1+1
    종가 맛김치(800g)/청정원 씨간장숙성 양조간장(1.7L)/100% 국산 고추장(1kg) 14,900/14,980/19,800원
    ※동일상품에 한함

    밥 말아 먹으면 더 맛있는 롯데 단독 라면!
    L.POINT 500원
    팔도&양반 미역국라면(4개입) 3,480원

    1+1
    동원 양반 백합 미역국 (460g) 5,480원

    요리하다 후라이드 바사삭 치킨(400g)/허니 바사삭 치킨 (375g) 1,000원 할인 각4,990원

    오늘좋은 꿀유자차/꿀레몬차/생강차(각 1kg) 1,500원 할인 각4,990원

    ※중요표시 광고고시에 따른 상기 상품의 원재료, 혼용율, 가공지역, 품질보증 기간 및 취급시 주의사항은 제품 및 라벨 등에 표시되어 있으며, 공정거래위원회 고시 소비자분쟁해결 기준에 의거 교환 또는 보상받을 수 있습니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다
    ※재활용지로 분류하여 처리해 주십시오 ※전단행사는 해당일 영업시간부터 적용됩니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다 ※전단지 인쇄상 가격이 잘못된 부분은 매장내에서 정정하여 판매하오니 양해바랍니다

  </div>
  `,
  `
  <div>
    2개 이상 50%
    살림 안정 물가 안정 인기 생필품
    테크 액체세제 전품목(상품별 상이) 2개 이상 구매시 1개당 각4,950~9,900원
    ※기간 : 11/14(목)~11/17(일)
    ※교차구매 가능 ※공구핫딜/시트/캡슐세제 제외
    ※1개 구매시 각9,900~19,800원

    2개 이상 50%
    다우니 섬유유연제 13종(상품별 상이) 2개 이상 구매시 1개당 각 8,500~11,650원
    ※교차구매 가능 ※롯데마트몰 제외
    ※1개 구매시 각17,000~23,300원

    2개 이상 20%
    울세제 전품목(상품별 상이) 2개 이상 구매시 1개당 각4,400~7,920원
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각5,500~9,900원

    2개 이상 50%
    홈스타 전품목(상품별 상이) 2개 이상 구매시 1개당 각2,950~5,450원
    ※해당상품에 한함
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각5,900~10,900원

    2개 이상 50%
    자연퐁 11종(상품별 상이) 2개 이상 구매시 1개당 각3,950~9,900원
    ※해당상품에 한함
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각7,900~19,800원

    2개 이상 50%
    깨끗한나라 순수한면 생리대 2만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉦"를 확인하세요 ※결제시 계산대에서 수령
    순수한면 제로 울트라날개/입는 오버나이트(상품별 상이) 2개 이상 구매시 1개당 각4,000~7,950원
    ※교차구매 가능
    ※1개 구매시 각8,000~15,900원

    2개 이상 50% 
    마미포코 기저귀 3만원 이상 구매시 5천원 롯데상품권 증정!
    ※기간 : 11/14(목)~11/27(수) ※브랜드별 합산불가
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉲"를 확인하세요 ※결제시 계산대에서 수령
    마미포코 하이퍼드라이/슈퍼컴포트 6종(상품별 상이) 2개 이상 구매시 1개당 각13,950~14,950원
    ※1개 구매시 각27,900~29,900원 ※교차구매 가능

    롯데/신한/NH농협/삼성카드 60%
    크리넥스 순수 소프트 3겹 화장지(35m×30롤) 15,560원

    2개 이상 50% 
    깨끗한나라 페퍼민트/퓨어 물티슈(상품별 상이) 2개 이상 구매시 1개당 각6,950원
    ※교차구매 가능
    ※1개 구매시 각13,900원

    2개 이상 55% 
    히말라야 핑크솔트 클렌징 폼 2종 (각 200ml) 2개 이상 구매시 1개당 각4,950원
    ※교차구매 가능 ※점별 운영상품 상이
    ※1개 구매시 각11,000원

    VT 전품목 3만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 ' V 를' 확인하세요 ※결제시 계산대에서 수령

    VT 시카 바이탈 에센스 기획 (100ml+리들샷 2ml×2입) 23,900원

    아리얼 세븐데이즈 마스크팩 레몬C/알로에H(각 10매) 각7,500원
    ※구/신형 패키지 혼용 운영(점포별 상이)

    밀크바오밥 딥 데미지 샴푸/딥 퍼퓸 바디워시(각 1,200ml) 각9,900/8,900원
    ※화이트머스크/은방울꽃 비누 향 ※점별 운영상품 상이

    센소다인 화이트 치약 기획세트 (치약 2개+칫솔 3개) 13,900원
    ※점별 재고 수량 상이
    ※창립기획 한정수량

    아이깨끗해 기획 (각 용기 250ml+리필 200ml×2입) 2,000원 할인 각6,490원
    ※레몬/순 ※점별 운영상품 상이

    아모레퍼시픽×종근당건강 행사상품 3만 5천원 이상 구매시 스페셜 3종 키트 증정!
    ※기간 : 11/14(목)~11/27(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1개)
    ※구분자 ' ♥ '를 확인하세요 ※한정수량으로 소진시 조기종료
    ※해당상품에 한함 ※점별 입점상품 상이

    미쟝센 퍼펙트세럼 샴푸/컨디셔너/트리트먼트 6종 각7,900원
    (상품별 상이) ※점별 운영상품 상이

    해피바스 인기 바디워시&로션 7종 (상품별 상이) 각5,900원
    ※점별 운영상품 상이

    1+1
    닥터메디안 멀티케어 치약 (120g×3입) 8,900원

    L.POINT 3,000원
    아임비타 이뮨플러스 12+3 기획 (23.5g×15입) 22,800원
    
    2+1
    락토핏 당케어 60포 (2g×60포) 27,900원

    프릴 전품목 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수)
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 ' ㉬ '를 확인하세요 ※결제시 계산대에서 수령

    2개 이상 50%
    프릴 전품목(용기 700ml/리필 1L) 2개 이상 구매시 1개당 각4,750원
    ※교차구매 가능 ※해당상품에 한함 ※점별 운영상품 상이
    ※1개 구매시 각9,500원

    화장품 3만원 이상 구매시 건강기능식품 5% 추가 할인
    3만원 이상 구매시 건강기능식품 화장품 5% 추가 할인
    ※기간 : 11/14(목)~11/17(일)
    ※점별 입점점/단일 브랜드/당일 영수증에 한함
    ※참여 브랜드 매장 내 안내문 참고

    ※정상가란? 2024년 10월 24일(목) ~ 11월 13일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※KB국민BC/신한BC/NH농협BC카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

    ※전단적용기간 : 2024. 11. 14(목) ~ 11. 27(수)

  </div>
  `,
  `
  <div>
    테팔 해당상품 최대 50% 할인
    테팔 스타트이지 프라이팬(상품별 상이) 40% 24,480~33,480원

    인텐시움 통3중/티타늄 블라썸 냄비(상품별 상이) 40% 26,280~37,680원

    시큐어 트랜디 압력솥(2L) 50% 43,400원

    크린랩 인기상품 최대 50%/2+1기획팩
    2개 이상 50% 
    크린롤백(각 200매, 미니/중/대) 2개 이상 구매시 1개당 각2,045/3,045/4,045원
    ※1개 구매시 각4,090/6,090/8,090원

    2+1 기획팩
    크린랩/크린장갑 2+1기획팩(22cm×50m/100매) 각10,980/10,180원

    크린랩 간편 물걸레 청소포 (표준형/대형, 각 40매) 30% 3,000/3,490원 
    ※입점점에 한함 ※해당상품에 한함
    ※매장에 더 많은 상품이 준비되어 있습니다

    겨울시즌 보온용품 최대 20% 할인
    겨울시즌 가전, 보온시트(상품별 상이) 최대 20% 4,880~78,400원

    전기요(싱글 105×180cm/더블 135×180cm) 20% 55,920~71,920원
    ※입점점에 한함 ※해당상품에 한함

    쿡셀 전품목 5만원 이상 구매시 10,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수) ※브랜드별 합산불가
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※일부품목제외 ※결제시 계산대에서 수령
    ※구분자 "◆"를 확인하세요
    쿡셀 브랜드(상품별 상이) 20% 31,120~50,240원

    글라스락 브랜드 4만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수) ※브랜드별 합산불가
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉺"를 확인하세요 ※결제시 계산대에서 수령
    L.POINT 최대 50%
    글라스락 브랜드(상품별 상이) 각2,450~15,950원

    2개 이상 40%
    오늘좋은 스웨트셔츠/긴팔티셔츠/조거팬츠/레깅스 外 2개 이상 구매시 1개당 각5,940~13,140원
    ※입점점에 한함 ※해당상품에 한함※교차구매 가능
    ※기간 : 11/14(목)~11/17(일)
    ※1개 구매시 각9,900~21,900원

    2개 이상 30%
    오늘좋은 히트 성인/아동 동내복, 양말 2개 이상 구매시 1개당 각2,730/11,130원
    ※입점점에 한함 ※교차구매 가능
    ※1개 구매시 각3,900~15,900원

    2개 이상 30%
    비비안 스타킹 2개 이상 구매시 1개당 각2,030~5,530원
    ※입점점에 한함 ※교차구매 가능
    ※1개 구매시 각2,900~7,900원

    오늘좋은 발이 따뜻한 거실화 (핑크 250mm/그레이 280mm) 20% 10,320원
    ※입점점에 한함 ※해당상품에 한함

    2개 이상 20%
    브랜드 양말(상품별 상이) 2개 이상 구매시 1개당 각7,920~8,720원
    ※뉴발란스, 언더아머, 휠라, 네파, MLB 등
    ※1개 구매시 각 9,900~10,900원
    ※교차구매 가능 ※점별 상품 상이
    ※기간 : 10/31(목)~11/27(수)

    1+1 기획팩
    테이팩스 니트릴장갑 1+1기획팩 (상품별 상이) 각8,900원

    최대 30%
    3M 베이비 핑크 고무장갑/제로스크레치 수세미(10입) 外 1,950~8,790원

    30%
    룸바이홈 럭셔리 호텔 세면타월 4P(네이비/화이트/그레이) 18,130원
    ※입점점에 한함 ※해당상품에 한함
    ※매장에 더 많은 상품이 준비되어 있습니다

    1+1 밴딩
    그라스 디퓨저 (105ml) 24,900원

    1+1 기획팩
    벡셀 알카라인 건전지 (각 10입, AA/AAA) 각9,900원

    반려동물 식품
    최대 30%
    시저/아이엠즈 행사상품 2만원 이상 구매시 5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉠"를 확인하세요 ※결제시 계산대에서 수령
    시저 간식/사료 30종 (상품별 상이) 2,100~10,115원
    ※점별 재고 상이

    11/20 수 세계 어린이의 날
    어린이를 위한 필수 & 추천 아이템
    하기스 기저귀 8만원 이상 구매시 10,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/20(수) ※브랜드별 합산불가
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 " ㉻"를 확인하세요 ※결제시 계산대에서 수령

    2개 이상 50%
    하기스 네이처메이드/맥스드라이 19종(상품별 상이) 2개 이상 구매시 1개당 각15,300~22,950원
    ※1개 구매시 각30,600~45,900원 ※교차구매 가능
    ※기간 : 11/14(목)~11/20(수)

    베이비페어 25대 브랜드 롯데/신한/NH농협/삼성카드 2만원 이상 구매시 5,000원 롯데상품권 증정
    ※기간 : 11/14(목)~11/27(수) ※브랜드별 합산불가
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※구분자 "㉥"를 확인하세요 ※결제시 계산대에서 수령
    메디안 키즈 365 Days(3단계) (12입) 1,400원 할인 11,900원
    ※점별 재고 상이

    슈팅스타 티니핑 미용티슈 (230매×6입) 7,650원

    아토마일드 빅물티슈(120매×6입) 9,900원

    1+1
    인기 이유식/유아간식 54종 (상품별 상이) 1,500~5,400원
    ※동일브랜드/동일 매가 간 교차구매 가능

    레고 Lamborghini Lambo V12 VisionGT(76923) 7,000원 할인 27,900원
    (10세 이상/26.2×7.2×14.1cm)

    슈팅스타 무빙 스타하츄핑 外 피규어 11종(3세이상/상품별 상이) 12,900~29,900원
    ※입점점에 한함 ※점별 재고 상이

    스위치 OLED 모여봐요 동물의 숲 세트 + 스위치 모여봐요 동물의 숲 해피홈 파라다이스
    동시구매시 15,000원 할인 425,000원
    ※스위치 OLED 모여봐요 동물의숲 415,000원
    ※ 스위치 모여봐요 동물의숲 해피홈 파라다이스 25,000원
    ※전체이용가 ※입점점에 한함 ※점별 재고 상이

    ※정상가란? 2024년 10월 24일(목) ~ 11월 13일(수) 롯데마트 중계점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    ※카드할인은 스노우플랜 결제 시 적용되지 않습니다 ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※롯데마트몰 카드행사 상이 ※오프라인 점포에 한함

  </div>
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getAllStoreData = (type) => {
  const dataArray = [];
  allStoreData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/${type === "ZETTA" ? "jamsil_" : ""}${fillZero(
        2,
        String(i + 1)
      )}.jpg`,
      title: `${type === "ZETTA" ? "제타플렉스" : "롯데마트"} 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaData = () => {
  const dataArray = [];
  zettaData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/jamsil_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 잠실점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getZettaSeoulData = () => {
  const dataArray = [];
  zettaSeoulData.map((data, i) => {
    const dataObj = {
      href: LEAFLET_URL.a1,
      imgs: `01/seoul_${fillZero(2, String(i + 1))}.jpg`,
      title: `제타플렉스 서울역점 전단${i + 1}면`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const imgPath = "images/202411_3";
const category = [];

const data = {
  L301: {
    title: "스마트전단지-전점301",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L302: {
    title: "스마트전단지-전점302",
    category: category,
    type: "HYPER",
    isFooter: true,
    imgPath: imgPath,
    contents: getAllStoreData(""),
  },
  L303: {
    title: "스마트전단지-제타플렉스 잠실점303",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L304: {
    title: "스마트전단지-제타플렉스 잠실점304",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaData(""),
  },
  L305: {
    title: "스마트전단지-제타플렉스 서울역점305",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
  L306: {
    title: "스마트전단지-제타플렉스 서울역점306",
    category: category,
    type: "ZETTA",
    isFooter: true,
    imgPath: imgPath,
    contents: getZettaSeoulData(""),
  },
};

export default data;

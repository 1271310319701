//링크
export const LEAFLET_URL = {
  a1: "https://www.lotteon.com/p/display/shop/seltDpShop/12919?mall_no=4",
  a2: "http://toysrus.lottemart.com",
  chu2020: "https://www.lotteon.com/p/display/shop/seltDpShop/29638",
  main: "http://www.lottemart.com",
};

export const IMAGE_PATH = `${process.env.REACT_APP_BASE_S3_IMAGE}/leaflet`;

/* 지난 전단지 reload */
export const leafletState = {
  // openDate는 오픈날짜로 변경(매주 목요일)
  MART: {
    openDate: "20241114",
    thisWeek: [
      201, //전점 GO(~11/13)
      203, //제타플렉스 GO(~11/13)
      758, //맥스 GO(~11/13)

      ////////////////////////////////////
      202, //전점 LMS(~11/13)
      204, //제타플렉스 LMS(~11/13)
      759, //맥스 LMS(~11/13)

      205, //제타플렉스 서울역점 GO(~11/13)
      206, //제타플렉스 서울역점 LMS(~11/13)

      //  작업 x
      601, //고정 (생활공감)
      901, //고정 (룸바이홈)

      // 테스트용 고정
      999,
    ],
    willThisWeek: [
      301, //전점 GO(~11/20)
      303, //제타플렉스 GO(~11/20)
      738, //맥스 GO(~11/27)

      ////////////////////////////////////
      302, //전점 LMS(~11/20)
      304, //제타플렉스 LMS(~11/20)
      739, //맥스 LMS(~11/27)

      305, //제타플렉스 서울역점 GO(~11/20)
      306, //제타플렉스 서울역점 LMS(~11/20)

      //  작업 x
      601, //고정 (생활공감)
      901, //고정 (룸바이홈)

      // 테스트용 고정
      999,
    ],
  },
  // 롯데슈퍼 대표 전단
  // 성수연 담당 요청 (24.03.28) 슈퍼 LMS 추가
  SUPER: {
    openDate: "20241114",
    thisWeek: [
      209, //슈퍼(~11/13)
      210, //슈퍼 LMS (~11/13)
    ],
    willThisWeek: [
      309, //슈퍼(~11/20)
      310, //슈퍼 LMS (~11/20)
    ],
  },
  // 롯데슈퍼 추가 전단(설,명절 등)
  /*SUPER_THEME: {
    openDate: "20230831",
    thisWeek: [505],
    willThisWeek: [105],
  },*/
};

/* 701, 751 빅마켓(~6/12) */
const vicData = [
  `
  <h1>LOTTE VIC Market</h1>
  <div>
    롯데/신한카드 롯데마트&MAXX

    수박/탄산음료/폴로,챔피언 패션잡화브랜드 20%
    추 가 할 인
    
    전용카드
    회원만!
    ▶ 기간 : 5/30(수) ~ 6/12(수)
    ▶ 해당카드 :
    롯데마트&MAXX카드,
    LOCA for 롯데마트카드/롯데마트맥스 신한카드
    ※최종가/엘포인트 할인가에서 추가 할인 적용
    ※MAXX/VIC 매장 한정, 롯데마트몰/SCAN&GO 제외
    ※로카페이, LPAY 및 해당 실물카드 결제 시 적용
    (삼성페이 등 간편결제는 미적용)
    ※할인스티커 부착 상품과 중복적용 불가 ※재고소진시 행사종료
    ※특약(입점)행사/선물세트 제외 ※자세한 사항은 가까운 직원에게 문의

    롯데마트&MAXX카드
    LOCA for 롯데마트카드
    롯데마트맥스 신한카드 적용가

    수박 전품목 20% 추가 할인 12,784~16,784원
    당도선별 수박(6~10kg 미만/통/국산)
    ※점별 운영 중량 상이
    ※ 해당카드 미적용시
    17,980~22,980원
    ※ 롯데/신한카드 결제시
    15,980~20,980원

    롯데마트&MAXX카드
    LOCA for 롯데마트카드
    롯데마트맥스 신한카드 적용가
    20% 추가 할인
    탄산음료 전품목
    (콜라/사이다/혼합탄산음료)
    ※트레비 토닉워터 얼그레이 제외

    롯데마트&MAXX카드
    LOCA for 롯데마트카드
    롯데마트맥스 신한카드 적용가
    20% 추가 할인
    폴로/챔피언 브랜드 의류(성인/아동)
    ※해당카드 미적용시 9,980~79,800원

    61월 한달 내내 이 가격 ※기간 : 6/1(토) ~ 6/30(일)

    3,500원 할인 캐나다산 카무트(2kg/캐나다산) 18,980원
    2,000원 할인  롤링롤링 오징어(150g/원산지 별도표기) 7,980원
  </div>
  `,
  `
  <div>
    3,000원 할인 어포튀각 9,980원
    (400g/원산지 별도표기)

    하림 더 건강한 두마리 치킨 (팩 : 두마리/닭고기 : 국내산) 15,980원

    2,000원 할인 하림 용가리 치킨 9,980원
    (1.2kg)
    
    1,000원 할인 큐원 올리고당 7,380원
    (1.2kg×2입)
    
    1+1 자임 곤약젤리 사과/
    패션후르츠(150g×10입) 각16,980원
    
    1,500원 할인 삼립 뉴 젤리뽀 7,480원
    (2.4kg)
    
    2,500원 할인 유키앤러브 파인애플
    케이크(25g×20입) 13,480원

    롯데카드/신한카드 1,000원 할인 상쾌환(3g×12입) 14,980원
    
    10,000원 할인 블루밍가든 누들볼 29,800원
    (4P)
    
    롯데카드/신한카드 3,000원 할인 싸이닉 엔조이 선에센스 9,980원
    (50ml×2)

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    </div>
  `,
  `
  <div>
    ▶ 기 간 : 5/30(목)~6/12(수)
    시원한 할인가로
    슬기로운 여름 준비!

    롯데카드/신한카드 2,000원 할인 미국산 체리 12,980원
    (900g/팩)
    
    제스프리 한판 골드키위 14,980원
    (12입/박스/뉴질랜드산)

    단 8일간, 핫프라이스!
    롯데카드/신한카드 50% 할인  한우 등심(100g/냉장/국내산) 3,990원
    ※기간 : 5/30(목)~6/6(목) ※2등급

    L.POINT 25& 할인 1등급 한우 국거리/불고기 2,835원
    (각 100g/냉장/국내산)

    L.POINT 25& 할인 호주산 곡물비육 부채살 1,935원
    (100g/냉장/호주산)
    
    L.POINT 25& 할인 캐나다산 돼지 삼겹살/목심 각1,260원
    (각 100g/냉장/캐나다산)
  </div>
  `,
  `
  <div>
    1,000원 할인 청수 물냉면 5,280원
    (720g)
    
    3,000원 할인 풀무원 함흥비빔냉면 3,980원
    2인(460g)

    롯데카드/신한카드 2,000원 할인 티오 아이스티 복숭아맛 17,980원
    (13g×150입)
    
    스페쇼우 젤리스트로우
    플러스(1,254g) 10,980원

    4,020원 할인 트레비 토닉워터
    얼그레이 3,960원

    2,000원 할인 티젠 콤부차 매실 10,980원
    (5g×30입)

    롯데카드/신한카드 35% 할인 캐나다 활 랍스터 12,935원
    (마리/냉장)

    L.POINT 5,000원 할인 미국산 냉동 차돌박이 39,800원
    (1.2kg/냉동/미국산)

    10,000원 할인 젠텍 14인치 터보펜 에어 서큘레이터/
    젠텍 20인치 공업용 선풍기 각79,800원

    순면 누빔 편백메밀베개
    (블루/차콜) 각9,980원
     
    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
    </div>
  `,
  `
  <div>
    ▶ 기 간 : 5/30(목)~6/12(수)
    해양수산부와 함께하는 수산대전20%

    수산대전20% 프리미엄 광어회 29,984원
    (500g 내외/팩/광어 : 국산)

    수산대전20% ASC인증 완도 활전복 19,840원
    (대/14미/10마리)

    수산대전20% 원양산 오징어 2,384원
    (마리/해동)
 
    수산대전20% 영광 법성포 굴비 27,984원
    (1.4kg/팩/냉동/국산)

    수산대전20% 국산 고등어필렛 8,784원
    (1kg/팩/냉동)

    수산대전20% 황태채(400g/팩/러시아산)/
    볶음용, 조림용멸치 각9,984원
    (각 500g/팩/멸치:국산)

    완숙 토마토 10,980원
    (2.4kg/팩/국산)

    1,000원 할인 옛날방앗간 미숫가루 8,980원
    (1.2kg/원산지 별도표기) 

    1,500원 할인 냉장 다진마늘 9,480원
    (800g/국산)

    GAP 파프리카 980원
    (1개/국산) 

    다다기오이 4,480원
    (10입/봉/국산)

    
    </div>
  `,
  `
  <div>
    롯데카드/신한카드 1,800원 할인 신선한 계란
    (특/30입/1.8kg/국산) 5,480원

    3,500원 할인 귀한 쌀 26,480원
    (10kg/국산)

    각 2,000원 할인 구운아몬드/구운 점보캐슈넛 11,980/6,980원
    (1kg/400g, 원산지 별도표기)

    물가안정 갈치/참조기 각3,600/1,400원
    (각 32미/180미/마리, 해동, 국산)
 
    L.POINT 5,000원 할인 생새우살 31-40 16,980원
    (908g/팩/냉동/베트남산)

    L.POINT 20% 할인 호주산 곡물비육 살치살 3,664원
    (100g/냉장/호주산)
    
    L.POINT 25% 할인 호주산 찜갈비 1,860원
    (100g/냉장/호주산)

    L.POINT 3,000원 할인 정다운 허브 훈제오리
    (600g/냉장/원산지 별도표기) 11,980원
    
    L.POINT 1,500원 할인 하림 동물복지 닭볶음탕 8,480원
    (1.3kg/냉장/국내산)

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 5/30(목)~6/12(수)

    L.POINT 4,000원 할인 양념 목심 칼집구이 23,980원
    (1.7kg/원산지 별도표기) 

    L.POINT 5,000원 할인 수원식 양념돼지 왕갈비 구이 24,980원
    (1.7kg/원산지 별도표기)

    L.POINT 50% 할인 풀무원 국산콩 와사비 나또 5,700원
    (44.5g×8입/원산지 별도표기)

    패밀리팩 초밥 24,980원
    (40P/팩/원산지 별도표기)

    씨푸드 쉐이크 우동 3,980원
    (팩/원산지 별도표기)

    핫 스파이시 윙봉 13,980원
    (팩/원산지 별도표기)

    날치알 새꼬막 비빔밥 8,980원
    (팩/원산지 별도표기)

    2,000원 할인 피크닉 마들렌 10,900원
    (20입/1팩/원산지 별도표기)

    각 1,000원 할인 RTC 피자 2종/파스타 1종 11,900~4,900원
    (듬뿍불고기/핫 페퍼로니)
    ※원산지 별도표기 ※빅마켓 영등포점에 한함
  </div>
  `,
  `
  <div>
    3,000/1,000원 할인 하림 닭가슴살 블랙페퍼(510g)/
    사조대림 로얄크랩(100g×2입) 9,480/4,480원

    1,000원 할인 백종원의 홍콩반점 해물육교자 6,980원
    (350g×2입)

    6,000원 할인 하림 치킨쌀너겟 9,980원
    (1.2 kg)

    각 2,000원 할인 쉐푸드 등심통돈까스(600g)/
    롯데 라퀴진 모짜렐라 치즈스틱(800g) 12,980/11,980원

    2,000원 할인 남가네 설악 추어탕 14,980원
    (450g×3입)

    각 2,000원 할인 문옥례 명인 전통 고추장/된장 15,580/11,980원
    (각 900g)

    각 2,000/1,000원 할인 움트리 생와사비(120g×2입)/
    육류n생와사비랑(120g×2입)/
    양념 초고추장(1kg×2입) 7,480/8,380/5,980원

    1,000원 할인 고기엔 참소스
    (800g×2입) 8,480원

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  `,
  `
 <div>
    ▶ 기 간 : 5/30(목)~6/12(수)

    테이스티 말보로
    소비뇽 블랑(750ml) 19,800원

    커티삭 오리지널/프로히비션(각 700ml) 24,800/42,800원

    1,000원 할인 풀무원 다논 그릭 플레인(90g×10입) 4,680원

    롯데카드/신한카드 1,500원 할인 대파&베이컨 크림치즈(50g×4입) 5,480원

    롯데카드/신한카드 5,000원 할인 덴마크 산양 프로틴 고단백 14,480원
    슬라이스 치즈(220g×4입) 

    3,500원 할인 밀카 초코무 비스킷(40g×24입) 11,980원

    1,000원 할인 해태 홈런볼 초코(46g×9입) 7,980원
 
    2,500원 할인 액트투 전자렌지용 팝콘 버터맛 14,480원
    (1,404g)
  </div> 
  `,
  `
  <div>
    2,000원 할인 켈로그 고소한 현미 그래놀라 11,680원
    (500g×2입)

    롯데카드/신한카드 5,000원 할인 베지밀 17곡 한끼두유 11,980원
    (190ml×24입)

    롯데카드/신한카드 1,500원 할인 MAXX아메리카노 리얼블랙 1,980원

    2,000/1,000원 할인 사과당 애플밀크티/애플크러쉬 4,980/3,980원
    (각 18g×20입)

    종근당건강 전품목 3만원 이상
    5천원 롯데상품권 증정!
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령

    L.POINT 5,000원 할인 종근당건강 생유산균 락토핏 코어
    더블세트(60포×2입) 27,800원

    롯데카드/신한카드 1,000원 할인 독일 비타하임 발포 멀티비타민
    (4,500mg×80정) 8,980원

    4,000원 할인 뉴트리원 장용성 초임계 알티지
    오메가3 트리플(1,200mg×60캡슐) 21,980원

    5,000원 할인 내추럴박스 산양유프로틴
    (15g×42포) 29,800원

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  </div>
  `,
  `
  <div>
    ▶ 기 간 : 5/30(목)~6/12(수)
    
    롯데카드/신한카드 2,000원 할인 MAXX 프리미엄 화장지
    (40m×30롤) 17,980원

    L.POINT 2.500원 할인 아우라 피오니 블러썸 16,480원
    (5.5L)

    L.POINT 4,000원 할인 테크 베이킹+구연산 액체세제 11,980원
    (4.8L)

    2,000원 할인 히말리야 핑크솔트 아이스 15,980원
    (160g×5)

    2,000원 할인 미쟝센 퍼펙트
    오리지널 세럼 8,980원
    샴푸(900ml)

    2,000원 할인 밀크바오밥
    세라 바디워시
    베이비 파우더 
    (1,200ml) 11,980원
    
    L.POINT 최대 4,000원 할인 INTEX 플라밍고 라이드온/
    미니프레임풀(블루) 17,980/32,800원

    2,000원 할인 애견 쿨아이스 방석(스카이블루) 10,980원
  </div>
  `,
  `
  <div>
    락앤락 저장용품
    ※세부 품목은 매장에서 확인하세요
    전품목 할인!
    7,000원 할인 락앤락 클래식 밀폐용기 12P 14,980원
    
    3,000원 할인 니트릴 장갑 100매 7,980원
    (소/중/대)
    2,000원 할인 냉감 일체형 베게 10,980원
    1,000원 할인 마작 대방석(내추럴) 7,980원
    9,820원 할인 블랙야크 워크웨어
    반소매 카라티셔츠 29,980원
    5,000원 할인 SOUP 슬리브리스 포인트 원피스 34,800원
    5,000원 할인 비비안 숨쉬는 브라
    (2매입, M/L/XL) 29,800원
    5,000원 할인 나이키 코르테즈 파우치(블랙) 39,800원

    ※정상가란? 2024년 5월 9일(목) ~ 5월 29일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  </div>
  `,
];

/* 738,739 maxx(~11/27) */
const maxxData = [
  `
  <h1>LOTTE Mart maxx</h1>
  <div>
    ·전단적용기간 : 2024. 11. 14(목) ~ 2024. 11. 27(수) ·롯데마트맥스 : 금천, 목포, 상무, 송천, 영등포, 창원중앙점

    코리아 세일 페스타 11.9. ~ 11.30.
    국가대표 쇼핑축제
    MAXX가 준비한 고객 감사 대축제
    Again 땡큐절
    매일 10시 아침 종이 땡 미친 할인 큐

    땡 잡았다!
    1 4일간 이 가격
    2 1+1ㆍ 50%
    3 겨울 먹거리&아이템 득템찬스

    롯데카드/신한카드 3,000원 할인
    서귀포 감귤 (3.5kg/박스/국산) 11,980원

    사과(3kg/봉/국산) 19,980원

    L.POINT 최대 30%
    미국산 초이스&호주산 와규 인기부위 
    ※ 행사 대상 품목 : 미국산 초이스 부채살, 살치살/호주산 와규 윗등심살, 부채살, 갈비살, 치마살

    L.POINT 최대 30% 할인    
    미국산 초이스 부채살/호주산 와규 윗등심살 (각 100g/냉장, 미국산/호주산) 1,946/3,735원

    롯데카드/신한카드 6,000원 할인
    양념 소불고기 (2kg/냉장/원산지 별도표기) 100g당 1,290원 25,800원

    L.POINT 30% 할인
    흑마늘 치킨구이 (팩/원산지 별도표기) 8,386원

    산루카스 엑스트라버진 아보카도오일(1L) 5,000원 할인 19,980원

    2+1
    락토핏 생유산균 골드 더블 타포린 기획 (2g×50포×2입) 23,800원
    ※한정수량 ※점별 재고 상이

    니트릴 장갑 (각 100매, 중형/대형) 40% 각6,580원

    땡 잡았다! 4일간 이 가격!
    기간 : 11/14(목) ~ 11/17(일)
    롯데카드/신한카드 2,000원 할인
    감숙왕/허니골드 파인애플 (2kg 내외/1통/필리핀산)  4,980원
    ※점별 운영상품 상이

    캐나다산 돼지 삼겹&목심 (각 100g/냉장/캐나다산) 각990원

    L.POINT 50% 할인
    두툼한 손질 갈치 (400g/팩/냉동/모로코산) 3,440원

    풀무원 순생 나또(49.5g×12) 50% 5,490원
    ※원산지 별도표기

    중화 해물 양장피 (팩/원산지 별도표기) 12,980원

    서울 멸균 흰우유(200ml×24입) 6,000원 할인 10,880원

    최대10,000원 할인
    사조해표 카놀라유/포도씨유(각 900ml×4입) 11,980/21,980원

    1+1
    스테비아 핫초코 라떼 /아몬드호두율무차 (각 30개입) 각11,980원
    ※교차구매 가능

    암앤해머 트로피컬 파라다이스(2.21L×2입) 8,000원 할인 19,980원

    키친플라워 스텐 무선포트(1.8L) 2,000원 할인 7,980원

    롯데/신한 제휴카드 결제 고객 대상 놀라운 혜택을 만나보세요!
    ▶ 해당카드 : 롯데마트&MAXX카드, LOCA for 롯데마트카드, 롯데마트맥스 신한카드

    맛있는 오뚜기밥 롯데/신한 제휴카드 50%
    기간 : 11/14(목)~11/17(일)
    ※인당 2개 한정
    오뚜기 맛있는 오뚜기밥 (210g×24입) 11,490원

    건해산물 전품목 롯데/신한 제휴카드 20%
    기간 : 11/14(목)~11/27(수)
    ※선물세트 제외

    타미힐피거/휠라/챔피온/CK 등 브랜드의류 롯데/신한 제휴카드 20%
    기간 : 11/14(목)~11/27(수)
    ※일부 품목 제외

    ※정상가란? 2024년 10월 24일(목) ~ 11월 13일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC카드 제외 ※롯데마트몰 카드행사 제외 ※오프라인 점포에 한함 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    땡 잡았다! 1+1 50%

    1+1
    하림 치킨쌀너겟 (1,200g) 15,980원

    1+1
    풀무원 밀 누들떡볶이 2인(423.5g) 4,980원

    1+1
    빙그레 스트링치즈 (각 20g×10입) 각9,980원
    ※교차구매 가능
    ※프로틴/프리바이오틱스

    1+1
    파스퇴르 쾌변 요구르트 (150ml×8입) 10,780원

    1+1
    Chefood 고기통교자(1,050g) 11,980원

    1+1
    코우카키스 그릭요거트 (각 1kg) 각19,980원
    ※오리지날/무지방 ※교차구매 가능

    롯데카드/신한카드 50% 할인
    동서 포스트 씨리얼컵 3종 기획(30g×12입) 5,990원

    1+1
    파스퇴르 쾌변 요구르트 (150ml×8입) 10,780원

    1+1
    사조대림 조기어묵 (280g) 3,680원

    1+1
    아이배냇 핑거요거트 플레인/딸기 (각 20g) 각4,900원
    ※교차구매 가능

    1+1
    할리스 시그니처 아메리카노 마일드 미니(0.9g×150입) 28,980원

    1+1
    이디야 오리지널 밀크티(18g×20입) 11,980원

    1+1
    뉴트리원 아이맥스 루테인(500mg×90캡슐) 20,980원
    ※기간 : 10/31(목) ~ 11/27(수

    1+1
    초저분자300 달톤 콤부차콜라겐(5g×30포) 14,980원

    50%
    더호텔컬렉션 이불세트 (S/Q, 차렵이불+베개커버) 31,400/36,900원

    2개 이상 50%
    TRY 성인 플란넬 9부 팬츠(2매입) 2개 이상 구매시 1개당 각14,990원
    ※1개 구매시 29,980원 ※교차구매 가능

    땡 잡았다!겨울 먹거리득템 찬스

    구룡포 꽁치과메기 (팩/원산지 별도표기) 15,980원
    ※이미지 연출컷입니다

    찜/구이용 석화 (2kg/냉장/국산) 8,980원
    ※이미지 연출컷입니다

    삼립 옛날 꿀호떡 (560g×2입) 2,000원 할인 4,480원

    스위스미스 핫코코아 (메쉬멜로 737g) 1,000원 할인 7,980원

    수산물 할인 바다! 2024 대한민국 수산대전
    해양수산부와 함께하는 수산대전 20%
    ※기간 : 11/14(목) ~ 11/27(수) ※해당상품에 한함 ※산지시황에 따라 조기품절될 수 있습니다
    ※L.POINT 회원 기간 중 1인 주차별 최대 1만원 할인 ※이미지 연출컷입니다

    수산대전 20%
    남해안 봉지굴 (420g/봉/냉장/국산) 7,984원

    수산대전 20%
    남해안 생굴 (100g/냉장/국산) 1,984원

    수산대전 20%
    ASC인증 완도활전복 (14미/대/10마리/국산) 19,840원
    ※ 기간 : 11/21(목)~11/27(수)

    수산대전 20%
    새우젓(2kg/통/냉장) 14,384원
    ※ 새우젓 : 국산 천일염 : 국산

    수산대전 20%
    방어회 (300g 내외/팩/방어 : 국산) 29,584원
    ※ 기간 : 11/14(목)~11/20(수)

    수산대전 20%
    국물용 멸치 (1kg/팩/원산지 별도표기) 13,584원

    수산대전 20%
    황태채(400g/팩) 9,984원
    ※ 원산지 별도표기

    게메즈 에낙 라면스낵(696g) 2,000원 할인 7,980원

    크리스마스 하우스 키트(418g) 8,980원
    ※ 시즌 한정물량으로 조기소진될 수 있습니다

    2개 이상 10%
    농심 멸치 칼국수(5개입) 2개 이상 구매시 1개당 각3,762원
    ※1개 구매시 4,500원 4,180원(320원 할인)

    L.POINT 2,000원 할인
    광동 진쌍화 (100ml×20입) 8,980원

    MAXX 국내산 쌀누룽지(2kg) 3,500원 할인 20,480원

    롯데카드/신한카드 5,000원 할인
    베지밀 17곡 한끼두유 (190ml×24입) 11,980원

    ※정상가란? 2024년 10월 24일(목) ~ 11월 13일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
 
  </div>
  `,
  `
  <div>
    L.POINT 1,000원 할인
    냉동 블루베리/딸기/트리플베리 전품목(상품별 상이) ※매장 입점상품에 한함
    ※냉동 미국산 블루베리 1.81kg 外

    스윗텔토마토(1.5kg/팩/국산)/미국산 석류(개/미국산) 14,980/4,980원

    L.POINT 2,000원 할인
    씨를 뺀 건자두(650g)/건망고(400g) 각7,980원
    ※원산지 별도표기

    감자(2kg/박스/국산) ※기간 : 11/14(목)~11/20(수)/무농약 새송이 버섯(600g/팩/국산) 4,990/2,780원

    L.POINT 최대 2,500원 할인
    구운아몬드(1kg)/이탈리아산 파로(1kg/이탈리아산) 11,980/12,480원

    호박고구마(1kg/봉/국산)/국산 미니 단호박(1.5kg/박스/국산) 5,280/8,980원

    양배추컷&믹스(450g/팩/국산) 2,680원

    까다롭게 선별한 1+등급란 (특 30입/1.8kg/국산) 1,500원 할인 6,980원
    ※기간 : 11/14(목)~11/20(수)

    최대4,000원 할인
    임금님표 이천쌀/찹쌀(10kg/4.5kg, 국산) 39,800/12,980원

    L.POINT 40% 할인 L.POINT 2,000원 할인
    산더미 부추 오리주물럭/요리하다 다리살로만 훈제오리 (900g/450g, 원산지 별도표기) 14,880/8,980원

    L.POINT 각 2,000원 할인
    BBQ오징어(300g/원산지 별도표기)/대천 김수정(5g×30봉/원산지 별도표기)

    L.POINT 각 10,000원 할인
    1등급 한우 차돌박이/갈비살 (700g/600g, 냉장, 국내산(한우)) 44,800/69,800원

    L.POINT 30% 할인 
    동물복지 돼지 삼겹살/목심 (각 100g/냉장/국내산) 각2,436원

    L.POINT 3,000원 할인
    스페인 대패 삼겹살(3kg/냉동/스페인산) 26,700원

    L.POINT 6,000원 할인
    LA식 꽃갈비(2kg, 냉동, 호주산/미국산) 각68,800원

    우리 해초 새꼬막 비빔밥/하림 허니 치킨 봉(팩/원산지 별도표기) 8,980/12,980원
    ※꼬막/해초 : 국내산 ※닭고기 : 국내산

    헬씨 베지롤/깐쇼새우 (팩/원산지 별도표기) 각6,980원

    미니 버터 크로와상 (20입/1팩/원산지 별도표기) 2,000원 할인 6,900원
    ※롯데마트맥스 금천, 영등포, 송천, 창원중앙점에 한함    

    포카치아 피자 2종 (각 1팩/원산지 별도표기) 2,000원 할인 각10,900원
    ※마르게리따/핫페퍼로니
    ※ 롯데마트맥스 금천, 영등포, 상무, 창원중앙에 한함
    ※창원중앙점 : 11/18(월)~11/27(수)

    L.POINT 20% 할인
    더 커진 왕밤단팥빵 (1입/원산지 별도표기) 1,592원
    ※롯데마트맥스 상무, 목포점에 한함

    풀무원 다논 그릭 (플레인 90g×10입) 700원 할인 4,980원

    파스퇴르 바른목장우유 (1.8L) 2,300원 할인 4,980원

    매일 상하 체다 슬라이스 치즈 (1.36kg/80매) 5,000원 할인 12,480원

    동원 고추참치 (100g×10입) 2,500원 할인 14,480원

    목우촌 프라임 빵위네모햄(330g) 3,000원 할인 3,980원

    삼진 간편꼬치 어묵(1,000g) 롯데카드/신한카드 3,000원 할인 8,980원

    마니커에프앤지 춘천식 닭갈비(950g) 5,000원 할인 9,980원

    파닭떡닭 맛꼬치(1.2kg) 4,000원 할인 13,980원

    최대2,000원 할인
    모니니 발사믹 식초(500ml)/엑스트라버진 올리브유 (유기농 500ml) 5,980/15,980원

    고기엔 참소스 (800g×2입) 1,500원 할인 7,980원

    2개 이상 30%
    페리카나 치킨양념 오리지널맛/아딸 떡볶이 소스(각 300g) 2개 이상 구매시 1개당 각2,786/3,486원
    ※1개 구매시 3,980/4,980원 ※교차구매 가능

    해태 맛동산(70g×12입) 2,000원 할인 7,480원

    최대2,500원 할인
    롯데 ABC 초콜릿 대용량(565g)/크라운 마이쮸(포도+복숭아 952g) 7,980/7,480원

    다농원 생강차/쌍화차(각 15g×80입) 2,000원 할인 각13,980원

    광동 썬키스트 모과생강 세트 (280ml×8입) 3,000원 할인 6,980원

    매일유업 피크닉 사과 (200ml×24입) 1,300원 할인 7,480원

    롯데카드/신한카드 3,000원 할인
    오늘좋은 이뮨샷 멀티비타민 (20ml×7병) 8,900원

    리얼닥터 전품목 3만원 이상 구매시 5천원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    리얼닥터 프리미엄 오메가 3 (1,100mg×180캡슐) 5,000원 할인 21,980원

    L.POINT 3,000원 할인
    닥터리브 애사비 클렌즈(15g×10입) 7,980원

    L.POINT 50% 할인
    산페드로 9 라이브스 리저브 까베르네 소비뇽(750ml) 8,950원

    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC카드 제외 ※롯데마트몰 카드행사 제외 ※오프라인 점포에 한함 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    땡 잡았다! 겨울 아이템 득템 찬스!

    최대 11,800원 할인
    버뮤다 PTC 타워온풍기(250×250×820mm)/대웅 오방난로(250×250×480mm) 108,000/41,800원

    최대5,000원 할인
    모리츠 2단 석영관(800W)/모리츠 에코히터 (304×210×240mm) 15,000/74,800원

    2,000원 할인
    대용량 멀티탭 2구(1.5m) 14,980원

    ※더 많은 난방 상품들이 매장에 준비되어 있습니다

    최대3,000원 할인
    하우홈 따뜻한 단열벽지(화이트 2.5m)/하우홈 따뜻한 보온시트(2.2m) 12,880/2,980원

    베어파우양털 슬리퍼 (230/240/250mm) 9,820원 할인 29,980원

    5,000원 할인
    피치워싱 극세사이불 (S : 150×200cm/Q: 180×220cm, 그레이) 24,980/34,800원

    7,000원 할인
    테르톤 대용량 가습기 (13.5L) 82,800원

    모리츠 마운틴 전기요(소/대) 각6,000원 할인 29,800/31,800원

    성인 수면 세트 각3,000원 할인 각16,980원
    (블루: 95~110/핑크: 90~100)

    히트허그 성인 보들이 기모 양말(3족) 1,500원 할인 각7,480원

    각2,000원 할인
    컬럼비아(남성)/시슬리(여성) 울 장목 양말(각 4족/5족) 각12,980원

    5,500원 할인 -> 롯데카드/신한카드 5,000원 추가할인
    잘풀리는집 명품 로션티슈 에센셜(30m×30롤) 14,980원

    잘풀리는집 뽑아쓰는 키친타월 (150매×6입) 1,500원 할인 8,480원

    맥스 물티슈(120매×10입) 1,000원 할인 8,980원

    탑스텝 3in1 캡슐 세탁세제(100입) 4,000원 할인 15,980원

    엘지생활건강 생리대 전품목 3만원 이상 구매시 5천원 롯데상품권 증정
    ※당일 영수증에 한함 ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령

    각2,000원 할인 롯데카드/신한카드 각2,000원 추가할인
    리엔 보윤 샴푸/트리트먼트(각 1L) 각6,980원
 
    IVORY 아이보리 비누 오리지널 (113g×10) 2,000원 할인 8,980원

    VT 시카 마일드 폼클렌져 기획세트(300ml×2) 3,000원 할인 12,980원

    아르코맨 센서티브 쉐이빙폼(200ml×3) 2,500원 할인 7,480원

    티타늄 코팅 인덕션 프라이팬/웍 (각 30cm) 2,000원 할인 각14,980원

    이나바 챠오츄르 구루메 버라이어티(14g×60P) 2,000원 할인 26,980원

    코코마켓 닭고구마/오리고구마 (각 1,500g) 각5,000원 할인 각22,980원

    락포트 클래식 로퍼 (250/260/265/270mm) 59,800원

    아디다스 오리지널 후드티셔츠 UCLA 다운 베스트 각29,980원

    최대5,000원 할인
    락앤락 인터락(6P)/락앤락 비스프리 테이블탑(6P) 8,980/19,980원

    ※정상가란? 2024년 10월 24일(목) ~ 11월 13일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다 ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다 ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
    ※해당 단일카드로 전액 결제시에 한함 ※체크카드/앱카드 포함 ※기프트/선불카드 제외 ※신한BC카드 제외 ※롯데마트몰 카드행사 제외 ※오프라인 점포에 한함 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
 
  </div>
  `,
];

/* 703,753 빅마켓(~6/26) */
const vicNewData = [
  `
  <h1>LOTTE VIC Market</h1>
  <div>
    롯데/신한카드 롯데마트&MAXX 전용카드 회원만!
    미국산 초이스 소고기/즉석밥 20% 추가할인

    ▶ 기간 : 6/13(목) ~ 6/26(수)
    ▶ 해당카드 : 롯데마트&MAXX카드,LOCA for 롯데마트카드,롯데마트맥스 신한카드
    ※최종가/엘포인트 할인가에서 추가 할인 적용
    ※MAXX/VIC 매장 한정, 롯데마트몰/SCAN&GO 제외                                      
    ※로카페이, LPAY 및 해당 실물카드 결제 시 적용
    (삼성페이 등 간편결제는 미적용)
    ※할인스티커 부착 상품과 중복적용 불가 ※재고소진시 행사종료
    ※특약(입점)행사/선물세트 제외 ※자세한 사항은 가까운 직원에게 문의

    미국산 초이스 소고기 20% 추가 할인

    롯데마트&MAXX카드,LOCA for 롯데마트카드,롯데마트맥스 신한카드 적용가
    미국산 초이스 부채살/살치살 (각 100g/냉장/미국산) 1,584/3,584원
    ※해당카드 미적용시 1,980/4,480원

    롯데마트&MAXX카드,LOCA for 롯데마트카드,롯데마트맥스 신한카드 적용가
    즉석밥 전품목(상품별 상이) 20% 추가 할인

    딱!! 100일간 이 가격 ※기간 : 6/10(월)~9/17(화)

    동물복지 백색 유정란 (대 30입/1.56kg/국내산) 1,500원 할인 7,980원
    건망고 (400g/베트남산) 2,000원 할인 7,980원

    L.POINT 2,000원 할인
    롱스틱 직화육포 (90g×2입/원산지 별도표기) 6,980원

  </div>
  `,
  `
  <div>
    스위트 마운틴 바나나 (1.2kg 내외/봉/원산지 별도표기) 2,780원

    1등급 국내산 돼지 앞다리(100g/냉장/국내산) 980원

    왕새우튀김 (팩/원산지 별도표기) 8,980원

    MAXX 1A 저지방우유(2.3L) 400원 할인 4,980원

    롯데 설레임 밀크쉐이크 (160ml×12입) 3,000원 할인 8,980원

    뮤엘올리바 카놀라유(2L) 1,000원 할인 8,980원

    크라운 초코하임(142g×5입) 2,000원 할인 7,480원

    토레타/파워에이드 마운틴 블라스트 (각 900ml×12입) 3,500원 할인 각11,480원

    L.POINT 4,000원 할인
    비비랩 가르시니아 플러스 (1,000mg×112정) 15,980원

    에고라운드 두피쿨링 민트 샴푸(1,000ml) 2,000원 할인 11,980원

    MAXX 테이크아웃 아이스컵 80P 세트 2,000원 할인 11,980원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)

    슬기로운 여름생활!

    롯데카드/신한카드 2,000원 할인
    AI선별 수박(6~10kg 미만/통/국산) 14,980~19,980원

    천도 복숭아(2kg/팩/국산) 12,980원

    롯데카드/신한카드 25% 할인
    1등급 국내산 돼지 삼겹살/목심 (각 100g/냉장/국내산) 각1,860원

    L.POINT 7,000원 할인 
    1등급 한우 차돌박이/갈비살 (700g/600g, 냉장, 국내산) 47,800/72,800원

    롯데카드/신한카드 50% 할인
    러시아 활 킹크랩 (100g/냉장/러시아산) 5,990원

    롯데카드/신한카드 2,000원 할인
    파스퇴르 쾌변 요구르트 (150ml×8입) 8,780원

  </div>
  `,
  `
  <div>
    1,000원 할인
    스페쇼우 젤리스트로우 플러스 (1,254g) 9,980원

    2팩 이상 20% 할인
    제주 삼다수 그린 (2L×6입/500ml×20입) 2팩 이상 구매시 1팩당 5,184/7,680원
    ※1개 구매시 각 6,480/9,600원 ※교차구매 가능

    풀무원 평양 물냉면(2,538g)/칠갑 얼음찬 메밀소바(804g) 2,000원 할인 8,480/5,480원

    롯데 스낵 버라이어티팩(894g) 2,000원 할인 11,980원

    기능성 반팔티셔츠/7부팬츠, 5부팬츠 3,000원 할인 12,980/14,980원

    MAXX 스탠다드 단목 양말 (6족, 남성/여성) 1,000원 할인 각7,980원
    ※일부 색상 조기 소진 가능
    ※기간 : 6/10(월)~9/17(화)

    주방 필수템!
    키친유 스텐 밀폐용기 2P(2.0L/3.6L) 최대 3,000원 할인 17,980/24,980원

    에찌모토 식과도 가위세트(3P) 2,000원 할인 11,980원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)
    6월 여름맞이 특별전 해양수산부와 함께하는 수산대전20%

    ※기간 : 6/13(목) ~ 6/19(수) ※해당상품에 한함
    ※산지시황에 따라 조기품절될 수 있습니다 ※이미지 연출컷입니다
    ※L.POINT 회원 기간 중 1인 최대 1만원 할인 ※기간 內 주차별 1인/1만원 한도

    수산대전20%
    프리미엄 광어회 (500g 내외/팩/광어 : 국산) 29,984원

    수산대전20%
    ASC인증 완도 활전복 (대/14미/10마리) 19,840원

    수산대전20%
    원양산 오징어 (마리/해동) 2,384원

    수산대전20%
    영광 법성포 굴비 (1.4kg/팩/냉동/국산) 27,984원

    수산대전20%
    국산 고등어필렛 (1kg/팩/냉동) 8,784원

    수산대전20%
    황태채(400g/팩/러시아산)/볶음용, 조림용멸치(각 500g/팩/멸치:국산) 각9,984원

    제스프리 점보 골드키위(8~12입/팩/뉴질랜드산) 14,980원

    스윗탱고 토마토 (1.5kg/국산) 10,480원

    시즌컬러 채소모둠 (500g/팩/국내산) 4,680원

    양파(3kg/국내산) 5,380원
    ※기간 : 6/13(목)~6/19(수)

    새송이 버섯 (600g/팩/국내산) 2,780원

    양상추 (1봉/국내산) 1,980원

  </div>
  `,
  `
  <div>
    가바현미 7곡 (4.2kg/국내산) 2,000원 할인 14,980원

    귀한쌀(20kg/국내산) 7,000원 할인 49,800원

    하루한줌 고메넛츠 (30g×25봉/원산지 별도표기) 2,000원 할인 15,980원

    호두(1kg/미국산) 1,500원 할인 9,480원

    손질민물장어 (500g/팩/냉장/국내산) 34,980원

    데친 문어 (100g/냉장/베네수엘라산) 2,480원

    L.POINT 5,000원 할인
    생칵테일새우살 51-70 (908g/팩/냉동/베트남산) 14,980원

    L.POINT 3,000원 할인
    한판 모둠 소시지 (1.1kg/냉장/원산지 별도표기) 8,980원

    L.POINT 2,000원 할인
    요리하다 다리살로만 훈제오리(450g/냉장/원산지 별도표기) 8,980원

    L.POINT 각2,000원 할인
    하림 IFF 6개 품목 ※냉동/국내산 (상품별 상이/품목 2kg) 

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)

    L.POINT 15% 할인
    참한 새우초밥 (24P/팩/냉장/원산지 별도표기) 19,533원

    오징어볼/쉬림프칩 (240g/160g/원산지 별도표기) 2,000원 할인 각7,980원

    L.POINT 6,000원 할인
    100g당 1,290원
    양념 소불고기 (2kg/팩/냉장/원산지 별도표기) 25,800원

    서리태 콩국물/국산콩 콩국물(각 1L/국내산) 5,880/4,980원

    경양식 함박스테이크(9입/팩/원산지 별도표기) 13,980원

    일품 월남쌈 세트 (팩/원산지 별도표기) 13,980원

    가성비 식사/사이드 세트, 감탄박스 (1팩/원산지 별도표기) 8,900원

    오리지널 핫도그 (5입/1팩/원산지 별도표기) 2,000원 할인 8,900원

  </div>
  `,
  `
  <div>
    최대3,000원 할인 
    동원 고추참치(100g×10입)/동원 리챔 더블라이트(300g×6입) 15,480/21,980원
    
    2,740/4,000원 할인
    티아시아 골든 버터 난(200g)/쉐푸드 생크림버터 치킨카레,토마토 비프카레(각 170g×4입) 2,740/5,980원

    최대 40% 할인
    디벨라 파스타면 5종(상품별 규격 상이) 
    ※ 스파게티니, 스파게티, 링귀네, 푸실리, 펜네

    롯데카드/신한카드 4,000원 할인
    매일 상하 체다 슬라이스 치즈 (1.36kg/80매) 13,480원

    빙그레 딸기맛 우유 (240ml×8입) 1,000원 할인 7,480원

    5개 구매시 5,980원
    삼립 인기빵 10종(상품별 규격 상이) 5개 이상 구매시 1개당 각1,196원 
    ※주종발효 단팥크림빵, 주종발효 카스타드 소보루 外
    ※1개 구매시 각 1,480원

    트리코 고프레 프렌치 쿠키(450g) 14,980원

    트윅스 미니스 (1.02kg) 5,000원 할인 16,480원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)
    1+1 & 50% 할인

    1+1
    CJ 삼호 오뎅한그릇(360g)/구이한판 스모크 그릴(60g×4) 3,680/8,480원

    1+1
    사세 쏘스치킨 윙봉/순살 단짠갈릭맛(300g/375g) 각8,900원

    50%
    사조대림 국물떡볶이 (373g) 2,340원

    1+1
    풀무원 납작지짐만두(700g) 8,980원

    1+1
    동원 양반 수라 왕갈비탕/도가니설렁탕/보양추어탕/통다리삼계탕(각 460g) 각8,980원

    1+1
    담터 콤부차 샤인머스캣 포켓몬 기획(5g×30입) 15,980원

    1+1
    립톤 아이스티 피치(14g×120입) 37,980원
    ※ 점별 재고 한정

    1+1
    락티브 어린이 면역엔 멀티비타민 젤리(4g×60EA) 24,980원

  </div>
  `,
  `
  <div>
    크러시 쿨러백 (355ml×18입) 26,800원

    볼쓰 멜론×파쏘아 하이볼 패키지 (700ml×2ea+리유저블컵 3ea) 39,800원

    트레비 탄산수 3종 (각 300ml×20입) 각7,980원
    ※ 레몬/라임/플레인

    남양 프렌치카페 믹스 (10.9g×300입) 2,000원 할인 27,780원

    롯데카드/신한카드 5,000원 할인
    베지밀 검은콩과 검은참깨 두유 (190ml×24입) 11,980원

    멘토스 뉴레인보우 츄잉캔디 (미니/1kg) 1,800원 할인 9,480원

    뉴트리원 프리미엄 프리바이오틱스(5g×50포) 4,000원 할인 12,980원

    내추럴박스 카무트 브랜드 밀효소 (3g×15포×2입) L.POINT 2,000원 할인 19,980원

    롯데카드/신한카드 4,000원 할인
    바이탈슬림 리얼컷 아이돌물 녹차꿀맛 슬림(20ml×14포) 11,980원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    ▶ 기 간 : 6/13(목)~6/26(수)

    롯데카드/신한카드 3,000원 추가할인
    잘풀리는집 명품 로션티슈 에센셜(30m×30롤) 16,980원

    롯데카드/신한카드 2,000원 추가할인
    탑스텝 3in1 캡슐 세탁세제(100입) 15,980원

    옹달샘 습기제거제(18입) 2,000원 할인 12,980원

    롯데카드/신한카드 3,000원 할인
    시린메드 검케어(125g×5) 18,980원

    퍼펙트 건조대(대) 5,000원 할인 19,980원

    3M 이지 극세사 막대걸레 세트 (막대걸레 1P+극세사패드4매 구성) 6,000원 할인 18,980원

    제크롤 후면식 선풍기 (JK BF1400) 4,000원 할인 31,800원

    모리츠 메탈 20인치 선풍기 (MO 200MF) 4,000원 할인 55,800원

  </div>
  `,
  `
  <div>
    최대 70,000원 할인
    라코스테 클래식 카라/그래픽 반소매 티셔츠/블랙야크 웨크웨어 반소매 카라/라운드 티셔츠 24,980~59,800원
    
    나이키 브라질리아 더플백 (S, 41L) 3,000원 할인 32,800원

    NEW Arrival

    두툼한 손질 갈치 (400g/냉동/팩/모로코산) 6,880원

    MSC 짜먹는 냉동 명란 (30g×10팩/냉동/원산지 별도표기) 12,800원

    간편 고등어구이 (90g×4봉/냉동/원산지 별도표기) 13,800원

    뚜껑과 빨때를 제거해 환경까지 생각한 마이카페라떼 그린!
    매일카페라떼 마일드 컴포리드 (200ml×8입) 9,480원

    뉴트리 에버콜라겐 고백 (750mg×84정) 32,980원

    헤어플러스 아쿠아 워터 에센스 (200ml×2) 19,980원

    에이센트 3X 고농축 퍼퓸드 섬유유연제(4L) 15,980원

    ※정상가란? 2024년 5월 23일(목) ~ 6월 12일(수) 롯데빅마켓 금천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다
    ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
];

/* 758,759 maxx(~11/6) */
const maxxNewData = [
  `
  <h1>LOTTE Mart maxx</h1>
  <div>
    ·전단적용기간 : 2024. 10. 31(목) ~ 2024. 11. 13(수) < 신선식품 적용기간 : 2024. 10. 31(목) ~ 11. 6(수) >
    ·롯데마트맥스 : 금천, 목포, 상무, 송천, 영등포, 창원중앙점

    ·롯데마트맥스가 준비한 고객 감사 대축제
    땡큐절
    매일 오전 10시 ~ 오후 1시 타임쿠폰팩 다운받고 즉시 사용하세요!
    10만원 이상 구매시 5,000원 할인
    ※쿠폰 관련 세부내용은 롯데마트몰에서 확인하실 수 있습니다

    매일 10시 아침 종이 땡 미친 할인 큐
    땡 잡았다! 1. 4일간 이 가격 2. 1+1,50% 3. 땡전딜 4. 득템찬스

    L.POINT 30% 할인
    1등급 국내산 삼겹살/목심(각 100g/냉장/국내산) 각1,785원

    L.POINT 30% 할인
    미국산 프라임 척아이롤(100g/냉장/미국산) 1,995원

    L.POINT 500원 할인 -> 롯데/신한카드 500원 추가할인
    마운틴 바나나(1kg 내외/원산지 별도표기) 1,980원

    풀무원 특등급 국산 콩나물(200g/국산) 990원
    ※기간 : 10/31(목)~11/6(수)

    3,500원 할인
    사조 살코기+마일드 참치(살코기 150g×6입+마일드 150g×2입) 12,280원

    롯데/신한카드 1+1
    프링글스 미니 캔 번들(636g) 15,280원
    ※2개 결제시 1개 자동할인 적용

    L.POINT 30% 할인
    하림 더 건강한 두마리 치킨(2마리/팩/닭고기:국내산) 12,586원
    ※기간 : 10/31(목) ~ 11/6(수)

    롯데/신한카드 5,000원 할인
    샤인머스캣(4kg/박스/국산) 19,800원

    롯데/신한카드 1,000원 할인
    제주 GAP 감귤(3kg/박스/국산) 7,980원

    땡잡았다! 4일간 이 가격!
    기간: 10/31일(목)~11/31(일)

    생새우살 4종(각 908g 내외/팩/냉동/베트남산)
    L.POINT 5,000원 할인

    롯데/신한카드 40% 할인
    1등급 한우등심(100g/냉장/국내산) 5,688원

    L.POINT 35% 할인 
    호주산 곡물비육 척아이롤(100g/냉장/호주산) 1,482원

    롯데/신한카드 2,700원 할인
    신선한 계란(특/30입/국산) 4,980원

    롯데/신한카드 3,000원 할인
    상생 파프리카(700g/봉/국산) 3,980원

    3,000원 할인
    크라운 참크래커(280g×4입) 6,980원

    최대 6,000원 할인
    칠성사이다/펩시 제로슈거(각 245ml×24캔) 각9,980원

    5,000원 할인
    해피홈 파워캡슐 트리플 생화향기(72개입) 17,980원

    WMF/휘슬러 최대 150,000원 할인!
    휘슬러 오리지날 프로피 컬렉션 5종 세트 462,800원
    ※한정수량으로 조기 품절될 수 있습니다

    한달 내내 이 가격!
    기간 : 11/1(금) ~ 11/30(토)

    L.POINT 25% 할인
    항공직송 노르웨이 생연어(100g/냉장/노르웨이산) 2,985원

    L.POINT 25% 할인 
    생연어초밥(22P/팩/원산지 별도표기) 13,485원

    롯데/신한 제휴카드 결제 고객 대상 
    놀라운 혜택을 만나보세요!
    ▶ 해당카드 : 롯데마트&MAXX카드, LOCA for 롯데마트카드, 롯데마트맥스 신한카드

    시즌제철상품 
    롯데/신한 제휴카드 40%
    남해안 봉지굴(420g/봉/냉장/국산) 5,988원

    견과/건과일 전품목
    롯데/신한 제휴카드 20%
    ※선물세트 제외 ※점별 입점상품에 한함

    냉장 베이컨 전품목
    롯데/신한 제휴카드 2개 이상 구매시 50%
    기간 : 10/31(목) ~ 11/3(일)
    ※교차구매 가능

    인기 초콜렛 10종
    롯데/신한 제휴카드 2개 이상 구매시 30%
    기간 : 11/8(금) ~ 11/11(월)
    ※교차구매 가능

    ※정상가란? 2024년 10월 10일(목) ~ 10월 30일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다   ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다  ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다  
    ※해당 단일카드로 전액 결제시에 한함  ※체크카드/앱카드 포함  ※기프트/선불카드 제외  ※신한BC카드 제외  ※롯데마트몰 카드행사 제외  ※오프라인 점포에 한함   ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다

  </div>
  `,
  `
  <div>
    땡 잡았다! 1+1,50%

    L.POINT 50% 할인
    양념 한우 불고기(1kg/원산지 별도표기) 19,900원

    2개 이상 50%
    하림 닭볶음탕(800g/국내산) 2개 이상 구매시 1개당 각3,950원
    ※1개 구매시 7,900원

    50%
    풀무원 국산 부침두부(340g/국산) 2,495원

    1+1
    삼진어묵 어부의 바(80g×8입) 13,980원

    1+1
    Hy 한국야쿠르트1971(750ml) 3,600원

    1+1
    풀무원 브레드이발소 꼬마유부초밥(149.7g×2입) 6,980원

    1+1
    풀무원 해물 생 수제비(367g/2인) 7,480원

    1+1
    마니커 리얼치킨 통살치킨너겟(1kg) 12,980원

    2개 이상 50%
    동원 참치액 진(900g×2입)/동원 카놀라유(850ml) 2개 이상 구매시 1개당 각11,490/4,500원
    ※1개 구매시 각22,980/9,000원 ※동일상품에 한함

    50%
    맘으로 설렁탕(500g×4입) 10,240원

    1+1
    블랙 아이브루 플러스 헤이즐넛향(200T) 19,980원
    ※기간 : 10/31(목)~11/3(일),4일간

    1+1
    테일러 유기농 하루 레몬즙(20ml×10입) 10,980원

    땡 잡았다! 9,980원 땡전딜!

    L.POINT 5,000원 할인
    촉촉 버터구이 오징어(300g/원산지 별도표기) 9,980원

    슈퍼 울트라 빅믹스넛(1.5kg/원산지 별도표기) 9,980원

    L.POINT 2,000원 할인
    아구포채(250g/원산지 별도표기) 9,980원

    L.POINT 3,000원 할인
    광천 파래식탁김(5g×36봉/원산지 별도표기) 9,980원

    L.POINT 5,000원 할인
    정다운 허브 훈제오리(600g/원산지 별도표기) 9,980원

    최대 3,000원 할인
    해태 자유시간 미니(790g)/피니 빈모양 젤리(900g) 각9,980원

    최대 5,000원 할인
    사푸토 스트링 치즈 外 3종(상품별 규격 상이) 각9,980원

    4,000원 할인
    사조해표 식용유(1.7L×2입) 9,980원

    6,000원 할인
    쾌변엔 차전자피 화이버(8g×20포) 9,980원

    3,000원 할인
    해피바스 프레시팝 주스 스무디 옐로우 바디워시(1,100ml) 9,980원

    3,000원 할인
    밀크바오밥 세라샴푸/트리트먼트(화이트머스크 각 1.2L) 각9,980원

    4,000원 할인
    스피드펀치 욕실용 세정제(800ml×3입) 9,980원

    3,000원 할인
    아리얼 릴리빙 퓨어풀 클렌징 티슈(80매×3입) 9,980원

    ※정상가란? 2024년 10월 10일(목) ~ 10월 30일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다   ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다  ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다
  </div>
  `,
  `
  <div>
    김장 준비 필수 아이템

    롯데마트 맥스에서 김장 미리 준비하세요!
    김장 사전예약
    절임배추, 절임 알타리, 김치 양념 등 김장품목

    ※예약은 '도와드리겠습니다'직원에게 문의해주세요

    깐마늘(1kg/국산)/다진마늘(800g/국산) 7,980/9,480원

    2,500원 할인
    청화농산 하늘정성 내리찬 국산 고추가루(1kg) 24,480원

    1,000원 할인
    3M 고무장갑 2+1 기획(소/중/대) 각5,980원

    보쌈용 무김치(1kg/무 : 국내산) 7,980원

    새우젓(2kg/통/원산지 별도표기)/어리굴젓(1kg/통/원산지 별도표기)  17,980/19,980원
  
    코맥스 김치통 기획 2P(11.5L) 17,980원

    각 2,500원 할인
    모슬포 진멸치액젓(1.8L)/섬들채 천일염(2.5kg) 10,980/7,980원

    최대 3,510원 할인
    요리하다 맛김치/포기김치(각 900g) 각5,480원

    엔비사과(1.8kg/봉/국산) 16,980원

    시금치(200g/팩/국산)/가을 표고버섯(400g/봉/국산) 2,180/4,980원
 
    애호박(개/국산)/양파(2.5kg/국산) 980/4,990원
    ※기간 : 10/31(목)~11/6(수)

    꿀고구마(1.5kg/국산) 5,980원

    최대 6,000원 할인
    귀한쌀(10kg/국산)/불릴 필요없는 칼집 현미(4.5kg/국산) 23,980/11,480원

    롯데/신한카드 50% 할인
    활 킹크랩(100g/냉장/러시아산) 5,990원
    ※이미지 컷입니다

    ASC인증 완도활전복(14미/10마리/국산)/홍가리비(1kg/팩/냉장/국산) 22,800/5,980원

    꾸이포대(1kg/원산지 별도표기) 19,980원

    L.POINT 3,000원 할인
    롱스틱 육포(180g/원산지 별도표기) 6,980원

    L.POINT 20% 할인
    미국 초이스 꽃갈비살/호주 곡물비육 살치살(각 100g, 냉장, 미국산/호주산) 6,944/3,664원
        
    L.POINT 15% 할인
    함박 스테이크(팩/원산지 별도표기) 13,583원

    나시고랭/해물볶음우동(각 팩/원산지 별도표기) 각5,980원

    3,000원 할인
    18인치 트리플 치즈 피자(원산지 별도표기) 13,900원
    ※롯데마트맥스 영등포, 금천, 상무, 송천점에 한함
    ※ 상무점 기간 : 11/4(월)~11/13(수), 
    송천점 기간 : 10/31(목)~종료 날짜 상이

    20%
    AOP 이즈니버터 소금빵(1입/원산지 별도표기) 3,192원
    ※롯데마트맥스 상무, 목포점에 한함

    3,000원 할인
    마들렌(20입/1팩/원산지 별도표기) 9,900원
    ※롯데마트맥스 금천, 영등포, 송천, 창원중앙점에 한함
     
    롯데/신한카드 4,000원 할인
    롯데 에센소시지(1,000g) 9,480원

    롯데/신한카드 3,000원 할인
    고래사 전통 꼬치어묵(840g) 8,980원
    
    4,000원 할인
    목우촌 오븐 닭다리살 오리지널/숯불양념맛(각 600g) 각8,980원

    4,000원 할인
    해태 고향만두(1,350g) 7,980원

    2,000원 할인
    허니 한입 꽈배기(520g) 6,980원

    빼빼로 3만원 이상 구매시 5천원 롯데상품권 증정
    ※당일 영수증에 한함  ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령  ※기간 : 10/31(목)~11/13(수)
    롯데 빼빼로 뉴진스 패키지 초코/화이트 外 2종(상품별 규격상이) 각9,480원

    칼로로시 콩고드(750ml) 4,990원

    최대 1,600원 할인
    삼립 호빵 3종(각 90g×12입) 각7,980원
    ※단팥/단팥&야채, 단팥&피자

    2,000원 할인
    콘푸레이크 천마차(18g×80입) 12,980원

    2,000원 할인
    델몬트 팜앤홈(제주감귤)(1.2L×4입) 4,980원

    디벨라 파스타면 5종(상품별 규격상이) 최대 40% 할인
    ※스파게티니/스파게티/링귀네/푸실리/펜네

    2개 이상 각 1,500원 할인
    청정원 파스타소스 2개 이상 구매시 1개당 각4,480~7,480원
    ※ 로제 600g/머쉬룸투움바 350g/베이컨&갈릭 까르보나라 350g/아라비아따 600g/토마토 600g  ※교차구매 가능
    ※1개 구매시 각5,980~8,980원

    L.POINT 3,000원 할인
    아임비타 이뮨플러스 12+3(23.5g×15입) 22,800원

    L.POINT 2,000원 할인
    후디스 산양유 단백질(280g) 23,980원
    일동후디스 해당품목 3만원 이상 구매시 5천원 롯데상품권 증정
    ※당일 영수증에 한함  ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령

    뉴트리원 해당품목 3만원 이상 구매시 
    5천원 롯데상품권 증정
    ※당일 영수증에 한함  ※비연속식 증정(1인 1일 1매)
    ※결제시 계산대에서 수령
    롯데/신한카드 4,000원 할인
    비비랩 콜라겐 구미 더블 기획(18g×10포×2입) 15,980원

    ※해당 단일카드로 전액 결제시에 한함  ※체크카드/앱카드 포함  ※기프트/선불카드 제외  ※신한BC카드 제외  ※롯데마트몰 카드행사 제외  ※오프라인 점포에 한함   ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  </div> 
  `,
  `
  <div>
    땡 잡았다! 득템찬스!

    2팩 이상 10%
    2팩 이상 15%
    MAXX 미네랄워터 ECO(2L×6입/500ml×20입) 2팩 이상 구매시 1팩당 각1,602/2,703원
    ※1팩 구매시 1,780/3,180원  ※동일 상품에 한함

    각 10%
    MAXX PB 우유 3종(각 2.3L) 4,210/4,840/5,020원
    ※MAXX 1A 신선한 우유/1A 저지방 우유/소화가 편한 신선한 우유

    15,000원 할인
    MAXX 페달 휴지통 30,800원

    6,000원 할인
    MAXX 애견패드(300입) 21,980원

    1,500원 할인
    MAXX 자연 그대로 100% 맛밤(110g×5입) 9,980원

    MAXX PB 주방 소모품(비닐장갑/지퍼백/위생백) 10% 할인

    1,000원 할인
    MAXX 박스종이컵(1,000P) 9,980원

    최대 10,000원 할인
    블랙야크 워크웨어 기모 맨투맨/기모 팬츠 39,800/34,800원

    휠라골프 기모 맨투맨/휠라골프 밍크기모 오픈 팬츠 각19,980원

    휠라 크레마 보아자켓/휠라키즈 크레마 보아자켓 19,980/9,980원

    챔피온 리버스리브 맨투맨 9,980원

    챔피온 클래식 후드티/챔피온 그래픽 조거팬츠 29,980/19,980원

    컬럼비아 시그니처 롱패딩자켓 79,800원
    ※한정수량 입고

    컬럼비아 본딩팬츠/패딩 카고팬츠 각39,800원

    SOUP 여성 숏 패딩 점퍼/롱패딩 점퍼 39,800/49,800원

    20,000원 할인
    SOUP 빅카라 모직 하프자켓/SOUP 칼라시어링 코트 각59,800원

    1,000원 할인 -> 롯데/신한카드 3,000원 추가할인
    생활공감ON 3겹 천연펄프 화장지(30m×30롤) 9,880원

    3,000원 할인
    테크 베이킹+구연산 액체세제(4.8L) 12,980원

    2,000원 할인
    에찌모토 식과도 가위세트 11,980원

    2,000원 할인
    키친아트 다용도 슬라이서 14,980원

    2,000원 할인
    마호가니 도마 12,880원

    4,000원 할인
    시린메드 시린이케어 칫솔(10개입) 13,980원

    2,500원 할인 -> 롯데/신한카드 2,000원 추가할인
    리스테린 토탈케어 검케어 마일드(1L×2입) 12,980원

    엘라스틴 케라틴 샴푸/트리트먼트(각 1,000ml) 각13,980원

    2,000원 할인 
    크린아이 무세제 크리너(48P) 7,980원

    각 10,000원 할인
    엘리제 밍크 극세사 이불(S/Q) 39,800/49,800원

    2,000원 할인 
    자민경 오리지날 달팽이크림 기획세트(80g×2) 17,980원

    3,000원 할인 
    에이센트 3X 고농축 퍼퓸드 섬유유연제(4L) 12,980원

    1,000원 할인
    스텐 함마톤 편수냄비 8,980원

    각 3,000원 할인 
    비비안 기모 타이즈/ 9부 레깅스(2족입/블랙) 각9,880원

    뉴발란스 로고 파일 장목 양말(남성/여성, 각 4매입) 각14,980원

     ※정상가란? 2024년 10월 10일(목) ~ 10월 30일(수) 롯데마트맥스 송천점 오프라인 매장에서 판매되었던 L.POINT 비회원 최저가격입니다   ※일부 상품은 행사 이후 일정기간 가격이 유지될 수 있습니다  ※점별 운영 상품 및 가격이 상이할 수 있습니다 ※카드할인은 스노우플랜 결제 시 적용되지 않습니다  
    ※해당 단일카드로 전액 결제시에 한함  ※체크카드/앱카드 포함  ※기프트/선불카드 제외  ※신한BC카드 제외  ※롯데마트몰 카드행사 제외  ※오프라인 점포에 한함   ※롯데마트몰은 전단지 상의 이벤트 및 가격과 혜택은 상이할 수 있습니다
  </div>
  `,
];

const fillZero = (width, str) => {
  return str.length >= width
    ? str
    : new Array(width - str.length + 1).join("0") + str; //남는 길이만큼 0으로 채움
};

const getVicData = () => {
  const dataArray = [];
  vicData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `빅마켓 전단${i + 1}`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getMaxxData = () => {
  const dataArray = [];
  maxxData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `maxx_${fillZero(2, String(i + 1))}.jpg`,
      title: `maxx 전단${i + 1}`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getVicNewData = () => {
  const dataArray = [];
  vicNewData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `${fillZero(2, String(i + 1))}.jpg`,
      title: `빅마켓 전단${i + 1}`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const getMaxxNewData = () => {
  const dataArray = [];
  maxxNewData.map((data, i) => {
    const dataObj = {
      href: "",
      imgs: `maxx_${fillZero(2, String(i + 1))}.jpg`,
      title: `maxx 전단${i + 1}`,
      texts: `${data}`,
    };
    dataArray.push(dataObj);
    return <></>;
  });
  return dataArray;
};

const data = {
  L705: {
    title: "스마트전단지-빅마켓705",
    category: ["1", "2", "3", "4", "5", "6"],
    type: "VIC",
    isFooter: false,
    imgPath: "images/202405_5/07/",
    contents: getVicData(),
  },
  L755: {
    title: "스마트전단지-빅마켓755",
    category: ["1", "2", "3", "4", "5", "6"],
    type: "VIC",
    isFooter: false,
    imgPath: "images/202405_5/07/",
    contents: getVicData(),
  },
  L738: {
    title: "스마트전단지-맥스738",
    category: [],
    type: "MAXX",
    isFooter: false,
    imgPath: "images/202411_3/07",
    contents: getMaxxData(),
  },
  L739: {
    title: "스마트전단지-맥스739",
    category: [],
    type: "MAXX",
    isFooter: false,
    imgPath: "images/202411_3/07",
    contents: getMaxxData(),
  },

  L703: {
    title: "스마트전단지-빅마켓703",
    category: ["1", "2", "3", "4", "5", "6"],
    type: "VIC",
    isFooter: false,
    imgPath: "images/202406_3/07/",
    contents: getVicNewData(),
  },
  L735: {
    title: "스마트전단지-빅마켓735",
    category: ["1", "2", "3", "4", "5", "6"],
    type: "VIC",
    isFooter: false,
    imgPath: "images/202406_3/07/",
    contents: getVicNewData(),
  },
  L758: {
    title: "스마트전단지-맥스758",
    category: [],
    type: "MAXX",
    isFooter: false,
    imgPath: "images/202410_5/07",
    contents: getMaxxNewData(),
  },
  L759: {
    title: "스마트전단지-맥스759",
    category: [],
    type: "MAXX",
    isFooter: false,
    imgPath: "images/202410_5/07",
    contents: getMaxxNewData(),
  },
};

export default data;
